import React from 'react';
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import {
  IonContent,
  IonText,
  IonList,
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonNote,
  useIonActionSheet,
  IonModal,
} from '@ionic/react';
import {
  readerOutline,
  ellipsisVerticalOutline,
  callOutline,
} from 'ionicons/icons';
function ProviderShopList({ order }) {
  console.log('order==>', order);
  const history = useHistory();
  const timeOut = (id) => {
    // setIsLoading(true);

    setTimeout(() => {
      history.push(`/ProvidersOrders/${order.sid}`, {
        product1: {
          sid: order.sid,
        },
      });
    }, 500);
  };
  return (
    <div>
      {' '}
      <IonList class="ion-no-padding" lines="inset">
        <IonItem>
          <IonGrid>
            <IonRow class="ion-align-items-stretch">
              <IonCol size="8" onClick={() => timeOut(order.sid)}>
                <IonLabel
                  class="ion-text-uppercase"
                  color="primary"
                  style={{ fontSize: '20px', fontWeight: 'bold' }}
                >
                  {order.shopTitle}
                </IonLabel>
                <IonLabel class="ion-text-uppercase" color="primary">
                  <Moment format="YYYY/MM/DD , h:mm:ss a">
                    {order.created_at}
                  </Moment>
                </IonLabel>

                <IonLabel color="medium" style={{ fontSize: '14px' }}>
                  Total: {order.total} usd
                </IonLabel>
              </IonCol>

              <IonCol size="2" style={{ paddingTop: '8px' }}>
                <IonIcon
                  // onClick={() => timeOut(sid)}
                  icon={readerOutline}
                  style={{ fontSize: '30px' }}
                  color="primary"
                />
              </IonCol>
              <IonCol size="2" style={{ paddingTop: '8px' }}>
                {/* <IonButton
                  // onClick={() => timeOut(sid)}
                  icon={callOutline}
                  style={{ fontSize: '30px' }}
                  color="primary"
                  href="tel:+4316800820"
                /> */}

                <IonButton href={`tel:${+order.guidePhone}`}>
                  <IonIcon slot="icon-only" icon={callOutline}></IonIcon>
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonItem>
      </IonList>
    </div>
  );
}

export default ProviderShopList;
