// products context
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL } from '../constants/AppConstants';
import Loading from '../components/Loading';
export const ProductContext = React.createContext();

export default function ProductProvider({ children }) {
  const [loadingProduct, setLoadingProduct] = React.useState(false);
  const [loadingOrder, setLoadingOrder] = React.useState(false);
  const [loadingTouristOrders, setloadingTouristOrders] = React.useState(false);
  const [loadingFinalOrders, setLoadingFinalOrders] = React.useState(false);
  const [loadingPlces, setloadingPlces] = React.useState(false);
  const [products, setProducts] = React.useState([]);
  const [orders, setorders] = React.useState([]);
  const [touristOrders, setTouristOrders] = React.useState([]);
  const [finalOrders, setFinalOrders] = React.useState([]);
  const [places, setPlaces] = React.useState([]);
  const [sorted, setSorted] = React.useState([]);
  const [items, setItems] = React.useState([]);

  const ShowProducts = () => {
    setLoadingProduct(true);
    axios
      .get('https://shop.holyland.app/api/product')
      .then((response) => {
        setProducts(response.data);
        setLoadingProduct(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };
  const ShowOrders = () => {
    setLoadingOrder(true);
    axios
      .get(`${BASE_URL}/api/orders/`)
      .then((response) => {
        console.log(response.data);
        setorders(response.data);
        setLoadingOrder(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const ShowTouristOrders = () => {
    setloadingTouristOrders(true);
    axios
      .get('https://shop.holyland.app/api/useroders')
      .then((response) => {
        console.log(response.data);
        setTouristOrders(response.data.data);
        setloadingTouristOrders(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  const ShowFinalOrder = () => {
    setLoadingFinalOrders(true);
    axios
      .get('https://shop.holyland.app/api/finalorder')
      .then((response) => {
        console.log(response.data);
        setFinalOrders(response.data.data);
        setSorted(response.data.data);
        setLoadingFinalOrders(false);
      })
      .catch((err) => {
        return console.log(err);
      });
  };

  React.useEffect(() => {
    const items = JSON.parse(localStorage.getItem('theme') || '{}');

    if (items) {
      setItems(items);
    }
  }, []);
  console.log('theme from context', items);

  React.useEffect(() => {
    ShowProducts();
    // ShowOrders();
    ShowTouristOrders();
    ShowFinalOrder();
    // ShowPlaces();
  }, []);

  return (
    <div>
      {console.log('CONTEXT this is products', products)}
      {console.log('CONTEXT this is tourist orders', touristOrders)}
      {loadingFinalOrders || loadingPlces || loadingTouristOrders ? (
        <Loading />
      ) : (
        <ProductContext.Provider
          value={{
            products,
            ShowProducts,
            // orders,
            touristOrders,
            finalOrders,
            loadingProduct,
            loadingOrder,
            loadingTouristOrders,
            loadingFinalOrders,

            ShowFinalOrder,
            ShowTouristOrders,

            sorted,
            items,
          }}
        >
          {children}
        </ProductContext.Provider>
      )}
    </div>
  );
}
