import React, { useState } from 'react';

import {
  IonText,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonContent,
  IonImg,
  IonIcon,
} from '@ionic/react';
import {
  chevronBackOutline,
  addCircleOutline,
  removeCircleOutline,
} from 'ionicons/icons';

function TouristCard({
  title,
  about,
  image,
  price,
  idProduct,
  addToFavorite,
  removeFavorite,
  customerOrders,
  addTocart,
  removeFromCard,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const HandelAdd = (idProduct) => {
    addToFavorite(idProduct);
    addTocart(price);
  };

  const HandelDelete = () => {
    removeFavorite(idProduct);
    removeFromCard(price);
  };

  return (
    <div>
      {/* {console.log('this is customer orders from tourist card', customerOrders)} */}
      {console.log('image=>', image)}
      <IonCard style={{ marginBottom: '20px' }}>
        <img alt="Silhouette of mountains" src={JSON.parse(image)} />

        <IonCardHeader>
          <IonCardTitle>{title}</IonCardTitle>

          <IonCardSubtitle>
            <IonText color="primary">
              <h5>Price: {price}$</h5>
            </IonText>
          </IonCardSubtitle>

          <IonCardSubtitle>
            {customerOrders.find((item) => item.id == idProduct) ? (
              <>
                <>
                  <IonText>include in my shop</IonText>
                </>
              </>
            ) : (
              <>
                <>
                  <IonText>Not include in my shop</IonText>
                </>
              </>
            )}
          </IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>{about}</IonCardContent>

        <>
          <IonGrid>
            <IonRow class="ion-justify-content-between">
              <IonCol size="auto">
                {/* <IonButton fill="clear" onClick={() => setIsOpen(true)}>
                  ABOUT
                </IonButton> */}
              </IonCol>
            </IonRow>
            <IonRow>
              <IonToolbar mode="md">
                <IonButtons slot="start" style={{ padding: '8' }}>
                  <IonButton onClick={() => setIsOpen(true)}> ABOUT</IonButton>
                </IonButtons>
                <IonButtons slot="end" style={{ padding: '8' }}>
                  <IonButton onClick={() => HandelDelete(idProduct, price)}>
                    <IonIcon
                      slot="icon-only"
                      icon={removeCircleOutline}
                      color="primary"
                    ></IonIcon>
                  </IonButton>
                </IonButtons>
                <IonButtons slot="primary">
                  <IonButton
                    onClick={() => HandelAdd(idProduct, price)}
                    size="large"
                  >
                    <IonIcon
                      slot="icon-only"
                      icon={addCircleOutline}
                      color="primary"
                      style={{ display: '130px' }}
                    ></IonIcon>
                  </IonButton>
                </IonButtons>
                <IonRow slot="start">
                  <IonTitle>
                    {' '}
                    {
                      customerOrders.filter((item) => item.id == idProduct)
                        .length
                    }
                  </IonTitle>
                </IonRow>
              </IonToolbar>
            </IonRow>
          </IonGrid>
        </>
      </IonCard>
      {'  '}

      <IonModal isOpen={isOpen}>
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>{title.toUpperCase()}</IonTitle>
            <IonIcon
              onClick={() => setIsOpen(false)}
              slot="start"
              icon={chevronBackOutline}
              size="large"
            ></IonIcon>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="all-page">
          <IonImg src={JSON.parse(image)} alt="Product featued image"></IonImg>
          {/* <IonButton onClick={() => history.goBack()}>back</IonButton> */}
          <IonGrid>
            <IonCardHeader>
              <IonCardTitle class="ion-text-uppercase">{title}</IonCardTitle>
              <IonCardSubtitle class="ion-text-capitalize">
                About this product
              </IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>{about}</IonCardContent>
          </IonGrid>
        </IonContent>
      </IonModal>
    </div>
  );
}

export default TouristCard;
