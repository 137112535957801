import React from 'react';

import {
  IonText,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';


function FinalShopCardViewNoButtons({
  title,
  about,
  image,
  price,
}) {
  

  
  return (
    <div>
      <IonCard>
        <img
          alt="Silhouette of mountains"
          src={JSON.parse(image).uploadInfo.url}
        />

        <IonCardHeader>
          <IonCardTitle>{title}</IonCardTitle>

          <IonCardSubtitle>
            <IonText color="primary">
              <h5>Price: {price}$</h5>
            </IonText>

            {/* {productsList.includes(idProduct) ? (
              <>
                <IonLabel>
                  <IonIcon
                    color="danger"
                    size="large"
                    icon={heartCircleOutline}
                  ></IonIcon>
                </IonLabel>
              </>
            ) : (
              <>
                <IonLabel>
                  <IonIcon
                    color="Primary"
                    size="large"
                    icon={heartCircle}
                  ></IonIcon>
                </IonLabel>
              </>
            )} */}
          </IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>{about}</IonCardContent>
      </IonCard>
    </div>
  );
}

export default FinalShopCardViewNoButtons;
