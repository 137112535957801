import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Progress from './Porgress';
import {
  IonHeader,
  IonTitle,
  IonToolbar,
  IonBreadcrumb,
  IonBreadcrumbs,
  IonIcon,
} from '@ionic/react';

import { chevronBackOutline } from 'ionicons/icons';

function Menu({ what, backBtnLink, display }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Design>
      <IonHeader id="main-content" className="main">
        <IonToolbar color="primary">
          {display ? (
            <>
              <IonBreadcrumbs slot="start" className="bread1">
                <IonBreadcrumb
                  onClick={() => {
                    setIsLoading(true);
                    setTimeout(() => {
                      history.push(backBtnLink);
                      setIsLoading(false);
                    }, 600);
                  }}
                >
                  <IonIcon
                    style={{ color: 'white' }}
                    onClick={() => setTimeout(() => {}, 600)}
                    slot="start"
                    icon={chevronBackOutline}
                    size="large"
                  ></IonIcon>
                </IonBreadcrumb>
              </IonBreadcrumbs>
            </>
          ) : (
            <></>
          )}
          <IonTitle class="ion-text-uppercase">{what}</IonTitle>

          {/* <IonButtons slot="end">
            <IonMenuButton></IonMenuButton>
          </IonButtons> */}

          {/* <IonItem lines="none">
            <IonIcon slot="end" icon={moon} />

            <IonToggle
              slot="end"
              name="darkMode"
              onIonChange={toggleDarkModeHandler}
            />
          </IonItem> */}
        </IonToolbar>
        {isLoading && <Progress isRever={true} />}
      </IonHeader>
    </Design>
  );
}

const Design = styled.div`
  .main {
    position: absolute;
    top: 0px;
  }
  .bread1 {
    padding-inline: 0px !important;
  }
  .headrtop {
    height: 70px !important;
  }
  ion-toolbar {
    --min-height: 65px !important;
  }
`;

export default Menu;
