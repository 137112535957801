import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProductContext } from '../../context/product';
import { UserContext } from '../../context/user';
import styled from 'styled-components';
import Progress from '../../components/Porgress';
import ProductProvider from '../../context/product';
import {
  IonContent,
  IonPage,
  IonText,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
} from '@ionic/react';
import Menu from '../../components/Menu';
import { addCircleOutline } from 'ionicons/icons';
import ProviderList from '../../components/ProviderList';

function ProvidersOrders() {
  const [isLoading, setIsLoading] = useState(false);
  const { finalOrders, touristOrders, products } =
    React.useContext(ProductContext);
  const { sid } = useParams();
  const { user } = React.useContext(UserContext);
  const user_id = user.user.id;
  const userId = user.user.id;
  const FinalcustomerOrders = touristOrders.map((it) => it.customerOrders);
  const ProviderOrders = touristOrders.map((item) =>
    item.customerOrders.filter((it) => it.sid === sid)
  );
  const allOrderSameShop = ProviderOrders.map((item) =>
    item.filter((it) => it.userId === userId)
  );

  return (
    <div>
      <Design>
        {console.log('ProviderOrders----->', ProviderOrders)}
        {console.log('touristOrders----->', touristOrders)}
        {console.log('user id----->', user_id)}
        {console.log('allOrderSameShop ----->', allOrderSameShop)}
        {console.log('FinalcustomerOrders ----->', FinalcustomerOrders)}
        {console.log('ProviderOrders ----->', ProviderOrders)}

        <IonPage>
          <Menu what=" MY ORDERS" backBtnLink="/home" display={true} />

          <IonContent fullscreen className="all-page">
            {isLoading && <Progress />}

            <IonRow class="ion-padding-vertical">
              <IonCol>
                <IonText color="dark" class="ion-text-center">
                  <h3 class="ion-text-uppercase">List of ORDERS</h3>
                </IonText>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol class="ion-text-center">
                {ProviderOrders.length === 0 && (
                  <>
                    {' '}
                    <IonRow class="ion-padding-vertical">
                      <IonCol>
                        <IonText color="medium" class="ion-text-center">
                          {/* <h3>List of shops created by {user.user.name}</h3> */}
                          <h5>You do not have any shop, add new</h5>
                        </IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow class="ion-text-center">
                      <IonCol class="ion-text-center">
                        <IonButton
                          expand="block"
                          fill="solid"
                          color="primary"
                          routerLink="/Addshop"
                          size="large"
                        >
                          <IonIcon
                            slot="start"
                            icon={addCircleOutline}
                          ></IonIcon>
                          ADD NEW SHOP
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </>
                )}
              </IonCol>
            </IonRow>

            {ProviderOrders.map((order, index) => {
              return order.map((item) => {
                return (
                  <div className="container">
                    <ProviderList
                      // name={item.name}
                      {...item}
                      key={index}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  </div>
                );
              });
            })}
          </IonContent>
        </IonPage>
      </Design>
    </div>
  );
}
const Design = styled.div`
  .my-custom-class {
    display: none !important;
  }
`;
export default ProvidersOrders;
