import React from 'react';
import { ProductContext } from '../../context/productold';
import Producto from '../../components/ProductCardHome';
import Menu from '../../components/Menu';
import { IonContent, IonPage, IonText, IonGrid, IonRow } from '@ionic/react';

function HomeProductsList() {
  const { products } = React.useContext(ProductContext);

  return (
    <div>
      <IonPage>
        <Menu what="Choose products" />
        <IonContent fullscreen className="all-page">
          <IonGrid class="ion-justify-content-center">
            <IonRow class="ion-justify-content-around">
              <IonText color="dark" class="ion-text-center">
                <h3>Please choose product for your store</h3>
              </IonText>
            </IonRow>
          </IonGrid>
          {products.map((item) => {
            return (
              <>
                <div key={item.id}>
                  <Producto
                    // key={item.id}
                    title={item.title}
                    about={item.about}
                    image={item.image}
                    price={item.price}
                    idProduct={item.id}
                  />
                </div>
              </>
            );
          })}
        </IonContent>
      </IonPage>
    </div>
  );
}

export default HomeProductsList;
