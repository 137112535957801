import React, { useState, useEffect } from 'react';
import { ProductContext } from '../../context/product';
import { useParams } from 'react-router-dom';
import Producto from '../../components/FinalShopCardViewNoButtons';
import {
  IonContent,
  IonPage,
  IonList,
  IonLabel,
  IonItem,
  IonItemDivider,
  IonRow,
  IonCol,
  IonNote,
} from '@ionic/react';

function TouristOrderPage() {
  //email
  const { id } = useParams();
  const { products, touristOrders } = React.useContext(ProductContext);
  const product = touristOrders.filter((item) => item.id === id);
  const customerOrders = product.map((item) => item.customerOrders).toString();
  const customerOrders1 = products.filter((item) =>
    customerOrders.includes(item.id)
  );
  const name = product.map((item) => item.name);
  const total = product.map((item) => item.total);
  const email = product.map((item) => item.email);
  const phoneNumber = product.map((item) => item.phoneNumber);

  const [userId, setuserId] = useState('');

  const [considerTourLeader, setconsiderTourLeader] = useState('');
  const [tourLeaderC, settourLeaderC] = useState('');
  const [tourLeaderName, settourLeaderName] = useState('');
  const [tourGuideName, settourGuideName] = useState('');

  useEffect(() => {
    console.log(userId);
    setconsiderTourLeader(product.map((item) => item.considerTourLeader)[0]);
    settourLeaderC(product.map((item) => item.tourLeaderC)[0]);
    settourLeaderName(product.map((item) => item.tourLeaderName)[0]);
    setuserId(product.map((item) => item.userId)[0]);
    settourGuideName(product.map((item) => item.tourGuideName));
  }, [product, userId]);

  return (
    <div>
      <IonPage>
        <IonContent>
          <IonList inset={true}>
            <IonItemDivider class="ion-padding" color="light">
              <IonLabel class="ion-text-center">Tourist details</IonLabel>
            </IonItemDivider>

            <IonItem>
              <IonLabel>Tourist name </IonLabel>
              <IonNote slot="end" color="primary">
                {name}
              </IonNote>
            </IonItem>

            <IonItem>
              <IonLabel>Total of order</IonLabel>
              <IonNote slot="end" color="primary">
                {total} $
              </IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>Email of client</IonLabel>
              <IonNote slot="end" color="primary">
                {email}
              </IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>Phone number</IonLabel>
              <IonNote slot="end" color="primary">
                +{phoneNumber}
              </IonNote>
            </IonItem>

            <IonItemDivider class="ion-padding" color="light">
              <IonLabel>Tour guide and tour leader details</IonLabel>
            </IonItemDivider>
            <IonItem>
              <IonLabel>Tour guide name</IonLabel>
              <IonNote slot="end" color="primary">
                {tourGuideName}
              </IonNote>
            </IonItem>
            <IonItem>
              <IonLabel>Consider tour leader</IonLabel>
              <IonNote slot="end" color="primary">
                {considerTourLeader ? <>Yes</> : <>No</>}
              </IonNote>
            </IonItem>

            {considerTourLeader ? (
              <>
                <IonItem>
                  <IonLabel>Tour Leader name</IonLabel>
                  <IonNote slot="end" color="primary">
                    {tourLeaderName}
                  </IonNote>
                </IonItem>
              </>
            ) : (
              <></>
            )}
            {considerTourLeader ? (
              <>
                {' '}
                <IonItem>
                  <IonLabel>Comission of tour leader</IonLabel>
                  <IonNote slot="end" color="primary">
                    {tourLeaderC}
                  </IonNote>
                </IonItem>
              </>
            ) : (
              <></>
            )}
          </IonList>

          <IonRow>
            <IonCol>
              <IonItemDivider class="ion-padding" color="light">
                <IonLabel class="ion-text-center">
                  products ordered by {name}
                </IonLabel>
              </IonItemDivider>
              {customerOrders1.map((item, i) => {
                return (
                  <>
                    <div key={i}>
                      <Producto
                        title={item.title}
                        about={item.about}
                        image={item.image}
                        price={item.price}
                        idProduct={item.id}
                      />
                    </div>
                  </>
                );
              })}
            </IonCol>
          </IonRow>
          {/* <Swiper
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log('slide change')}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {' '}
            {customerOrders1.map((item, i) => {
              return (
                <>
                  <SwiperSlide>
                    <IonImg
                      src={JSON.parse(item.image).uploadInfo.url}
                    ></IonImg>
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper> */}
        </IonContent>
      </IonPage>
    </div>
  );
}

export default TouristOrderPage;
