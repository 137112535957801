import React, { useState, useRef } from 'react';
import { UserContext } from '../context/user';
import {
  IonText,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonImg,
  IonIcon,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';

function ProductCardHome({
  title,
  about,
  image,
  price,
  idProduct,
  addToFavorite,
  removeFavorite,
  productsList,
}) {
  const modal = useRef();
  const { user } = React.useContext(UserContext);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <IonModal trigger="open-modal" ref={modal} isOpen={isOpen}>
        {console.log(JSON.parse(image))}
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>{title}</IonTitle>
            <IonIcon
              onClick={() => setIsOpen(false)}
              slot="start"
              icon={chevronBackOutline}
              size="large"
              // color="primary"
            ></IonIcon>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen className="all-page">
          <IonImg src={JSON.parse(image)} alt="Product featued image"></IonImg>
          {/* <IonButton onClick={() => history.goBack()}>back</IonButton> */}
          <IonGrid>
            <IonCardHeader>
              <IonCardTitle class="ion-text-uppercase">{title}</IonCardTitle>
              <IonCardSubtitle class="ion-text-capitalize">
                About this product
              </IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>{about}</IonCardContent>
          </IonGrid>
        </IonContent>
      </IonModal>
      <IonCard>
        <img alt="Silhouette of mountains" src={JSON.parse(image)} />

        <IonCardHeader>
          <IonCardTitle>{title}</IonCardTitle>

          <IonCardSubtitle>
            <IonText color="primary">
              <h5>Price: {price}$</h5>
            </IonText>
          </IonCardSubtitle>
          <IonCardSubtitle>
            {console.log('this is productList ==>', productsList)}
            {productsList.includes(idProduct) ? (
              <>
                <IonText>include in my shop</IonText>
              </>
            ) : (
              <>
                <IonText>Not include in my shop</IonText>
              </>
            )}
          </IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>{about}</IonCardContent>
        {user.user && (
          <>
            <IonGrid>
              {console.log('this is idproduct==>', idProduct)}
              <IonRow class="ion-justify-content-between">
                <IonCol size="auto">
                  <IonButton fill="clear" onClick={() => setIsOpen(true)}>
                    ABOUT
                  </IonButton>
                </IonCol>
                {productsList.includes(idProduct) ? (
                  <>
                    <IonCol size="auto">
                      <IonButton
                        color="danger"
                        fill="clear"
                        onClick={() => removeFavorite(idProduct)}
                      >
                        DELETE
                      </IonButton>
                    </IonCol>
                  </>
                ) : (
                  <>
                    <IonCol size="auto">
                      <IonButton
                        color="success"
                        fill="clear"
                        onClick={() => addToFavorite(idProduct)}
                      >
                        ADD
                      </IonButton>
                    </IonCol>
                  </>
                )}
              </IonRow>
            </IonGrid>
          </>
        )}
      </IonCard>
    </div>
  );
}

export default ProductCardHome;
