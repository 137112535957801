import React from 'react';
import styled from 'styled-components';
import Menu from '../../components/Menu';

import {
  IonContent,
  IonPage,
  IonText,
  IonGrid,
  IonRow,
} from '@ionic/react';

function Explore() {
  return (
    <Design>
      <IonPage>
        <Menu what="EXPLORE" />
        <IonContent>
          <IonGrid>
            {/* <IonRow className="r1"> */}
            <IonRow>
              <IonText>EXPLORE THE HOLYLAND</IonText>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </Design>
  );
}
const Design = styled.div`
  .r1 {
    display: flex;
    height: 80vh;
    align-items: center;
  }
`;
export default Explore;
