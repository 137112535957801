import React from 'react';
import styled from 'styled-components';
import Image1 from '../../image/not.jpg';

import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
} from '@ionic/react';

function WrongPage() {
  return (
    <Design>
      <IonPage>
        <IonContent>
          <IonGrid>
            <IonRow className="r1">
              <IonCol>
                <img src={Image1} alt='none'></img>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </Design>
  );
}
const Design = styled.div`
  .r1 {
    display: flex;
    height: 100vh;
    align-items: center;
  }
`;
export default WrongPage;
