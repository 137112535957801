import React, { useState, useMemo } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import * as yup from 'yup';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import {
  IonContent,
  IonPage,
  IonText,
  IonList,
  IonLabel,
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonTextarea,
  useIonToast,
  IonSelect,
  IonSelectOption,
  IonFooter,
  IonToolbar,
} from '@ionic/react';
import {
  lockClosedOutline,
  mailOutline,
  textOutline,
  documentTextOutline,
  logoFacebook,
  thumbsUpOutline,
} from 'ionicons/icons';
import Menu from '../../components/Menu';
import { BASE_URL } from '../../constants/AppConstants';
const loginSchema = yup.object({
  email: yup
    .string()
    .required('Email is required')
    .email('Email must have proper format'),
  password: yup
    .string()
    .required('Password is required')
    .min(6, 'Password must be grater then 6 letters'),
  name: yup.string().required('Tour guide name is required field'),
  facebook: yup.string(),
  bio: yup.string().max(100),
});

function AddTourGuide() {
  const [present] = useIonToast();
  const presentToast2 = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message: ' User added successfully',
      duration: 1500,
      position: 'bottom',
      color: 'success',
      icon: thumbsUpOutline,
    });
  };

  const history = useHistory();
  const [role, setRole] = useState('');
  function handleRole(event) {
    setRole(event.target.value);
  }
  const [value, setValue] = React.useState('null');
  const handleSubmit = async (
    email,
    password,
    name,
    bio,
    facebook
    // isTourGuide
  ) => {
    axios
      .post('https://shop.holyland.app/api/users/signup', {
        email,
        password,
        name,
        bio,
        facebook,
        phone: value,
        isAdmin: false,
        role,
      })
      .then((res) => {
        console.log(res.data.user);
        presentToast2();
        history.push('/tourGuideList');
        window.location.reload(true);
      })
      .catch((error) => console.log(error));
  };
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        name: '',
        bio: '',
        facebook: '',
      }}
      validationSchema={loginSchema}
      onSubmit={(values, actions) => {
        console.log(values);
        handleSubmit(
          values.email,
          values.password,
          values.name,
          values.bio,
          values.facebook
        );
      }}
    >
      {(formikProps) => (
        <div>
          <Design>
            <IonPage style={{ paddingTop: '70px' }}>
              <Menu what="ADD USER" backBtnLink="/home" display={true} />
              <IonContent fullscreen className="all-page">
                <IonGrid style={{ height: '100%' }}>
                  <IonRow className="r1">
                    <IonCol class="ion-align-self-center">
                      <IonList
                        class="margin-top-25 ion-margin margin-0-28"
                        className="list"
                      >
                        {/* Email filed */}
                        <IonRow>
                          <IonCol>
                            <IonItem
                              class="roundedInput8px margin-bottom-15 "
                              fill="outline"
                            >
                              <IonLabel>
                                <IonIcon icon={mailOutline}></IonIcon>
                              </IonLabel>
                              <IonInput
                                autofocus={true}
                                clearInput={true}
                                placeholder="email@domain.com"
                                type="email"
                                value={formikProps.values.email}
                                onIonChange={formikProps.handleChange('email')}
                                onBlur={formikProps.handleBlur('email')}
                              ></IonInput>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow class="ion-padding" slot="end">
                          <IonCol>
                            <div
                              style={{
                                color: '#a2852e',
                                fontSize: '12px',
                              }}
                            >
                              {formikProps.touched.email &&
                                formikProps.errors.email}
                            </div>
                          </IonCol>
                        </IonRow>
                        {/* Password */}
                        <IonRow>
                          <IonCol>
                            <IonItem>
                              <IonLabel>
                                <IonIcon
                                  color="medium"
                                  icon={lockClosedOutline}
                                ></IonIcon>
                              </IonLabel>
                              <IonInput
                                clearInput={true}
                                placeholder="Password"
                                type="password"
                                minlength={6}
                                value={formikProps.values.password}
                                onIonChange={formikProps.handleChange(
                                  'password'
                                )}
                                onBlur={formikProps.handleBlur('password')}
                              ></IonInput>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow class="ion-padding" slot="end">
                          <IonCol>
                            <div
                              style={{
                                color: '#a2852e',
                                fontSize: '12px',
                              }}
                            >
                              {formikProps.touched.password &&
                                formikProps.errors.password}
                            </div>
                          </IonCol>
                        </IonRow>
                        {/* name */}
                        <IonRow>
                          <IonCol>
                            <IonItem>
                              <IonLabel>
                                <IonIcon
                                  color="medium"
                                  icon={textOutline}
                                ></IonIcon>
                              </IonLabel>
                              <IonInput
                                autocorrect="on"
                                placeholder="user name"
                                clearInput={true}
                                type="text"
                                value={formikProps.values.name}
                                onIonChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                              ></IonInput>
                            </IonItem>
                          </IonCol>
                        </IonRow>{' '}
                        <IonRow class="ion-padding" slot="end">
                          <IonCol>
                            <div
                              style={{
                                color: '#a2852e',
                                fontSize: '12px',
                              }}
                            >
                              {formikProps.touched.name &&
                                formikProps.errors.name}
                            </div>
                          </IonCol>
                        </IonRow>
                        {/* facebook */}
                        <IonRow>
                          <IonCol>
                            <IonItem>
                              <IonLabel>
                                <IonIcon
                                  color="medium"
                                  icon={logoFacebook}
                                ></IonIcon>
                              </IonLabel>
                              <IonInput
                                clearInput={true}
                                placeholder="Facbook"
                                type="text"
                                value={formikProps.values.facebook}
                                onIonChange={formikProps.handleChange(
                                  'facebook'
                                )}
                                onBlur={formikProps.handleBlur('facebook')}
                              ></IonInput>
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '12px',
                                  marginTop: '5px',
                                }}
                              >
                                {formikProps.touched.facebook &&
                                  formikProps.errors.facebook}
                              </div>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        {/* bio */}
                        <IonRow>
                          <IonCol>
                            {' '}
                            <IonItem counter={true}>
                              <IonLabel>
                                <IonIcon
                                  color="medium"
                                  icon={documentTextOutline}
                                ></IonIcon>
                              </IonLabel>
                              <IonTextarea
                                autoGrow={true}
                                placeholder="About this user"
                                clearInput={true}
                                type="text"
                                value={formikProps.values.bio}
                                onIonChange={formikProps.handleChange('bio')}
                                onBlur={formikProps.handleBlur('bio')}
                                counter={true}
                                maxlength={100}
                              ></IonTextarea>
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '12px',
                                  marginTop: '5px',
                                }}
                              >
                                {formikProps.touched.bio &&
                                  formikProps.errors.bio}
                              </div>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        {/* phone */}
                        <IonRow class="ion-padding">
                          <IonCol>
                            <PhoneInput
                              placeholder={
                                'User, Insert your phone number for help'
                              }
                              country={'il'}
                              value={value}
                              onChange={setValue}
                              alwaysDefaultMask={true}
                              enableAreaCodes={true}
                              countryCodeEditable={false}
                              disableCountryCode={false}
                            />
                          </IonCol>
                        </IonRow>
                        {/* Role*/}{' '}
                        <IonItem>
                          {console.log('role -->', role)}
                          <IonLabel color="primary">CHOOSE ROLE</IonLabel>
                          <IonSelect
                            // interfaceOptions={customActionSheetOptions}
                            interface="action-sheet"
                            placeholder="Select Role"
                            onIonChange={handleRole}
                          >
                            <IonSelectOption value="isTourGuide">
                              Tour Guide
                            </IonSelectOption>
                            <IonSelectOption value="isProvider">
                              Provider
                            </IonSelectOption>
                            <IonSelectOption value="isRestaurant">
                              Restaurant
                            </IonSelectOption>
                            <IonSelectOption value="isAdmin">
                              Admin
                            </IonSelectOption>
                          </IonSelect>
                        </IonItem>
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
              <IonFooter color="primary">
                <IonToolbar>
                  <IonRow>
                    <IonCol>
                      <IonButton
                        expand="block"
                        onClick={formikProps.handleSubmit}
                        mode="ios"
                      >
                        ADD USER
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonToolbar>
              </IonFooter>
            </IonPage>
          </Design>
        </div>
      )}
    </Formik>
  );
}
const Design = styled.div`
  .r1 {
    /* display: flex;
    height: 80vh;
    align-items: center; */
  }
  .col {
  }
  .react-tel-input {
    color: black;
  }
  .react-tel-input .form-control {
    height: 40px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-self: center;
    justify-content: center;
  }
`;
export default AddTourGuide;
