import React, { useEffect, useState } from 'react';
import { IonProgressBar } from '@ionic/react';

function Progress({ isRever }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => prevProgress + 0.01);
    }, 50);

    return () => clearInterval(interval);
  }, []);

  if (progress > 1) {
    setTimeout(() => {
      setProgress(0);
    }, 100);
  }

  return (
    <div isRever={isRever}>
      <IonProgressBar
        reversed={isRever}
        style={{ height: '6px' }}
        type="indeterminate"
        color="secondary"
        value={progress}
      ></IonProgressBar>
    </div>
  );
}
export default Progress;
