import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { ProductContext } from '../../context/product';
import Clipboard from 'react-clipboard.js';
import { BASE_URL } from '../../constants/AppConstants';
import { BASE_URL_SHARE } from '../../constants/AppConstants';
import Menu from '../../components/Menu';
import axios from 'axios';
import Moment from 'react-moment';
import styled from 'styled-components';
import Progress from '../../components/Porgress';
import TouristPageItemSlide from '../../components/TouristPageItemSlide1';
// import TouristPageItemSlide from '../../components/TouristPageItemSlide';
import {
  IonContent,
  IonPage,
  IonText,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonBadge,
  useIonToast,
  IonIcon,
  IonAccordion,
  IonAccordionGroup,
  IonTabButton,
  IonFooter,
  IonToolbar,
  IonNote,
  IonDatetime,
  IonTextarea,
  useIonActionSheet,
} from '@ionic/react';
import {
  refreshCircle,
  share,
  thumbsUpOutline,
  thumbsDownOutline,
  checkmarkCircle,
  closeCircle,
  filterOutline,
} from 'ionicons/icons';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';

function FinalShopPage() {
  const accordionGroup = useRef();
  const { touristOrders, finalOrders } = React.useContext(ProductContext);
  const [result, setResult] = useState();
  const [present1] = useIonActionSheet();
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnActive, setIsBtnActive] = useState(true);
  const [present] = useIonToast();
  const { sid } = useParams();
  const myOrder = finalOrders.filter((item) => item.sid === sid); // SHOP NAME
  const myFinalOrderDetails = finalOrders.filter((item) => item.sid === sid);
  const ids = myFinalOrderDetails.map((item) => item.id);
  const date1 = myOrder.map((item) => item.date)[0];
  const considerTourLeader = myOrder.map((item) => item.considerTourLeader)[0];
  const shopTitle = myOrder.map((item) => item.shopTitle);
  const country = myOrder.map((item) => item.country);
  const country1 = country.map((item) => item.label);
  const tourLeaderC = myOrder.map((item) => item.tourLeaderC).toString();
  const tourLeaderName = myOrder.map((item) => item.tourLeaderName).toString();
  const statusofOrder = myOrder.map((item) => item.statusofOrder).toString();
  const tourGuideName = myOrder.map((item) => item.tourGuideName).toString();
  const [created_at, setCreated_at] = useState(
    myOrder.map((item) => item.created_at).toString()
  );
  const [btnToggler, setBtnToggler] = useState(false);
  const note = myOrder.map((item) => item.note);
  const id = myOrder.map((item) => item.id);
  const [deliveryTime, setdeliveryTime] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [deliveryLocation, setDeliveryLocation] = useState('');
  const [guidePhone, setguidePhone] = useState(null);
  const FinalTouristOrders = touristOrders.filter((item) => item.sid === sid);
  const FinaCustomerOrders = FinalTouristOrders.map(
    (item) => item.customerOrders
  );
  const FinalforSumOrders = FinalTouristOrders.map((item) => item.total);
  const FinalorersDollars = FinalforSumOrders.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);

  // sorting start
  const [dataObject, setdataObject] = useState(FinalTouristOrders);
  const [statuses, setStatuses] = useState(
    dataObject.map((it) =>
      it.customerOrders.map((item) => {
        return item.statusofOrder;
      })
    )
  );
  const allStatuses = statuses.flat(1);
  const sortByExpnesive = () => {
    setdataObject((FinalTouristOrders) => {
      const dataToSort = [...FinalTouristOrders];
      dataToSort.sort((a, b) => Number(a.total) - Number(b.total));
      return dataToSort;
    });
  };

  const sortByLow = () => {
    setdataObject((FinalTouristOrders) => {
      const dataToSort = [...FinalTouristOrders];
      dataToSort.sort((a, b) => Number(b.total) - Number(a.total));
      return dataToSort;
    });
  };

  const sortByDateHigh = () => {
    setdataObject((FinalTouristOrders) => {
      const dataToSort = [...FinalTouristOrders];
      dataToSort.sort((a, b) => new Date(b.date) - new Date(a.date));
      return dataToSort;
    });
  };

  const sortByDateLow = () => {
    setdataObject((FinalTouristOrders) => {
      const dataToSort = [...FinalTouristOrders];
      dataToSort.sort((a, b) => new Date(a.date) - new Date(b.date));
      return dataToSort;
    });
  };

  // sorting End
  useEffect(() => {
    setdeliveryTime(myOrder.map((item) => item?.deliveryTime));
    setDeliveryDate(
      myOrder.map((item) => {
        return item?.deliveryDate;
      })
    );
    setDeliveryLocation(myOrder.map((item) => item?.deliveryLocation));
    setguidePhone(myOrder.map((item) => item.guidePhone).toString());
  }, []);

  const presentToast = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message: ' Lined copied, Please share it with your Tourist ',
      duration: 1500,
      position: 'bottom',
      color: 'success',
      icon: thumbsUpOutline,
      animated: true,
    });
  };
  const presentToast2 = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message: ' Final order have been updated ',
      duration: 1500,
      position: 'bottom',
      color: 'success',
      icon: thumbsUpOutline,
    });
  };
  const ErrorUpdate = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message:
        'Order can not be update, please try again, or check your internet connection',
      duration: 1500,
      position: 'bottom',
      color: 'danger',
      icon: thumbsDownOutline,
    });
  };

  const HandelUpdateOrder = async () => {
    if (
      guidePhone.length > 9 &&
      deliveryDate &&
      deliveryTime &&
      deliveryLocation
    ) {
      setBtnToggler(true);

      await Promise.all(
        FinalTouristOrders.map(async (tourist) => {
          // return axios.put(`${BASE_URL}/api/finalorder/${ids}/update-final/`, {
          return axios.put(
            `https://shop.holyland.app/api/finalorder/${ids}/info`,
            {
              deliveryTime: deliveryTime,
              deliveryDate:
                deliveryDate && typeof deliveryDate === 'string'
                  ? deliveryDate
                  : deliveryDate[0],
              deliveryLocation: deliveryLocation,
              guidePhone: guidePhone,
              // customerOrders: [tourist],
              customerOrders: FinaCustomerOrders,
              statusofOrder: 'senToShop',
              total: FinalorersDollars,
              // country: country,
            }
          );
        })
      )
        .then((responses) => {
          // Handle successful responses from all the requests
          setBtnToggler(false);
          setIsBtnActive(false);
          presentToast2();
          setInterval(() => {
            window.location.reload(true);
          }, 300);
        })
        .catch((err) => {
          // Handle errors from any of the requests
          ErrorUpdate();
          console.log(err);
        });
    }
  };

  const UpdatePHoeAndLocation = () => {
    HandelUpdateOrder();
  };

  const reFreshOrder = () => {
    setIsLoading(true);
    setTimeout(() => {
      window.location.reload(true);
    }, 300);
  };
  const shareShop = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  return (
    <div id="all">
      <Design>
        <IonPage style={{ paddingTop: '63px' }}>
          <Menu what={shopTitle} backBtnLink="/GuideShops" display={true} />
          {console.log('dataObject==>', dataObject)}
          {console.log('allStatuses==>', allStatuses)}
          {console.log('finalOrders==>', finalOrders)}
          {console.log('touristOrders===>', touristOrders)}
          {console.log('myOrder==>', myOrder)}
          {console.log('deliveryTime==>', deliveryTime)}
          {console.log('deliveryDate==>', deliveryDate)}
          {console.log('deliveryLocation==>', deliveryLocation)}
          {console.log('guidePhone==>', guidePhone)}
          {console.log('statusofOrder==>', statusofOrder)}
          {console.log('FinalTouristOrders==>', FinalTouristOrders)}
          {console.log('FinaCustomerOrders==>', FinaCustomerOrders)}
          {/* {console.log(' customerOrders==>', customerOrders)} */}
          {console.log('total==>', FinalorersDollars)}
          {console.log('==================================')}
          <IonContent>
            {isLoading && <Progress />}
            <IonGrid>
              {/* Toolbar top */}
              {allStatuses.every((it) => it === 'closed') ||
              allStatuses.every((it) => it === 'onTheWay') ||
              allStatuses.every((it) => it === 'received') ? (
                <></>
              ) : (
                <>
                  <IonToolbar style={{ padding: '5px' }}>
                    <IonRow>
                      <IonCol>
                        <IonTabButton tab="schedule" mode="ios">
                          <Clipboard
                            onSuccess={() => presentToast('middle')}
                            component="a"
                            // button-href="#"
                            data-clipboard-text={`https://shopbus.holyland.app/shop/${id}`}
                          >
                            <IonButton
                              fill="solid"
                              color="light"
                              mode="ios"
                              onClick={() => shareShop()}
                            >
                              <IonIcon
                                icon={share}
                                size="large"
                                color="primary"
                              />
                            </IonButton>
                          </Clipboard>
                          <IonLabel>
                            <h3>Share </h3>
                          </IonLabel>
                        </IonTabButton>
                      </IonCol>
                      <IonCol>
                        <IonTabButton tab="speakers" mode="ios">
                          <IonButton
                            fill="solid"
                            color="light"
                            onClick={() => reFreshOrder()}
                          >
                            <IonIcon
                              icon={refreshCircle}
                              size="large"
                              color="primary"
                            />
                          </IonButton>
                          <IonLabel>
                            <h3>Refresh orders</h3>
                          </IonLabel>
                        </IonTabButton>
                      </IonCol>
                    </IonRow>
                  </IonToolbar>
                </>
              )}

              {/* toolbar end */}

              {/* EARNING MONEY */}
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonItem>
                      <IonBadge slot="end" style={{ fontSize: '15px' }}>
                        {shopTitle}
                      </IonBadge>
                      <IonLabel>Shop name:</IonLabel>
                    </IonItem>

                    <IonItem>
                      {console.log(
                        'hereeee',
                        allStatuses.every((it) => it === 'onTheWay')
                      )}

                      {(() => {
                        if (allStatuses.every((it) => it === 'preparing')) {
                          return (
                            <IonBadge
                              slot="end"
                              style={{ fontSize: '15px' }}
                              color="secondary"
                            >
                              prepairing
                            </IonBadge>
                          );
                        }
                        if (allStatuses.every((it) => it === 'closed')) {
                          return (
                            <IonBadge
                              slot="end"
                              style={{ fontSize: '15px' }}
                              color="success"
                            >
                              closed
                            </IonBadge>
                          );
                        }
                        if (allStatuses.every((it) => it === 'onTheWay')) {
                          return (
                            <IonBadge
                              slot="end"
                              style={{ fontSize: '15px' }}
                              color="tertiary"
                            >
                              On the way
                            </IonBadge>
                          );
                        }
                        if (allStatuses.every((it) => it === 'received')) {
                          return (
                            <IonBadge
                              slot="end"
                              style={{ fontSize: '15px' }}
                              color="success"
                            >
                              received
                            </IonBadge>
                          );
                        } else {
                          return (
                            <IonBadge
                              slot="end"
                              style={{ fontSize: '15px' }}
                              color="secondary"
                            >
                              prepairing
                            </IonBadge>
                          );
                        }
                      })()}

                      <IonLabel>Order status:</IonLabel>
                    </IonItem>
                    {}
                    <IonItem>
                      <p>
                        <IonText color="medium">{note} </IonText>
                      </p>
                    </IonItem>
                    <IonItem>
                      <IonBadge
                        slot="end"
                        style={{ fontSize: '18px', color: 'white' }}
                        color="success"
                      >
                        {FinalorersDollars} $
                      </IonBadge>
                      <IonLabel>Total Dollars:</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
              {/* ENDERANING MONEY CARD */}

              {/* accordion start */}
              <IonAccordionGroup ref={accordionGroup} multiple={true}>
                <IonAccordion value="first">
                  <IonItem slot="header" color="light">
                    <IonLabel>Order details</IonLabel>
                  </IonItem>
                  <div slot="content">
                    <IonList inset={true}>
                      <IonItem>
                        <IonLabel>Group country: </IonLabel>

                        <IonNote slot="end" color="primary">
                          {country1}
                        </IonNote>
                      </IonItem>

                      <IonItem>
                        <IonLabel>Created Date:</IonLabel>
                        <IonNote slot="end" color="primary">
                          {/* {date1} */}
                          <Moment format="YYYY/MM/DD , h:mm:ss a">
                            {created_at}
                          </Moment>
                        </IonNote>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Phone number :</IonLabel>
                        <IonNote slot="end" color="primary">
                          + {guidePhone}
                        </IonNote>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Tour Guide name</IonLabel>
                        <IonNote slot="end" color="primary">
                          {tourGuideName}
                        </IonNote>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Tour Leader name</IonLabel>
                        <IonNote slot="end" color="primary">
                          {tourLeaderName}
                        </IonNote>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Consider tour Leader :</IonLabel>
                        <IonNote slot="end" color="primary">
                          {considerTourLeader ? (
                            <IonIcon
                              icon={checkmarkCircle}
                              size="large"
                              color="primary"
                            />
                          ) : (
                            <IonIcon
                              icon={closeCircle}
                              size="large"
                              color="primary"
                            />
                          )}
                          {/* {considerTourLeader} */}
                        </IonNote>
                      </IonItem>
                      {considerTourLeader && (
                        <IonItem>
                          <IonLabel>Tour Leader commission</IonLabel>
                          <IonNote slot="end" color="primary">
                            {tourLeaderC}%
                          </IonNote>
                        </IonItem>
                      )}

                      <IonItem>
                        <IonLabel>Total orders</IonLabel>
                        <IonNote slot="end" color="primary">
                          {FinalforSumOrders.length} orders
                        </IonNote>
                      </IonItem>
                      <IonItem>
                        <IonLabel> Total Money on Dollars</IonLabel>
                        <IonNote slot="end" color="primary">
                          {FinalorersDollars} $
                        </IonNote>
                      </IonItem>
                    </IonList>
                  </div>
                </IonAccordion>

                <IonAccordion value="second" class="ion-padding-vertical">
                  <IonItem slot="header" color="light">
                    <IonBadge slot="end" color="primary">
                      {FinalTouristOrders.length}
                    </IonBadge>
                    <IonLabel>Tourists orders</IonLabel>
                  </IonItem>
                  <div slot="content">
                    <IonRow>
                      <IonCol>
                        <IonItem>
                          <IonLabel>SORT YOUR RESULTS</IonLabel>
                          <IonButton
                            slot="end"
                            onClick={() =>
                              present1({
                                header: 'SORT YOUR RESULTS',
                                animated: true,

                                buttons: [
                                  {
                                    text: 'Sort by Total Low to High',

                                    translucent: true,
                                    handler: () => {
                                      sortByExpnesive();
                                    },
                                  },

                                  {
                                    text: 'Sort by Total High to Low',

                                    translucent: true,
                                    handler: () => {
                                      sortByLow();
                                    },
                                  },

                                  {
                                    text: 'Sort by Date hight to low',

                                    translucent: true,
                                    handler: () => {
                                      sortByDateHigh();
                                    },
                                  },
                                  {
                                    text: 'Sort by Date Low to High',

                                    translucent: true,
                                    handler: () => {
                                      sortByDateLow();
                                    },
                                  },
                                  {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    data: {
                                      action: 'cancel',
                                    },
                                  },
                                ],
                                onDidDismiss: ({ detail }) => setResult(detail),
                              })
                            }
                          >
                            <IonIcon icon={filterOutline} color="light" />
                          </IonButton>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    {dataObject.map((order, index) => {
                      return (
                        <TouristPageItemSlide
                          {...order}
                          key={index}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                        />
                      );
                    })}
                  </div>
                </IonAccordion>
                <IonAccordion value="third">
                  <IonItem slot="header" color="light">
                    <IonLabel>Choose Date, Time, Location</IonLabel>
                  </IonItem>

                  <div className="ion-no-padding" slot="content">
                    <IonGrid>
                      <IonRow class="ion-padding">
                        <IonCol>
                          <IonText>
                            <h3>Insert where you want to recieve the Gods</h3>
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonItem counter={true}>
                            <IonTextarea
                              value={deliveryLocation}
                              maxlength={100}
                              onIonChange={(e) =>
                                setDeliveryLocation(e.target.value.toString())
                              }
                              placeholder="Insert Delivery location"
                            ></IonTextarea>
                          </IonItem>
                        </IonCol>
                      </IonRow>

                      <IonRow class="ion-padding">
                        <IonCol>
                          <IonText>
                            <h3>Choose Delivery Date</h3>
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          {deliveryDate ? (
                            <IonDatetime
                              value={
                                deliveryDate && typeof deliveryDate === 'string'
                                  ? deliveryDate
                                  : deliveryDate[0]
                              }
                              presentation="date"
                              size="cover"
                              // showDefaultButtons={true}
                              onIonChange={(e) => {
                                // console.log(deliveryDate);
                                // setDeliveryDate(e.target.value.split('T')[0])
                                setDeliveryDate(e.target.value);
                              }}
                            ></IonDatetime>
                          ) : (
                            ''
                          )}
                        </IonCol>
                      </IonRow>
                      <IonItem>
                        <IonBadge slot="end" style={{ fontSize: '15px' }}>
                          {deliveryDate === null ? (
                            <>
                              <IonLabel>Please choose date</IonLabel>
                            </>
                          ) : (
                            <>
                              {deliveryDate ? (
                                <Moment format="YYYY/MM/DD">
                                  {Array.isArray(deliveryDate) === true
                                    ? deliveryDate[0]
                                    : deliveryDate}
                                </Moment>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </IonBadge>
                        <IonLabel>Choosen date:</IonLabel>
                      </IonItem>

                      <IonRow class="ion-padding">
                        <IonCol>
                          <IonText>
                            <h3>Choose Delivery Time</h3>
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <IonDatetime
                            presentation="time"
                            value={deliveryTime}
                            size="cover"
                            onIonChange={(e) => {
                              // setdeliveryTime(e.target.value.split('T')[1])
                              setdeliveryTime(e.target.value);
                              console.log(
                                '======================MEEEE========================='
                              );
                              console.log(e.target.value);
                              console.log(
                                '==============================================='
                              );
                            }}
                          ></IonDatetime>
                        </IonCol>
                      </IonRow>
                      <IonItem>
                        <IonBadge slot="end" style={{ fontSize: '15px' }}>
                          {deliveryTime == null ? (
                            <>
                              {' '}
                              <IonLabel>Please choose Time:</IonLabel>
                            </>
                          ) : (
                            <>
                              <Moment format="hh:mm:ss">{deliveryTime}</Moment>
                            </>
                          )}
                        </IonBadge>
                        <IonLabel>Choosen Time:</IonLabel>
                      </IonItem>
                      <IonRow class="ion-padding">
                        <IonCol>
                          <IonNote>update phone number</IonNote>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol>
                          <PhoneInput
                            placeholder={
                              'Tour guide, Insert your phone number for help'
                            }
                            country={'il'}
                            value={guidePhone}
                            onChange={setguidePhone}
                            alwaysDefaultMask={true}
                            enableAreaCodes={true}
                            // disableCountryCode={true}
                          />
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
              {/* accordion end*/}
            </IonGrid>
          </IonContent>

          <IonFooter color="primary">
            <IonToolbar>
              <IonRow>
                <IonCol>
                  {/* {statusofOrder === 'open' && (
                    <IonButton
                      disabled={
                        deliveryDate && deliveryTime && deliveryLocation
                          ? false
                          : true
                      }
                      onClick={UpdatePHoeAndLocation}
                      expand="block"
                      color="primary"
                    >
                      MAKE FINAL ORDER
                    </IonButton>
                  )} */}
                  {allStatuses.includes('preparing', 'new order') && (
                    <IonButton
                      disabled={
                        deliveryDate && deliveryTime && deliveryLocation
                          ? false
                          : true
                      }
                      onClick={UpdatePHoeAndLocation}
                      expand="block"
                      color="primary"
                    >
                      UPDATE ORDER
                    </IonButton>
                  )}
                  {isBtnActive ? (
                    <>
                      {statusofOrder === 'prepairing' && (
                        <IonButton
                          expand="block"
                          color="primary"
                          onClick={UpdatePHoeAndLocation}
                          disabled={btnToggler}
                        >
                          {' '}
                          <IonText style={{ color: 'white' }}>
                            UPDATE FINAL ORDER
                          </IonText>
                        </IonButton>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {/* {statusofOrder === 'preparing' && (
                    <IonButton
                      expand="block"
                      color="primary"
                      onClick={HandelUpdateOrder}
                      // mode="ios"
                    >
                      <IonText style={{ color: 'white' }}>
                        UPDATE FINAL ORDER
                      </IonText>
                    </IonButton>
                  )}
                  {statusofOrder === 'onTheWay' && (
                    <IonButton disabled expand="block" color="primary">
                      <IonText style={{ color: 'white' }}>
                        ORDER IN THE WAY
                      </IonText>
                    </IonButton>
                  )}
                  {statusofOrder === 'received' && (
                    <IonButton disabled expand="block" color="primary">
                      <IonText style={{ color: 'white' }}>
                        ORDER RECEIVED
                      </IonText>
                    </IonButton>
                  )}
                  {statusofOrder === 'closed' && (
                    <IonButton disabled expand="block" color="primary">
                      <IonText style={{ color: 'white' }}>
                        ORDER IS CLOSED
                      </IonText>
                    </IonButton>
                  )} */}
                </IonCol>
              </IonRow>
            </IonToolbar>
          </IonFooter>
        </IonPage>
      </Design>
    </div>
  );
}
const Design = styled.div`
  .react-tel-input .form-control {
    height: 40px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-self: center;
    justify-content: center;
  }
  .react-tel-input {
    color: black;
  }
`;
export default FinalShopPage;
