import { Document, Text, View, StyleSheet } from '@react-pdf/renderer';

function PdfComponent({ customerOrders, customerOrder1 }) {
  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    body2: {
      height: 400,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: 'Oswald',
      padding: 40,
    },
    products: {
      fontSize: 18,
      textAlign: 'left',
      fontFamily: 'Oswald',
      padding: 10,
      color: '#a2852e',
    },
    dollars: {
      margin: 12,
      fontSize: 25,
      textAlign: 'justify',
      fontFamily: 'Times-Roman',
      fontWeight: 'bold',
      color: '#a2852e',
    },
    boldFont: {
      margin: 12,
      fontSize: 18,
      textAlign: 'justify',
      fontFamily: 'Times-Roman',
      fontWeight: '900',
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: 'Oswald',
    },
    text: {
      margin: 40,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman',
    },
    text1: {
      margin: 40,
      fontSize: 14,
      textAlign: 'justify',
      fontWeight: 'bold',
      fontFamily: 'Times-Roman',
      color: '#a2852e',
    },
    image: {
      width: 100,
      height: 'auto',
      alignContent: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    line: {
      border: '1px solid #a2852e',
    },
    breakable: {
      width: '100%',
      height: 'auto',
      backgroundColor: '#f6f6f6',
    },
  });
  return (
    <div>
      {customerOrders.map((it, index) => {
        return (
          <>
            <Document>
              <View>
                <Text style={styles.text1}> {index + 1} - </Text>
                <Text style={styles.text}>
                  Product Name : {it.name} {''}
                  {''}/ Quantity : (
                  {/* {customerOrder1.filter((order) => order.id === it.id).length} */}
                  {it.quantity}
                  Q) {''}/ Product Price :{it.price}$ {''} {'\n'}
                  <hr />
                </Text>
              </View>
              <hr style={styles.line} />
            </Document>
          </>
        );
      })}
    </div>
  );
}

export default PdfComponent;
