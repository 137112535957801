import { useLocation, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import UserImage from '../../image/user1.png';
import axios from 'axios';
import Progress from '../../components/Porgress';
import {
  IonContent,
  IonPage,
  IonText,
  IonList,
  IonLabel,
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonTextarea,
  useIonToast,
  IonImg,
  IonSelect,
  IonSelectOption,
  IonFooter,
  IonToolbar,
} from '@ionic/react';
import {
  mailOutline,
  textOutline,
  documentTextOutline,
  logoFacebook,
  thumbsUpOutline,
} from 'ionicons/icons';
import * as yup from 'yup';
import Menu from '../../components/Menu';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import { BASE_URL } from '../../constants/AppConstants';
function UpdateUserProfile() {
  React.useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);

  const loginSchema = yup.object({
    name: yup.string().required('User name is required field'),
    facebook: yup.string(),
    bio: yup.string(),
  });
  function handleRole(event) {
    setRole(event.target.value);
  }
  const [present] = useIonToast();

  const history = useHistory();
  const location = useLocation();
  const { producto } = location.state;
  console.log('producto', producto);
  const [isLoading, setIsLoading] = useState(false);
  const email = producto.email;
  const [phone, setPhone] = useState(producto.phone);
  const name = producto.name;
  const bio = producto.bio;
  const facebook = producto.facebook;
  const id = producto.id;
  const image = producto.image;
  const value = 'null';
  // const role = producto.role;
  const [role, setRole] = useState(producto.role);
  const presentToast2 = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message: ' Your order have been updated',
      duration: 1500,
      position: 'bottom',
      color: 'success',
      icon: thumbsUpOutline,
    });
  };

  //here you can change cloudinary settings to be yours
  // --------------------------------------------------
  // const cloudName = "dfjgtalry";
  // const uploadPreset = "ahmedImageUpload";

  const handleSubmit = (name, bio, facebook) => {
    axios
      .put(`https://shop.holyland.app/api/users/update-user/${id}`, {
        email: email,
        name: name,
        bio: bio,
        facebook: facebook,
        phone: phone,
        role: role,
        // image: image,
      })
      .then((res) => {
        console.log(res.data);
        presentToast2();
        history.push('/tourGuideList');
        window.location.reload(true);
      });
  };
  return (
    <Design>
      <IonPage style={{ paddingTop: '65px' }}>
        {console.log('this is roleeeeeeeeee', role)}
        <Menu what="UPDATE USER" backBtnLink="/tourGuideList" display={true} />

        {console.log('image', image)}
        <IonContent fullscreen className="all-page">
          <Formik
            initialValues={{
              name: name,
              bio: bio,
              facebook: facebook,
            }}
            validationSchema={loginSchema}
            onSubmit={(values, actions) => {
              console.log(values);
              handleSubmit(values.name, values.bio, values.facebook);
            }}
          >
            {(formikProps) => (
              <div>
                {isLoading ? (
                  <>
                    <Progress />
                  </>
                ) : (
                  <></>
                )}
                {image ? (
                  <>
                    <div className="container">
                      <div>
                        <IonImg
                          src={JSON.parse(image).uploadInfo.url}
                          // src={image}
                          alt="Product featued image"
                        ></IonImg>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {' '}
                    <div className="container">
                      <div>
                        <IonImg
                          src={UserImage}
                          alt="Product featued image"
                        ></IonImg>
                      </div>
                    </div>
                  </>
                )}

                {console.log('this is the id=>', id)}
                <IonGrid style={{ height: '100%' }}>
                  <IonRow className="r1">
                    <IonCol class="ion-align-self-center">
                      <IonRow class="ion-padding">
                        <IonCol>
                          <IonText color="dark" class="ion-text-center">
                            <h3 class="ion-text-uppercase">
                              UPDATE USER DETAILS
                            </h3>
                          </IonText>
                        </IonCol>
                      </IonRow>
                      <IonList
                        class="margin-top-25 ion-margin margin-0-28"
                        className="list"
                      >
                        {/* Email filed */}
                        <IonRow>
                          <IonCol>
                            <IonItem
                              class="roundedInput8px margin-bottom-15 "
                              fill="outline"
                            >
                              <IonLabel>
                                <IonIcon icon={mailOutline}></IonIcon>
                              </IonLabel>
                              <IonInput
                                disabled={true}
                                autofocus={true}
                                clearInput={true}
                                type="email"
                                value={email}
                              ></IonInput>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        {/* Password */}
                        {/* <IonRow>
                          <IonCol>
                            <IonItem>
                              <IonLabel>
                                <IonIcon
                                  color="medium"
                                  icon={lockClosedOutline}
                                ></IonIcon>
                              </IonLabel>
                              <IonInput
                                disabled={true}
                                clearInput={true}
                                type="password"
                                value={password}
                              ></IonInput>
                            </IonItem>
                          </IonCol>
                        </IonRow> */}
                        {/* name */}
                        <IonRow>
                          <IonCol>
                            <IonItem>
                              <IonLabel>
                                <IonIcon
                                  color="medium"
                                  icon={textOutline}
                                ></IonIcon>
                              </IonLabel>
                              <IonInput
                                autocorrect="on"
                                clearInput={true}
                                type="text"
                                value={formikProps.values.name}
                                onIonChange={formikProps.handleChange('name')}
                                onBlur={formikProps.handleBlur('name')}
                              ></IonInput>
                            </IonItem>
                          </IonCol>
                        </IonRow>{' '}
                        <IonRow class="ion-padding" slot="end">
                          <IonCol>
                            <div
                              style={{
                                color: '#a2852e',
                                fontSize: '12px',
                              }}
                            >
                              {formikProps.touched.name &&
                                formikProps.errors.name}
                            </div>
                          </IonCol>
                        </IonRow>
                        {/* facebook */}
                        <IonRow>
                          <IonCol>
                            <IonItem>
                              <IonLabel>
                                <IonIcon
                                  color="medium"
                                  icon={logoFacebook}
                                ></IonIcon>
                              </IonLabel>
                              <IonInput
                                clearInput={true}
                                placeholder="Facbook"
                                type="text"
                                value={formikProps.values.facebook}
                                onIonChange={formikProps.handleChange(
                                  'facebook'
                                )}
                                onBlur={formikProps.handleBlur('facebook')}
                              ></IonInput>
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '12px',
                                  marginTop: '5px',
                                }}
                              >
                                {formikProps.touched.facebook &&
                                  formikProps.errors.facebook}
                              </div>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        {/* bio */}
                        <IonRow>
                          <IonCol>
                            {' '}
                            <IonItem counter={true}>
                              <IonLabel>
                                <IonIcon
                                  color="medium"
                                  icon={documentTextOutline}
                                ></IonIcon>
                              </IonLabel>
                              <IonTextarea
                                autoGrow={true}
                                placeholder="About this user"
                                clearInput={true}
                                type="text"
                                value={formikProps.values.bio}
                                onIonChange={formikProps.handleChange('bio')}
                                onBlur={formikProps.handleBlur('bio')}
                              ></IonTextarea>
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '12px',
                                  marginTop: '5px',
                                }}
                              >
                                {formikProps.touched.bio &&
                                  formikProps.errors.bio}
                              </div>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        {console.log('name=>', name)}
                        {/* phone */}
                        <IonRow class="ion-padding">
                          <IonCol>
                            <PhoneInput
                              placeholder={
                                'User, Insert your phone number for help'
                              }
                              country={'il'}
                              value={phone}
                              onChange={setPhone}
                              alwaysDefaultMask={true}
                              enableAreaCodes={true}
                              //   countryCodeEditable={false}
                              disableCountryCode={false}
                            />
                          </IonCol>
                        </IonRow>
                        <IonItem>
                          {console.log('role -->', role)}
                          <IonLabel color="primary">CHOOSE ROLE</IonLabel>
                          <IonSelect
                            // interfaceOptions={customActionSheetOptions}
                            value={role}
                            interface="action-sheet"
                            placeholder="Select Role"
                            onIonChange={handleRole}
                          >
                            <IonSelectOption value="isTourGuide">
                              Tour Guide
                            </IonSelectOption>
                            <IonSelectOption value="isProvider">
                              Provider
                            </IonSelectOption>
                            <IonSelectOption value="isRestaurant">
                              Restaurant
                            </IonSelectOption>
                            <IonSelectOption value="isAdmin">
                              Adminstrator
                            </IonSelectOption>
                          </IonSelect>
                        </IonItem>
                        {console.log('this is phone=>', value)}
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid>
                <IonFooter color="primary">
                  <IonToolbar>
                    <IonRow>
                      <IonCol>
                        <IonButton
                          expand="block"
                          onClick={formikProps.handleSubmit}
                          mode="ios"
                        >
                          UPDATE USER
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonToolbar>
                </IonFooter>
              </div>
            )}
          </Formik>
        </IonContent>
      </IonPage>
    </Design>
  );
}

const Design = styled.div`
  .r1 {
    display: flex;
    height: 80vh;
    align-items: center;
  }
  .col {
  }
  .react-tel-input {
    color: black;
  }
  .react-tel-input .form-control {
    height: 40px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-self: center;
    justify-content: center;
  }
  .container {
    position: relative;
    text-align: center;
    /* background-color: rgba(1, 1, 1, 0.4); */
  }
  .container {
    /* position: relative; */
    text-align: center;
    background-color: rgba(1, 1, 1, 0.4);
  }
  .centered {
    color: white;
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .name {
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.45);
  }
  ion-img::part(image) {
    height: 250px; /* or whatever value */
    /* you can set the height value too */
    object-fit: cover;
  }
`;
export default UpdateUserProfile;
