import React, { useState } from 'react';
import Moment from 'react-moment';
import { useHistory } from 'react-router-dom';
import {
  IonContent,
  IonList,
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonModal,
  IonIcon,
  IonNote,
  IonBadge,
} from '@ionic/react';
import {
  readerOutline,
  informationCircle,
  arrowDownCircleOutline,
} from 'ionicons/icons';
import { ProductContext } from '../context/product';

function Item({
  tourGuideName,
  tourLeaderC,
  tourLeaderName,
  total,
  date,
  customerOrders,
  statusofOrder,
  sid,
  deliveryTime,
  deliveryDate,
  country,
  guidePhone,
  shopTitle,
  setIsLoading,
  created_at,
  id,
}) {
  const history = useHistory();
  const { touristOrders } = React.useContext(ProductContext);
  console.log('touristOrders->', touristOrders);
  const [isOpen, setIsOpen] = useState(false);
  // const FinalforSumOrders = customerOrders.length;
  function refreshPage(sid) {
    setIsLoading(true);
    setTimeout(() => {
      history.push(`/AdminFinalOrderPage/${sid}`, {
        product1: {
          sid: sid,
        },
      });
    }, 200);
  }
  function pdfDownload(sid) {
    setIsLoading(true);
    setTimeout(() => {
      history.push(`/pdf/${sid}`, {
        product1: {
          sid,
        },
      });
    }, 300);
  }
  console.log(date);

  return (
    <div>
      {console.log('customerOrders==>', customerOrders)}
      <div
        id="wow"
        className="container"
        style={{ borderLeft: '5px solid #a2852e', marginBottom: '10px' }}
      >
        <IonList class="ion-no-padding" style={{ backgroundColor: '#e0e0e0' }}>
          {}
          <IonItem>
            <IonGrid>
              <IonRow class="ion-align-items-stretch">
                <IonCol size="7.5" onClick={() => refreshPage(sid)}>
                  <IonLabel class="ion-text-uppercase">{shopTitle}</IonLabel>
                  <IonLabel color="medium" style={{ fontSize: '12px' }}>
                    <Moment format="YYYY/MM/DD , h:mm:ss a">
                      {created_at}
                    </Moment>
                    {/* {FinalforSumOrders} */}
                  </IonLabel>
                  {statusofOrder === 'open' && (
                    <IonNote
                      color="medium"
                      style={{ fontWeight: 'bold', fontSize: '20px' }}
                    >
                      New order
                    </IonNote>
                  )}
                  {statusofOrder === 'senToShop' && (
                    <IonNote
                      color="warning"
                      style={{ fontWeight: 'bold', fontSize: '20px' }}
                    >
                      Start to prepair
                    </IonNote>
                  )}

                  {statusofOrder === 'preparing' && (
                    <IonNote
                      color="secondary"
                      style={{ fontWeight: 'bold', fontSize: '20px' }}
                    >
                      Preparing order
                    </IonNote>
                  )}
                  {statusofOrder === 'onTheWay' && (
                    <IonNote
                      color="tertiary"
                      style={{ fontWeight: 'bold', fontSize: '20px' }}
                    >
                      On the way
                    </IonNote>
                  )}
                  {statusofOrder === 'received' && (
                    <IonNote
                      color="success"
                      style={{ fontWeight: 'bold', fontSize: '20px' }}
                    >
                      Received
                    </IonNote>
                  )}
                  {statusofOrder === 'closed' && (
                    <IonNote
                      color="danger"
                      style={{ fontWeight: 'bold', fontSize: '20px' }}
                    >
                      Closed
                    </IonNote>
                  )}
                  <IonLabel
                    color="medium"
                    style={{ fontSize: '14px' }}
                  ></IonLabel>
                  <IonLabel color="medium" style={{ fontSize: '16px' }}>
                    {touristOrders.filter((item) => item.shopId === id).length}{' '}
                    order
                  </IonLabel>
                </IonCol>

                <IonCol size="1.5" style={{ paddingTop: '8px' }}>
                  <IonIcon
                    onClick={() => refreshPage(sid)}
                    icon={readerOutline}
                    style={{ fontSize: '30px' }}
                    color="primary"
                  />
                </IonCol>

                <IonCol
                  size="1.5"
                  class="ion-text-start"
                  style={{ paddingTop: '8px' }}
                >
                  <IonIcon
                    onClick={() => setIsOpen(true)}
                    icon={informationCircle}
                    style={{ fontSize: '30px' }}
                    color="primary"
                  />
                </IonCol>
                {statusofOrder === 'senToShop' ||
                statusofOrder === 'preparing' ||
                statusofOrder === 'onTheWay' ||
                statusofOrder === 'received' ||
                statusofOrder === 'closed' ? (
                  <>
                    <IonCol size="1.5" style={{ paddingTop: '8px' }}>
                      <IonIcon
                        onClick={() => pdfDownload(sid)}
                        icon={arrowDownCircleOutline}
                        style={{ fontSize: '30px' }}
                        color="primary"
                      />
                    </IonCol>
                  </>
                ) : (
                  <>
                    {' '}
                    {/* <IonCol size="1.5" style={{ paddingTop: '8px' }}>
                      <IonIcon
                        onClick={() => pdfDownload(sid)}
                        icon={archiveOutline}
                        style={{ fontSize: '30px' }}
                        color="danger"
                      />
                    </IonCol> */}
                  </>
                )}
              </IonRow>
            </IonGrid>
          </IonItem>
          <IonModal
            isOpen={isOpen}
            onDidDismiss={() => setIsOpen(false)}
            trigger="open-modal"
            initialBreakpoint={0.25}
            breakpoints={[0, 0.25, 0.5, 0.75]}
          >
            <IonContent className="ion-padding">
              <IonList inset={true}>
                <IonItem class="ion-padding">
                  <h2>Quick view of this order</h2>
                </IonItem>
                <IonItem>
                  <IonBadge slot="end" style={{ fontSize: '15px' }}>
                    {statusofOrder === 'senToShop' && (
                      <IonNote color="Warning">Send to shop</IonNote>
                    )}
                    {statusofOrder === 'open' && (
                      <IonNote color="Medium">Open</IonNote>
                    )}
                    {statusofOrder === 'preparing' && (
                      <IonNote color="light">Preparing order</IonNote>
                    )}
                    {statusofOrder === 'onTheWay' && (
                      <IonNote color="light">On the way</IonNote>
                    )}
                    {statusofOrder === 'received' && (
                      <IonNote color="light">Received</IonNote>
                    )}
                    {statusofOrder === 'closed' && (
                      <IonNote color="light">Closed</IonNote>
                    )}
                  </IonBadge>
                  <IonLabel>Status of order:</IonLabel>
                </IonItem>
                <IonItem>
                  <IonBadge slot="end" style={{ fontSize: '15px' }}>
                    {tourGuideName}
                  </IonBadge>
                  <IonLabel>Tour Guide name:</IonLabel>
                </IonItem>
                <IonItem>
                  <IonBadge slot="end" style={{ fontSize: '15px' }}>
                    {tourLeaderName}
                  </IonBadge>
                  <IonLabel>Tour leader name:</IonLabel>
                </IonItem>
                <IonItem>
                  <IonBadge slot="end" style={{ fontSize: '15px' }}>
                    {tourLeaderC}%
                  </IonBadge>
                  <IonLabel>Tour leader commision:</IonLabel>
                </IonItem>
                <IonItem>
                  <IonBadge
                    slot="end"
                    style={{ fontSize: '15px' }}
                    color="danger"
                  >
                    {total} $
                  </IonBadge>
                  <IonLabel>Total order :</IonLabel>
                </IonItem>
                <IonItem>
                  <IonBadge
                    slot="end"
                    style={{ fontSize: '15px' }}
                    color="danger"
                  >
                    <Moment format="YYYY/MM/DD">{deliveryDate}</Moment>
                  </IonBadge>
                  <IonLabel> Delivery Date:</IonLabel>
                </IonItem>
                <IonItem>
                  <IonBadge
                    slot="end"
                    style={{ fontSize: '15px' }}
                    color="danger"
                  >
                    <Moment format="hh:mm:ss">{deliveryTime}</Moment>
                  </IonBadge>
                  <IonLabel> Delivery time:</IonLabel>
                </IonItem>
                <IonItem>
                  <IonBadge
                    slot="end"
                    style={{ fontSize: '15px' }}
                    color="success"
                  >
                    {guidePhone}
                  </IonBadge>
                  <IonLabel>Phone :</IonLabel>
                </IonItem>
                <IonItem>
                  <IonBadge
                    slot="end"
                    style={{ fontSize: '15px' }}
                    color="secondary"
                  >
                    {country.label}
                  </IonBadge>
                  <IonLabel>Group country :</IonLabel>
                </IonItem>
              </IonList>
            </IonContent>
          </IonModal>
        </IonList>
      </div>
    </div>
  );
}

export default Item;
