import {
  IonButton,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonIcon,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonToolbar,
} from '@ionic/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { UserContext } from '../../context/user';
import useLocalStorage from '../../hooks/useLocalStorage';
import { duplicateOutline } from 'ionicons/icons';
import React, { useState, useEffect } from 'react';
import Loader from '../../components/Loading';
import Menu from '../../components/Menu';
import Pagination from '@mui/material/Pagination';
import Producto from '../../components/Productcardnew';
import { ProductContext } from '../../context/product';
import axios from 'axios'; // Import axios for API requests.
import styled from 'styled-components';

function ProductListPorvider() {
  const { user } = React.useContext(UserContext);
  const userId = user.user.id;
  const themeDark = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#262626',
        light: '#FFFFFF',
        dark: '#000000',
      },
      secondary: {
        main: '#a2852e',
      },
    },
  });
  const themeLight = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#a2852e',
      },
    },
  });
  const { products, ShowProducts, currentPage, loadingProduct } =
    React.useContext(ProductContext);
  console.log('all products-->', products);
  const FinalProviderProducts = products.data.filter(
    (item) => item.userId === userId
  );
  const [isLoading, setIsLoading] = useState(false);
  const [theme1, setTheme1] = useLocalStorage('theme', '');
  const isLoadingFunction = (x) => {
    setIsLoading(x);
  };
  console.log('page 111 --> ', currentPage);
  console.log('products --> ', products);

  const handlPageCount = (event, value) => {
    ShowProducts(value);
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 600);
  };

  return (
    <Design>
      {console.log('theme111=>', theme1)}
      <IonPage>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Menu what="PRODUCTS LIST " backBtnLink="/home" display={true} />
            {products?.data?.length === 0 ? (
              <IonContent fullscreen className="all-page">
                <IonGrid class="ion-justify-content-center">
                  <IonRow class="ion-justify-content-around">
                    <IonRow class="ion-padding">
                      <IonCol>
                        <IonText color="dark" class="ion-text-center">
                          <h3
                            class="ion-text-uppercase"
                            style={{ fontWeight: 'bold' }}
                          >
                            LIST OF ALL ORDERS
                          </h3>
                        </IonText>
                      </IonCol>
                    </IonRow>

                    <IonButton routerLink="/addProduct" routerDirection="none">
                      <IonIcon slot="start" icon={duplicateOutline} />
                      <IonLabel> Add Products </IonLabel>
                    </IonButton>
                  </IonRow>
                </IonGrid>
              </IonContent>
            ) : (
              <IonContent fullscreen className="all-page">
                <IonGrid>
                  <IonRow class="ion-padding">
                    <IonCol>
                      <IonText color="dark" class="ion-text-center">
                        <h3
                          class="ion-text-uppercase"
                          style={{ fontWeight: 'bold' }}
                        >
                          LIST OF ALL PRODUCTS
                        </h3>
                      </IonText>
                    </IonCol>
                  </IonRow>
                  {console.log(
                    'FinalProviderProducts ==========-->',
                    user.user.id
                  )}
                  {console.log('userIDDDDDDDDDDD-->', userId)}
                  {console.log(
                    'prodcuts----------------==>',
                    FinalProviderProducts
                  )}
                  {FinalProviderProducts
                    ? FinalProviderProducts?.map((item, key) => {
                        return (
                          <>
                            <div key={item.id}>
                              <Producto
                                style={{ paddingBottom: '10px' }}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                isLoadingFunction={() => isLoadingFunction()}
                                title={item.title}
                                about={item.about}
                                image={item.image}
                                price={item.price}
                                id={item.id}
                              />
                            </div>
                          </>
                        );
                      })
                    : ''}
                  <div className="pagi">
                    {theme1 === 'dark' ? (
                      <>
                        {' '}
                        <IonRow>
                          <IonCol class="ion-padding-bottom">
                            <ThemeProvider theme={themeDark}>
                              <Pagination
                                shape="rounded"
                                count={Math.ceil(
                                  Number(products?.meta?.pagination?.total) / 10
                                )}
                                page={currentPage}
                                onChange={handlPageCount}
                                color="secondary"
                                showFirstButton
                                showLastButton
                              />
                            </ThemeProvider>
                          </IonCol>
                        </IonRow>
                      </>
                    ) : (
                      <>
                        {' '}
                        <IonRow>
                          <IonCol class="ion-padding-bottom">
                            <ThemeProvider theme={themeLight}>
                              <Pagination
                                shape="rounded"
                                count={Math.ceil(
                                  Number(products?.meta?.pagination?.total) / 10
                                )}
                                page={currentPage}
                                onChange={handlPageCount}
                                color="primary"
                                showFirstButton
                                showLastButton
                              />
                            </ThemeProvider>
                          </IonCol>
                        </IonRow>
                      </>
                    )}
                  </div>
                </IonGrid>
              </IonContent>
            )}
          </>
        )}
        <IonFooter color="primary">
          <IonToolbar>
            <IonRow>
              <IonCol>
                <IonButton expand="block" routerLink="/addProduct" mode="ios">
                  ADD NEW PRODUCT
                </IonButton>
              </IonCol>
            </IonRow>
          </IonToolbar>
        </IonFooter>
      </IonPage>
    </Design>
  );
}
const Design = styled.div`
  .pagi {
    display: flex;
    justify-content: center;
  }
`;
export default ProductListPorvider;
