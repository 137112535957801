import React, { useState, useMemo } from 'react';
import { ProductContext } from '../context/product';
import { useHistory, useLocation } from 'react-router-dom';
import { UserContext } from '../context/user';
import axios from 'axios';
import styled from 'styled-components';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import Producto from '../components/ProductCardHome';
import Menu from '../components/Menu';
import PhoneInput from 'react-phone-input-2';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  IonContent,
  IonPage,
  IonText,
  IonLabel,
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonRadioGroup,
  IonModal,
  IonCheckbox,
  IonHeader,
  IonTitle,
  IonButtons,
  IonToolbar,
  IonIcon,
  useIonToast,
  IonNote,
  IonFooter,
  IonBadge,
} from '@ionic/react';
import { thumbsUpOutline, chevronBackOutline } from 'ionicons/icons';
import { BASE_URL } from '../constants/AppConstants';
function UpdateOrderPart1() {
  const [present] = useIonToast();
  const ConsiderSchema = yup.object({
    shopTitle: yup.string().required('Shop title is required').max(20),
    tourLeaderName: yup
      .string()

      .required('Tour leader name is required')
      .max(20),
    tourLeaderC: yup.number().max(100).required('commission is required'),
  });
  const NotConsiderSchema = yup.object({
    shopTitle: yup.string().required('Shop title is required').max(20),
    tourLeaderName: yup
      .string()

      .required('Tour leader name is required')
      .max(20),
  });
  const { products } = React.useContext(ProductContext);
  let history = useHistory();
  const location = useLocation();
  const { producto } = location.state;
  console.log('productooooooo', producto);
  const { user } = React.useContext(UserContext);
  const [considerTourLeader, setconsiderTourLeader] = useState(
    producto.considerTourLeader
  ); //ok
  const [isOpen, setIsOpen] = useState(false);
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const options = useMemo(() => countryList().getData(), []);

  const tourGuideName = producto.tourGuideName; //ok
  const total = producto.total; //ok
  const [productsList, setproductsList] = useState(producto.productsList); //ok

  // var arr = serviceList.filter((item) => activeIds.indexOf(item.id) === -1);
  // const FinalProductList = products.data.filter(
  //   (item) => productsList.indexOf(item.id) === -1
  // );
  const customerOrders = producto.customerOrders; //ok
  const userId = producto.userId; //ok
  const statusofOrder = producto.statusofOrder; //ok
  // const [country, setCountry] = useState(JSON.parse(producto.country)); //ok
  const [country, setCountry] = useState(producto.country); //ok
  const sid = producto.sid; //ok
  const deliveryTime = producto.deliveryTime; //ok
  const deliveryDate = producto.deliveryDate; //ok
  const note = producto.note;
  const deliveryLocation = producto.deliveryLocation; //ok
  const [guidePhone, setGuidePhone] = useState(producto.guidePhone); //ok

  const presentToast2 = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message: ' Your shop have been updated ',
      duration: 1500,
      position: 'bottom',
      color: 'success',
      icon: thumbsUpOutline,
    });
  };
  const addToFavorite = (id) => {
    if (!productsList.includes(id)) {
      setproductsList(productsList.concat(id));
    }
  };
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    ///.....
    placeholder: (base) => ({
      ...base,
      fontSize: '1em',
      color: 'black',
      fontWeight: 400,
    }),
  };
  const changeHandler = (value) => {
    console.log(isCountrySelected);
    setCountry(value);
    setIsCountrySelected(true);
  };
  const removeFavorite = (id) => {
    let index = productsList.indexOf(id);
    console.log(index);
    let temp = [
      ...productsList.slice(0, index),
      ...productsList.slice(index + 1),
    ];
    setproductsList(temp);
  };

  const handleSubmit = (shopTitle, tourLeaderName, tourLeaderC) => {
    axios
      // .put(`${BASE_URL}/api/finalorder/${producto.id}/update-shop`, {
      .put(
        `https://shop.holyland.app/api/finalorder/${producto.id}/update-shop`,
        {
          shopTitle,
          productsList,
          considerTourLeader,
          tourGuideName,
          tourLeaderName,
          tourLeaderC,
          total,
          userId,
          customerOrders,
          sid,
          statusofOrder,
          deliveryTime,
          deliveryDate,
          deliveryLocation,
          country,
          guidePhone,
          note,
        }
      )

      .then((res) => {
        presentToast2();

        history.push(`/GuideShops`);
        window.location.reload();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {console.log('this is productop id ', producto.id)}
      {console.log('this is country update', producto.country)}
      {console.log('this is productList update', productsList)}
      {console.log('this is all product ', products)}

      <StyleHome>
        <Formik
          initialValues={{
            shopTitle: producto.shopTitle,
            tourLeaderName: producto.tourLeaderName,
            tourLeaderC: producto.tourLeaderC,
          }}
          validationSchema={
            considerTourLeader ? ConsiderSchema : NotConsiderSchema
          }
          onSubmit={(values) => {
            handleSubmit(
              values.shopTitle,
              values.tourLeaderName,
              values.tourLeaderC
            );
          }}
        >
          {(formikProps) => (
            <IonPage>
              <Menu
                what="Update My shop"
                backBtnLink="/GuideShops"
                display={true}
              />

              <IonContent fullscreen className="all-page">
                <IonGrid>
                  <IonRow>
                    <IonCol class="ion-padding">
                      <IonText color="dark">
                        <h3 className="title">
                          Hey welcome back <br></br>
                          {user.user && (
                            <span className="name">Dear {user.user.name}</span>
                          )}
                        </h3>
                      </IonText>
                    </IonCol>
                  </IonRow>

                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonItem counter={true}>
                          <IonInput
                            maxlength={20}
                            inputmode="text"
                            type="text"
                            clearOnEdit={true}
                            clearInput={true}
                            placeholder="Shop Name"
                            value={formikProps.values.shopTitle}
                            onIonChange={formikProps.handleChange('shopTitle')}
                            onBlur={formikProps.handleBlur('shopTitle')}
                          ></IonInput>{' '}
                          <div
                            style={{
                              color: '#a2852e',
                              fontSize: '12px',
                              marginTop: '5px',
                            }}
                          >
                            {formikProps.touched.shopTitle &&
                              formikProps.errors.shopTitle}
                          </div>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol class="ion-padding">
                        <Select
                          placeholder="Select country of the your group"
                          options={options}
                          value={country}
                          onChange={changeHandler}
                          styles={customStyles}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol class="ion-flot-center">
                        <IonItem counter={true}>
                          <IonInput
                            counter={true}
                            maxlength={20}
                            inputmode="text"
                            type="text"
                            clearOnEdit={true}
                            clearInput={true}
                            placeholder="Tour leader name"
                            value={formikProps.values.tourLeaderName}
                            onIonChange={formikProps.handleChange(
                              'tourLeaderName'
                            )}
                            onBlur={formikProps.handleBlur('tourLeaderName')}
                          ></IonInput>
                          <div
                            style={{
                              color: '#a2852e',
                              fontSize: '12px',
                              marginTop: '5px',
                            }}
                          >
                            {formikProps.touched.tourLeaderName &&
                              formikProps.errors.tourLeaderName}
                          </div>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol class="ion-flot-center">
                        <IonRadioGroup allowEmptySelection={true}>
                          <IonItem>
                            <IonCheckbox
                              slot="end"
                              checked={considerTourLeader}
                              onIonChange={(event) =>
                                setconsiderTourLeader(event.target.checked)
                              }
                            ></IonCheckbox>
                            <IonLabel>Consider Tour leader</IonLabel>
                          </IonItem>
                        </IonRadioGroup>
                      </IonCol>
                    </IonRow>
                    {considerTourLeader ? (
                      <>
                        <IonRow>
                          <IonCol class="ion-flot-center">
                            <IonItem>
                              <IonLabel slot="end">%</IonLabel>
                              <IonInput
                                inputmode="numeric"
                                clearOnEdit={true}
                                clearInput={true}
                                placeholder="Commision"
                                type="number"
                                value={formikProps.values.tourLeaderC}
                                onIonChange={formikProps.handleChange(
                                  'tourLeaderC'
                                )}
                                onBlur={formikProps.handleBlur('tourLeaderC')}
                              ></IonInput>{' '}
                              <div
                                style={{
                                  color: '#a2852e',
                                  fontSize: '12px',
                                  marginTop: '5px',
                                }}
                              >
                                {formikProps.touched.tourLeaderC &&
                                  formikProps.errors.tourLeaderC}
                              </div>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </>
                    ) : (
                      <></>
                    )}
                    {/* <IonRow class="ion-padding">
                <IonCol>
                  <IonText>
                    <h2> Choose products to sell them to your tourists</h2>
                  </IonText>
                </IonCol>
              </IonRow> */}
                    {/* <FloatingWhatsApp
                      phoneNumber={+972528963616}
                      chatMessage={`Dear ${user.user.name}, we are happy to help you`}
                      avatar={Logo}
                    /> */}
                    <IonModal isOpen={isOpen}>
                      <IonHeader>
                        <IonToolbar color="primary">
                          <IonTitle>CHOOSE PRODUCT</IonTitle>
                          <IonButtons slot="start">
                            <IonIcon
                              onClick={() => setIsOpen(false)}
                              slot="start"
                              icon={chevronBackOutline}
                              size="large"
                            ></IonIcon>
                          </IonButtons>
                        </IonToolbar>
                      </IonHeader>
                      <IonContent className="ion-padding">
                        {console.log('this is products.data==>', products)}

                        {products.data.map((item, i) => {
                          return (
                            <>
                              <div key={i}>
                                <Producto
                                  // key={item.id}
                                  title={item.title}
                                  about={item.about}
                                  image={item.image}
                                  price={item.price}
                                  idProduct={item.id}
                                  addToFavorite={addToFavorite}
                                  removeFavorite={removeFavorite}
                                  productsList={productsList}
                                  isOpen={isOpen}
                                  setIsOpen={setIsOpen}
                                />
                              </div>
                            </>
                          );
                        })}

                        {/* {isCountrySelected && (
                          <IonButton
                            expand="block"
                            onClick={formikProps.handleSubmit}
                          >
                            SEND
                          </IonButton>
                        )} */}
                        {producto.statusofOrder === 'senToShop' ||
                        producto.statusofOrder === 'preparing' ||
                        producto.statusofOrder === 'onTheWay' ||
                        producto.statusofOrder === 'received' ||
                        producto.statusofOrder === 'closed' ? (
                          <>
                            <IonButton
                              disabled
                              expand="block"
                              onClick={formikProps.handleSubmit}
                            >
                              SHOP CAN NOT BE UPDATED
                            </IonButton>
                          </>
                        ) : (
                          <>
                            {producto.country && guidePhone.length >= 5 && (
                              <IonButton
                                expand="block"
                                onClick={formikProps.handleSubmit}
                              >
                                UPDATE SHOP
                              </IonButton>
                            )}
                          </>
                        )}
                      </IonContent>
                    </IonModal>
                    <IonRow class="ion-padding">
                      <IonCol>
                        <PhoneInput
                          placeholder={
                            'Tour guide, Insert your phone number for help'
                          }
                          country={'il'}
                          value={guidePhone}
                          onChange={setGuidePhone}
                          alwaysDefaultMask={true}
                          enableAreaCodes={true}
                          countryCodeEditable={false}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow class="ion-padding">
                      <IonCol>
                        <IonNote>
                          Dear tour guide, Please provide us your phone number
                          for more information. delivery ..
                        </IonNote>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonRow class="ion-padding">
                    <IonCol class="ion-text-center">
                      {productsList.length > 0 ? (
                        <>
                          {' '}
                          <IonItem>
                            <IonBadge slot="end" color="success">
                              {productsList.length}
                            </IonBadge>
                            <IonLabel>YOU CHOOSE </IonLabel>
                          </IonItem>
                          <IonButton
                            expand="block"
                            color="light"
                            size="large"
                            onClick={() => setIsOpen(true)}
                          >
                            ADD MORE PRODUCTS
                          </IonButton>
                        </>
                      ) : (
                        <>
                          <IonButton
                            expand="block"
                            color="light"
                            size="large"
                            onClick={() => setIsOpen(true)}
                          >
                            CHOOSE PRODUCTS
                          </IonButton>
                        </>
                      )}
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
              {country && guidePhone.length >= 7 && (
                <IonFooter>
                  <IonToolbar>
                    <IonRow>
                      <IonCol>
                        <IonButton
                          expand="block"
                          onClick={formikProps.handleSubmit}
                        >
                          UPDATE SHOP
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </IonToolbar>
                </IonFooter>
              )}
            </IonPage>
          )}
        </Formik>
      </StyleHome>
    </div>
  );
}
const StyleHome = styled.div`
  .react-tel-input .form-control {
    height: 40px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-self: center;
    justify-content: center;
  }
  /* .title {
    color: #898989;
    font-weight: bold;
  }
  .name {
    font-weight: bold;
    color: black;
  }
  .btn {
    position: absolute;
    bottom: 5px;
    text-align: center;
  }
  .country1 {
    display: none;
  } */
`;
export default UpdateOrderPart1;
