import { useParams } from 'react-router-dom';
import React from 'react';
import { ProductContext } from '../../context/product';
import {
  IonContent,
  IonPage,
  IonGrid,
  IonImg,
  setupIonicReact,
  IonCardHeader,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
} from '@ionic/react';

import Menu from '../../components/Menu';

function ProductPage() {
  setupIonicReact();
  const { id } = useParams();
  const { products } = React.useContext(ProductContext);
  const finalProducts = products.data;
  let product = finalProducts.filter((item) => item.id == id);

  const title = product.map((item) => item.title);
  const about = product.map((item) => item.about);
  const image = product.map((item) => item.image);
  return (
    <IonPage>
      {console.log('this is products=>', product)}
      <Menu what={title} backBtnLink="/productList" display={true} />
      <IonContent fullscreen className="all-page">
        <IonImg src={JSON.parse(image)} alt="Product featued image"></IonImg>

        <IonGrid>
          <IonCardHeader>
            <IonCardTitle class="ion-text-uppercase">{title}</IonCardTitle>
            <IonCardSubtitle class="ion-text-capitalize">
              About this product
            </IonCardSubtitle>
          </IonCardHeader>

          <IonCardContent>{about}</IonCardContent>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
}

export default ProductPage;
