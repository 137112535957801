import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { ProductContext } from '../../context/product';
import { BASE_URL } from '../../constants/AppConstants';
import Menu from '../../components/Menu';
import axios from 'axios';
import Moment from 'react-moment';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectCoverflow } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-coverflow';

import {
  IonContent,
  IonPage,
  IonText,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonBadge,
  useIonToast,
  IonIcon,
  IonAccordion,
  IonAccordionGroup,
  IonItemOptions,
  IonItemOption,
  IonItemSliding,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonNote,
  IonHeader,
  IonItemDivider,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardSubtitle,
  IonCardHeader,
} from '@ionic/react';
import { thumbsUpOutline, chevronBackOutline } from 'ionicons/icons';

import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
function ProviderFinalOrderPage() {
  const accordionGroup = useRef();
  const { touristOrders, finalOrders } = React.useContext(ProductContext);
  const [isOpen, setIsOpen] = useState(false);
  const [present] = useIonToast();
  const { sid } = useParams();
  let history = useHistory();
  const location = useLocation();
  const { product1 } = location.state;
  const [price, setPrice] = useState(product1.price);
  const [productName, setProductName] = useState(product1.productName);
  const [quantity, setQuantity] = useState(product1.quantity);
  const ProductImage = product1.ProductImage;
  const id1 = product1.orderId;
  const myOrder = finalOrders.filter((item) => item.sid === sid);
  const myFinalOrderDetails = finalOrders.filter((item) => item.sid === sid); // important
  const [product, setProduct] = useState([]);
  const [customerOrders1, setCustomerOrders1] = useState(
    product.map((it) => it.customerOrders)
  );
  const date1 = myFinalOrderDetails.map((item) => item.created_at);
  const [statusofOrder, setStatusofOrder] = useState(product1.statusofOrder);
  const [note, setNote] = useState(product1.note);

  const statusofOrder1 = myFinalOrderDetails
    .map((item) => item.statusofOrder)
    .toString();
  const tourGuideName = myFinalOrderDetails
    .map((item) => item.tourGuideName)
    .toString();

  const [deliveryTime, setdeliveryTime] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);
  const [deliveryLocation, setDeliveryLocation] = useState(null);
  const guidePhone = myFinalOrderDetails
    .map((item) => item.guidePhone)
    .toString();
  // const FinalTouristOrders = touristOrders.filter((item) => item.sid === sid);
  // const FinalforSumOrders = FinalTouristOrders.map((item) => item.total);
  // const FinalorersDollars = FinalforSumOrders.reduce((accumulator, value) => {
  //   return accumulator + value;
  // }, 0);

  const presentToast2 = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message: ' Final order have been updated ',
      duration: 1500,
      position: 'bottom',
      color: 'success',
      icon: thumbsUpOutline,
    });
  };

  const deleteOrder = (OrderId) => {
    axios
      .delete(`${BASE_URL}/api/useroders/${OrderId}`)
      .then((res) => {
        // console.log('deleted');

        window.location.reload(true);
      })
      .catch((error) => console.log(error));
  };

  const updateOrderStatus1 = () => {
    axios
      .put(`https://shop.holyland.app/api/update-final-status/${id1}`, {
        statusofOrder,
        note,
      })

      .then((res) => {
        // console.log(res.data);

        presentToast2();
        history.push(`/ProvidersOrders/${sid}`);
        setInterval(() => {
          window.location.reload(true);
        }, 1000);
      })

      .catch((error) => console.log(error));
  };

  function handleOrderChangeUpdate(event) {
    setStatusofOrder(event.target.value);
  }
  useEffect(() => {
    setdeliveryTime(myFinalOrderDetails.map((item) => item.deliveryTime));
    setDeliveryDate(myFinalOrderDetails.map((item) => item.deliveryDate));
    setDeliveryLocation(
      myFinalOrderDetails.map((item) => item.deliveryLocation)
    );
  }, [setdeliveryTime, setDeliveryDate, setDeliveryLocation]);

  return (
    <div>
      <Design>
        {console.log(' ProductImag-->', ProductImage)}
        {console.log(' idd of the order------------------>', id1)}
        {console.log('  myFinalOrderDetails----------->', myFinalOrderDetails)}
        {console.log('customerOrders1', customerOrders1)}
        {console.log('statusofOrder', statusofOrder)}
        {console.log('userorders', touristOrders)}
        <IonPage style={{ paddingTop: '70px' }}>
          <Menu
            what="ORDER DETAILS"
            backBtnLink={`/ProvidersOrders/${sid}`}
            display={true}
          />
          <IonContent>
            <IonGrid>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonList class="ion-padding-top">
                      <IonItem>
                        <IonBadge
                          slot="end"
                          style={{ fontSize: '15px' }}
                          color="primary"
                        >
                          {statusofOrder1}
                        </IonBadge>
                        <IonLabel>Order status:</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Update it</IonLabel>
                        <IonSelect
                          value={statusofOrder}
                          interface="action-sheet"
                          placeholder="update order status"
                          onIonChange={handleOrderChangeUpdate}
                        >
                          <IonSelectOption value="preparing">
                            Prepairing
                          </IonSelectOption>
                          <IonSelectOption value="onTheWay">
                            On the way
                          </IonSelectOption>
                          <IonSelectOption value="received">
                            Order received
                          </IonSelectOption>
                          <IonSelectOption value="closed">
                            Order closed
                          </IonSelectOption>
                        </IonSelect>
                      </IonItem>
                      <IonItem>
                        <IonTextarea
                          placeholder="Type note for this order"
                          autoGrow={true}
                          value={note}
                          onIonChange={(e) => setNote(e.target.value)}
                        ></IonTextarea>
                      </IonItem>
                    </IonList>

                    <IonItem>
                      <IonBadge
                        slot="end"
                        style={{ fontSize: '20px' }}
                        color="success"
                      >
                        {price * quantity} $
                      </IonBadge>
                      <IonLabel>Total Dollars:</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>
              </IonGrid>
              {/* ENDERANING MONEY CARD */}

              {/* accordion start */}
              <IonAccordionGroup ref={accordionGroup} multiple={true}>
                <IonAccordion value="first">
                  <IonItem slot="header" color="light">
                    <IonLabel>Order details</IonLabel>
                  </IonItem>
                  <div slot="content">
                    <IonList inset={true}>
                      <IonItem>
                        <IonLabel>Order status :</IonLabel>
                        {console.log(
                          'statusofOrder---------------?>',
                          statusofOrder
                        )}
                        {console.log('note---------------?>', note)}
                        <IonNote slot="end" color="primary">
                          {statusofOrder == 'null' && (
                            <IonNote color="success">New shop</IonNote>
                          )}
                          {statusofOrder === 'senToShop' && (
                            <IonNote color="success">Send to shop</IonNote>
                          )}
                          {statusofOrder === 'open' && (
                            <IonNote color="success">Open</IonNote>
                          )}
                          {statusofOrder === 'preparing' && (
                            <IonNote color="success">Preparing order</IonNote>
                          )}
                          {statusofOrder === 'onTheWay' && (
                            <IonNote color="success">On the way</IonNote>
                          )}
                          {statusofOrder === 'received' && (
                            <IonNote color="success">Received</IonNote>
                          )}
                          {statusofOrder === 'closed' && (
                            <IonNote color="danger">Closed</IonNote>
                          )}
                        </IonNote>
                      </IonItem>

                      <IonItem>
                        <IonLabel>Created Date:</IonLabel>
                        <IonNote slot="end" color="primary">
                          <Moment format="YYYY/MM/DD, h:mm:ss a">
                            {date1}
                          </Moment>
                        </IonNote>
                      </IonItem>
                      <IonItem>
                        <IonLabel>Phone number :</IonLabel>
                        <IonNote slot="end" color="primary">
                          {guidePhone}
                        </IonNote>
                      </IonItem>
                    </IonList>
                  </div>
                </IonAccordion>

                <IonAccordion value="third" class="ion-padding-vertical">
                  <IonItem slot="header" color="light">
                    <IonLabel>Delivery location, Date and time</IonLabel>
                  </IonItem>

                  <div className="ion-no-padding" slot="content">
                    <IonGrid>
                      <IonRow class="ion-padding">
                        <IonCol>
                          <IonText>
                            <h3>Delivery location, Date and time</h3>
                          </IonText>
                        </IonCol>
                      </IonRow>

                      <IonItem>
                        <IonBadge slot="end" style={{ fontSize: '15px' }}>
                          {deliveryLocation}
                        </IonBadge>
                        <IonLabel>Delivery Location</IonLabel>
                      </IonItem>

                      <IonItem>
                        <IonBadge slot="end" style={{ fontSize: '15px' }}>
                          {deliveryDate ? (
                            <Moment format="YYYY/MM/DD">
                              {Array.isArray(deliveryDate) === true
                                ? deliveryDate[0]
                                : deliveryDate}
                            </Moment>
                          ) : (
                            ''
                          )}{' '}
                        </IonBadge>
                        <IonLabel>Delivery Date</IonLabel>
                      </IonItem>

                      <IonItem>
                        <IonBadge slot="end" style={{ fontSize: '15px' }}>
                          {console.log(deliveryTime)}
                          {/* {deliveryTime === null ?  */}
                          <Moment format="hh:mm:ss">
                            {Array.isArray(deliveryTime) === true
                              ? deliveryTime[0]
                              : deliveryTime}
                          </Moment>
                          {/* } */}
                        </IonBadge>
                        <IonLabel>Delivery Time:</IonLabel>
                      </IonItem>
                      <IonItem>
                        <IonBadge slot="end" style={{ fontSize: '15px' }}>
                          + {guidePhone}
                        </IonBadge>
                        <IonLabel>Phone number:</IonLabel>
                      </IonItem>
                    </IonGrid>
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
              <IonRow class="no-ion-padding">
                <IonItemDivider class="ion-padding" color="light">
                  <IonLabel>Product chosen by tourist</IonLabel>
                </IonItemDivider>
                <Swiper
                  pagination={{
                    el: '.swiper-custom-pagination',
                    type: 'fraction',
                  }}
                  effect={'coverflow'}
                  grabCursor={true}
                  centeredSlides={true}
                  slidesPerView={'auto'}
                  coverflowEffect={{
                    rotate: 90,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                  }}
                  modules={[EffectCoverflow, Pagination]}
                  className="mySwiper"
                >
                  <div>
                    {console.log('wowwwwwwww', ProductImage)}
                    <SwiperSlide>
                      <IonCard>
                        <img
                          alt="Silhouette of mountains"
                          src={JSON.parse(ProductImage)}
                        />
                        <IonCardHeader>
                          <IonCardTitle>
                            {' '}
                            <h5> {productName}</h5>
                          </IonCardTitle>
                          <IonCardSubtitle>
                            <h3> {price} usd </h3>
                          </IonCardSubtitle>
                        </IonCardHeader>

                        <IonCardContent>{quantity}: QUANTITY</IonCardContent>
                      </IonCard>
                    </SwiperSlide>
                  </div>
                </Swiper>
              </IonRow>
              {/* accordion end*/}
            </IonGrid>
          </IonContent>
          <IonFooter color="primary">
            <IonToolbar>
              <IonRow>
                <IonCol>
                  <IonButton expand="block" onClick={updateOrderStatus1}>
                    UPDATE ORDER STATUS
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonToolbar>
          </IonFooter>
        </IonPage>
      </Design>
    </div>
  );
}
const Design = styled.div`
  .react-tel-input .form-control {
    height: 40px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-self: center;
    justify-content: center;
  }
`;
export default ProviderFinalOrderPage;
