import React, { useState, useEffect } from 'react';
import { ProductContext } from '../../context/product';
import { UserContext } from '../../context/user';
import styled from 'styled-components';
import Progress from '../../components/Porgress';
import {
  IonContent,
  IonPage,
  IonText,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
} from '@ionic/react';
import Menu from '../../components/Menu';
import MyShopsList from '../../components/MyShopsList';
import { addCircleOutline } from 'ionicons/icons';

function MyShops() {
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setProgress((prevProgress) => prevProgress + 0.01);
  //   }, 50);

  //   return () => clearInterval(interval);
  // }, []);

  // if (progress > 1) {
  //   setTimeout(() => {
  //     setProgress(0);
  //   }, 1000);
  // }

  const { finalOrders } = React.useContext(ProductContext);
  const { user } = React.useContext(UserContext);
  const Myshop = finalOrders.filter((item) => item.userId === user.user.id);

  return (
    <div>
      <Design>
        <IonPage>
          <Menu what=" MY ORDERS" backBtnLink="/home" display={true} />

          <IonContent fullscreen className="all-page">
            {isLoading && <Progress />}

            <IonRow class="ion-padding-vertical">
              <IonCol>
                <IonText color="dark" class="ion-text-center">
                  <h3 class="ion-text-uppercase">List of ORDERS</h3>
                </IonText>
              </IonCol>
            </IonRow>
            {console.log('finalorder all----->', finalOrders)}
            {console.log('user id----->', user.user.id)}
            {console.log('my shop----->', Myshop)}
            <IonRow>
              <IonCol class="ion-text-center">
                {Myshop.length === 0 && (
                  <>
                    {' '}
                    <IonRow class="ion-padding-vertical">
                      <IonCol>
                        <IonText color="medium" class="ion-text-center">
                          {/* <h3>List of shops created by {user.user.name}</h3> */}
                          <h5>You do not have any shop, add new</h5>
                        </IonText>
                      </IonCol>
                    </IonRow>
                    <IonRow class="ion-text-center">
                      <IonCol class="ion-text-center">
                        <IonButton
                          expand="block"
                          fill="solid"
                          color="primary"
                          routerLink="/Addshop"
                          size="large"
                        >
                          <IonIcon
                            slot="start"
                            icon={addCircleOutline}
                          ></IonIcon>
                          ADD NEW SHOP
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </>
                )}
              </IonCol>
            </IonRow>
            {Myshop.map((order, index) => {
              return (
                <div className="container">
                  <MyShopsList
                    {...order}
                    key={index}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                  />
                </div>
              );
            })}
          </IonContent>
        </IonPage>
      </Design>
    </div>
  );
}
const Design = styled.div`
  .my-custom-class {
    display: none !important;
  }
`;
export default MyShops;
