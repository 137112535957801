import { useParams, useLocation, useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import {
  IonContent,
  IonPage,
  IonText,
  IonList,
  IonLabel,
  IonTextarea,
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonImg,
  IonProgressBar,
  IonFooter,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import axios from 'axios';
import {
  textOutline,
  imageOutline,
  documentTextOutline,
  cashOutline,
  thumbsUpOutline,
} from 'ionicons/icons';
import Menu from '../components/Menu';
import { BASE_URL } from '../constants/AppConstants';

function UpdateProductNew() {
  const [present] = useIonToast();
  const { id } = useParams();
  let history = useHistory();
  const location = useLocation();
  const { producto } = location.state;
  //   const product = products.filter((item) => item.id === id);
  const [title, setTitle] = useState(producto.title);
  const [about, setAbout] = useState(producto.about);
  const [price, setPrice] = useState(producto.price);
  const [image, setImage] = useState(producto.image);
  const presentToast2 = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message: ' Product have been updated ',
      duration: 1500,
      position: 'bottom',
      color: 'success',
      icon: thumbsUpOutline,
    });
  };
  const [loading, setLoading] = useState(false);
  function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    console.log(value);
    return () => setValue((value) => ++value); // update the state to force render
  }
  const forceUpdate = useForceUpdate();
  const [featuredImage, setFeaturedImage] = useState(null);
  //here you can change cloudinary settings to be yours
  // --------------------------------------------------
  // const cloudName = "dfjgtalry";
  // const uploadPreset = "ahmedImageUpload";

  const cloudName = 'dehpovrxe';
  const uploadPreset = 'lqs73mbv';
  //---------------------------------------------------
  let featuredOptions = {
    cloudName,
    uploadPreset,
    thumbnailTransformation: [{ width: 400, crop: 'scale' }],
    sources: ['local'],
    styles: {
      palette: {
        window: '#F5F5F5',
        sourceBg: '#FFFFFF',
        windowBorder: '#c0c0c0',
        tabIcon: '#0094c7',
        inactiveTabIcon: '#69778A',
        menuIcons: '#0094C7',
        link: '#0ca8fd',
        action: '#F5F5F5',
        inProgress: '#0194c7',
        complete: '#53ad9d',
        error: '#c43737',
        textDark: '#000000',
        textLight: '#FFFFFF',
      },
    },
    maxFiles: 1,
    maxImageFileSize: 7000000,
    singleUploadAutoClose: true,
    resourceType: 'image',
  };
  let addVideofeaturedImage = () => {
    let featuredWidget = window.cloudinary.createUploadWidget(
      featuredOptions,
      (error, result) => {
        error ? console.log(result) : console.log('he');
        error ? console.log('error.status') : console.log('non');
        console.log(result.event);
        if (!error && result && result.event === 'queues-end') {
          console.log(result.event);
          console.log(
            'updated image to send',
            result.info.files[0].uploadInfo.url
          );
          result.info.files[0].uploadInfo != null &&
            setImage(JSON.stringify(result.info.files[0].uploadInfo.url));
          setFeaturedImage(result.info.files[0].uploadInfo.url);

          forceUpdate();
        }
      }
    );
    featuredWidget.open();
  };

  const HandelSubmitOrder = () => {
    setLoading(true);
    axios
      .put(`https://shop.holyland.app/api/product/${producto.id}`, {
        title,
        about,
        price,
        image,
      })

      .then((res) => {
        console.log(res);

        presentToast2();
        setLoading(false);
        history.push(`/productList`);
        window.location.reload();
      })
      .catch((error) => console.log(error));
  };

  return (
    <IonPage>
      <Menu what={'UPDARE PRODCUT'} backBtnLink="/productList" display={true} />
      <IonContent fullscreen className="all-page">
        {loading && <IonProgressBar type="indeterminate"></IonProgressBar>}

        {console.log('update image to send', image)}

        <div>
          <IonGrid style={{ height: '100%' }}>
            <IonRow class="ion-padding">
              <IonCol>
                <IonText color="dark" class="ion-text-center">
                  <h3 class="ion-text-uppercase">UPDATE PRODUCT</h3>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow
              class="ion-justify-content-center"
              style={{ height: '100%' }}
            >
              <IonCol
                class="ion-align-self-center"
                className="col"
                size-md="6"
                size-lg="5"
                size-xs="12"
              >
                <IonList
                  class="margin-top-25 ion-margin margin-0-28"
                  className="list"
                >
                  {' '}
                  {/* Email filed */}
                  <IonItem class="roundedInput8px margin-bottom-15 ">
                    <IonLabel>
                      <IonIcon icon={textOutline}></IonIcon>
                    </IonLabel>
                    <IonInput
                      type="text"
                      clearInput={true}
                      value={title}
                      placeholder="Title"
                      onIonChange={(e) => setTitle(e.target.value)}
                    ></IonInput>
                  </IonItem>
                  {/* About */}
                  <IonItem>
                    <IonLabel>
                      <IonIcon
                        color="medium"
                        icon={documentTextOutline}
                      ></IonIcon>
                    </IonLabel>
                    <IonTextarea
                      placeholder="About product"
                      clearOnEdit={true}
                      type="text"
                      autoGrow={true}
                      value={about}
                      onIonChange={(e) => setAbout(e.target.value)}
                    ></IonTextarea>
                  </IonItem>
                  {/* name */}
                  <IonItem>
                    <IonLabel>
                      <IonIcon color="medium" icon={cashOutline}></IonIcon>
                    </IonLabel>
                    <IonTextarea
                      placeholder="Price in dollars"
                      clearInput={true}
                      type="number"
                      value={price}
                      onIonChange={(e) => setPrice(e.target.value)}
                    ></IonTextarea>
                  </IonItem>
                  {/* product featured image */}
                  {/* show the image */}
                  <ion-list-header>
                    <ion-label>Product featued image</ion-label>
                  </ion-list-header>
                  {featuredImage ? (
                    <>
                      <IonImg
                        src={JSON.parse(image)}
                        class="ion-padding"
                      ></IonImg>
                    </>
                  ) : (
                    <>
                      {' '}
                      <IonImg
                        src={JSON.parse(image)}
                        class="ion-padding"
                      ></IonImg>
                    </>
                  )}
                  {/* end show the image */}
                  {featuredImage ? (
                    <>
                      <IonButton
                        fill="outline"
                        expand="block"
                        onClick={addVideofeaturedImage}
                      >
                        <IonIcon slot="end" icon={imageOutline}></IonIcon>
                        CHANGE IMAGE
                      </IonButton>
                    </>
                  ) : (
                    <>
                      <IonButton
                        fill="outline"
                        expand="block"
                        onClick={addVideofeaturedImage}
                      >
                        <IonIcon slot="end" icon={imageOutline}></IonIcon>
                        UPLOAD IMAGE
                      </IonButton>
                    </>
                  )}
                  {/* <IonButton
                    shape="round"
                    className="button"
                    mode="ios"
                    class="ion-margin-top"
                    expand="block"
                    size="large"
                    onClick={HandelSubmitOrder}
                    // onClick={() => console.log(title)}
                  >
                    <IonText color="white">UPDATE PRODUCT</IonText>
                  </IonButton> */}
                </IonList>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
      </IonContent>
      <IonFooter color="primary">
        <IonToolbar>
          <IonRow>
            <IonCol>
              <IonButton expand="block" onClick={HandelSubmitOrder} mode="ios">
                UPDATE PRODUCT
              </IonButton>
            </IonCol>
          </IonRow>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
}

export default UpdateProductNew;
