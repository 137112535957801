import React from 'react';
import { ProductContext } from '../../context/product';
import { useParams } from 'react-router-dom';
import Menu from '../../components/Menu';
import Logo from '../../image/logo.png';
import Moment from 'react-moment';
import NewCard from '../../components/NewCard';
import PdfComponent from '../../components/PdfComponent';
import styled from 'styled-components';
import {
  IonContent,
  IonPage,
  IonButton,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonBadge,
  IonAccordion,
  IonAccordionGroup,
  IonFooter,
  IonToolbar,
} from '@ionic/react';
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
  Font,
} from '@react-pdf/renderer';

function Pdftest() {
  const { sid } = useParams();
  const { finalOrders, touristOrders } = React.useContext(ProductContext);
  const Final = finalOrders.filter((item) => item.sid === sid);
  const tourGuideName = Final.map((item) => item.tourGuideName);
  const tourLeaderName = Final.map((item) => item.tourLeaderName);
  const orderId = Final.map((item) => item.id);
  const tourLeaderC = Final.map((item) => item.tourLeaderC);
  const total = Final.map((item) => item.total);
  const deliveryDate = Final.map((item) => item.deliveryDate);

  const deliveryTime = Final.map((item) => item.deliveryTime);
  const deliveryLocation = Final.map((item) => item.deliveryLocation);
  const guidePhone = Final.map((item) => item.guidePhone);
  const country = Final.map((item) => item.country);
  const country1 = country.map((item) => item.label);
  const FinalTouristOrders = touristOrders.filter((item) => item.sid === sid);
  Font.register({
    family: 'Oswald',
    src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
  });
  const convertTime = (date, moment) => {
    {
      return <Moment format="YYYY/MM/DD , h:mm:ss a">{date}</Moment>;
    }
  };

  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    body2: {
      height: 400,
    },
    title: {
      fontSize: 24,
      textAlign: 'center',
      fontFamily: 'Oswald',
      padding: 40,
    },
    products: {
      fontSize: 18,
      textAlign: 'left',
      fontFamily: 'Oswald',
      padding: 10,
      color: '#a2852e',
    },
    dollars: {
      margin: 12,
      fontSize: 25,
      textAlign: 'justify',
      fontFamily: 'Times-Roman',
      fontWeight: 'bold',
      color: '#a2852e',
    },
    boldFont: {
      margin: 12,
      fontSize: 18,
      textAlign: 'justify',
      fontFamily: 'Times-Roman',
      fontWeight: '900',
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: 'Oswald',
    },
    text: {
      margin: 8,
      fontSize: 14,
      textAlign: 'justify',
      fontFamily: 'Times-Roman',
    },
    text1: {
      margin: 8,
      fontSize: 25,
      textAlign: 'center',
      fontWeight: 'bold',
      fontFamily: 'Times-Roman',
      color: '#a2852e',
    },
    image: {
      width: 100,
      height: 'auto',
      alignContent: 'center',
      justifyContent: 'center',
      alignSelf: 'center',
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 50,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    orderId1: {
      position: 'absolute',
      fontSize: 12,
      bottom: 15,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    contact: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },
    line: {
      border: '1px solid #a2852e',
    },
    breakable: {
      width: '100%',
      height: 'auto',
      backgroundColor: '#f6f6f6',
    },
  });

  return (
    <>
      <Design>
        <IonPage style={{ paddingTop: '70px' }}>
          <Menu
            what={tourGuideName}
            backBtnLink="/allordersegments"
            display={true}
          />
          <IonContent>
            {console.log('Final order=>', Final)}
            {console.log('deliverytime', deliveryTime)}
            <IonContent>
              <div className="content" id="new1">
                <IonList inset={true}>
                  <IonItem class="ion-padding">
                    <h2 className="ion-text-center">VIEW OF THE ORDER</h2>
                  </IonItem>
                  <IonItem>
                    <IonBadge slot="end" style={{ fontSize: '15px' }}>
                      {orderId}
                    </IonBadge>
                    <IonLabel>ORDER ID:</IonLabel>
                  </IonItem>

                  <IonItem>
                    <IonBadge slot="end" style={{ fontSize: '15px' }}>
                      {tourGuideName}
                    </IonBadge>
                    <IonLabel className="style1">Tour Guide name:</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonBadge slot="end" style={{ fontSize: '15px' }}>
                      {tourLeaderName}
                    </IonBadge>
                    <IonLabel className="style1">Tour leader name:</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonBadge slot="end" style={{ fontSize: '15px' }}>
                      {tourLeaderC}%
                    </IonBadge>
                    <IonLabel className="style1">
                      Tour leader commision:
                    </IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonBadge
                      slot="end"
                      style={{ fontSize: '15px' }}
                      color="danger"
                    >
                      {total} $
                    </IonBadge>
                    <IonLabel className="style1">Total order :</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonBadge
                      slot="end"
                      style={{ fontSize: '15px' }}
                      color="danger"
                    >
                      <Moment format="YYYY/MM/DD">
                        {Array.isArray(deliveryDate) === true
                          ? deliveryDate[0]
                          : deliveryDate}
                      </Moment>
                    </IonBadge>
                    <IonLabel className="style1"> Delivery Date:</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonBadge
                      slot="end"
                      style={{ fontSize: '15px' }}
                      color="danger"
                    >
                      <Moment format="hh:mm:ss">
                        {Array.isArray(deliveryTime) === true
                          ? deliveryTime[0]
                          : deliveryTime}
                      </Moment>
                    </IonBadge>
                    <IonLabel className="style1"> Delivery time:</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonBadge
                      slot="end"
                      style={{ fontSize: '15px' }}
                      color="success"
                    >
                      {guidePhone}
                    </IonBadge>
                    <IonLabel className="style1">Phone :</IonLabel>
                  </IonItem>
                  <IonItem>
                    <IonBadge
                      slot="end"
                      style={{ fontSize: '15px' }}
                      color="secondary"
                    >
                      {country1}
                    </IonBadge>
                    <IonLabel className="style1">Group country :</IonLabel>
                  </IonItem>
                </IonList>

                {FinalTouristOrders.map((item, index) => {
                  return (
                    <>
                      <IonRow>
                        <IonCol size="12">
                          <IonList inset={true}>
                            <IonItem>
                              <IonLabel className="style1"></IonLabel>
                              <IonBadge
                                slot="start"
                                style={{ fontSize: '15px' }}
                                color="secondary"
                              >
                                {index + 1}
                              </IonBadge>
                            </IonItem>
                            <IonItem>
                              <IonBadge
                                slot="end"
                                style={{ fontSize: '15px' }}
                                color="secondary"
                              >
                                {item.name}
                              </IonBadge>
                              <IonLabel className="style1">
                                Tourist name:
                              </IonLabel>
                            </IonItem>

                            <IonItem>
                              <IonBadge
                                slot="end"
                                style={{ fontSize: '15px' }}
                                color="secondary"
                              >
                                {item.email}
                              </IonBadge>
                              <IonLabel className="style1">
                                Tourist email:
                              </IonLabel>
                            </IonItem>

                            <IonItem>
                              <IonBadge
                                slot="end"
                                style={{ fontSize: '15px' }}
                                color="secondary"
                              >
                                +{item.phoneNumber}
                              </IonBadge>
                              <IonLabel className="style1">
                                {' '}
                                Toursit Phone:
                              </IonLabel>
                            </IonItem>

                            <IonItem>
                              <IonBadge
                                slot="end"
                                style={{ fontSize: '15px' }}
                                color="secondary"
                              >
                                {item.total}$
                              </IonBadge>
                              <IonLabel className="style1">Total :</IonLabel>
                            </IonItem>

                            <IonAccordionGroup class="no-ion-padding">
                              <IonAccordion value="first">
                                <IonItem slot="header" color="light">
                                  <IonLabel>PRODUCTS DETAILS</IonLabel>
                                </IonItem>
                                <div slot="content">
                                  <NewCard
                                    customerOrders={item.customerOrders.filter(
                                      (v, i, a) => {
                                        // console.log("===========================")
                                        // console.log(i)
                                        // console.log(v)
                                        // console.log(a)
                                        // console.log("===========================")
                                        return (
                                          a.findIndex(
                                            (v2) => v2.id === v.id
                                          ) === i
                                        );
                                      }
                                    )}
                                    customerOrder1={item.customerOrders}
                                  />
                                </div>
                              </IonAccordion>
                            </IonAccordionGroup>
                          </IonList>
                        </IonCol>
                      </IonRow>

                      <hr />
                    </>
                  );
                })}
              </div>
            </IonContent>
          </IonContent>
          <IonFooter color="primary">
            <IonToolbar>
              <IonRow>
                <IonCol>
                  <PDFDownloadLink
                    document={
                      <Document>
                        <Page size="A4" style={styles.body}>
                          <View>
                            <Image style={styles.image} src={Logo} fixed />
                            <Text
                              style={{
                                fontSize: 12,
                                marginBottom: 20,
                                textAlign: 'center',
                                color: 'grey',
                              }}
                              fixed
                            >
                              - SHOP IN BUS -
                            </Text>
                          </View>

                          <hr style={styles.line} />
                          <Text style={styles.title}>ORDER DETAILS</Text>

                          <hr style={styles.line} />

                          <Text style={styles.text}>Order ID : {orderId}</Text>
                          <Text style={styles.text}>
                            Tour Guide name : {tourGuideName}
                          </Text>
                          <Text style={styles.text}>
                            Tour leader name : {tourLeaderName}
                          </Text>
                          <Text style={styles.text}>
                            Tour leader commision : {tourLeaderC}
                          </Text>
                          <Text style={styles.text}>
                            Country of the group: {country[0].label}
                          </Text>
                          <Text style={styles.text}>
                            Contact phone: +{guidePhone}
                          </Text>
                          <Text style={styles.text}>
                            Delivery date :{deliveryDate}
                          </Text>
                          <Text style={styles.text}>
                            Delivery time : {deliveryTime}
                          </Text>
                          <Text style={styles.text}>
                            Delivery location : {deliveryLocation}
                          </Text>
                          <Text style={styles.dollars}>
                            Total order of all products : {total} $
                          </Text>
                          <Text style={styles.title} break>
                            TOURIST ORDERS INFORATIONS
                          </Text>
                          {FinalTouristOrders.map((item, index) => {
                            return (
                              <>
                                <View style={styles.breakable} wrap={false}>
                                  <Text style={styles.text1}>
                                    {' '}
                                    - {index + 1} -
                                  </Text>

                                  <Text style={styles.text}>
                                    Order Id:{item.id}
                                  </Text>
                                  <Text style={styles.text}>
                                    Tourist name :{item.name}
                                  </Text>
                                  <Text style={styles.text}>
                                    Tourist email : {item.email}
                                  </Text>
                                  <Text style={styles.text}>
                                    Toursit Phone: +{item.phoneNumber}
                                  </Text>
                                  <Text style={styles.products} break>
                                    Products details
                                  </Text>
                                  <Text style={styles.text}>
                                    <PdfComponent
                                      customerOrders={item.customerOrders.filter(
                                        (v, i, a) =>
                                          a.findIndex(
                                            (v2) => v2.id === v.id
                                          ) === i
                                      )}
                                      customerOrder1={item.customerOrders}
                                    />
                                  </Text>

                                  <Text style={styles.dollars}>
                                    Total : {item.total}$
                                  </Text>
                                  <Text style={styles.dollars}>{'      '}</Text>
                                  <hr style={styles.line} />
                                </View>
                              </>
                            );
                          })}

                          <Text
                            style={styles.pageNumber}
                            render={({ pageNumber, totalPages }) =>
                              `${pageNumber} / ${totalPages}`
                            }
                            fixed
                          />
                          <Text style={styles.orderId1} fixed>
                            Order ID : {orderId}
                          </Text>
                          <Text style={styles.contact} fixed>
                            Contact us : Elias: 0528963616 , Shadi : 0546705689
                          </Text>
                        </Page>
                      </Document>
                    }
                    fileName={tourGuideName}
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? (
                        <>
                          <IonButton expand="block">LOADING PDF</IonButton>
                        </>
                      ) : (
                        <>
                          <IonButton expand="block">Download PDF NOW</IonButton>
                        </>
                      )
                    }
                  </PDFDownloadLink>
                </IonCol>
              </IonRow>
            </IonToolbar>
          </IonFooter>
        </IonPage>
      </Design>
    </>
  );
}

const Design = styled.div`
  .style1 {
    font-size: 17px;
    color: #8e8e8e;
  }
`;

export default Pdftest;
