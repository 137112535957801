import React, { useState } from 'react';
import axios from 'axios';
import { BASE_URL } from '../constants/AppConstants';
import { useHistory } from 'react-router-dom';
import {
  IonLabel,
  IonItem,
  IonThumbnail,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonAvatar,
  IonCol,
} from '@ionic/react';
import UserImage from '../image/user.png';

function UserComponent({ user }) {
  const [isLoading, setIsLoading] = useState(false);
  console.log('this is new user from hadana=>', user.id);
  let history = useHistory();
  const DeleteUser = (UserId) => {
    console.log(isLoading);

    axios
      .delete(`https://shop.holyland.app/api/users/remove/${user.id}`)
      .then((res) => {
        console.log(res.data);
        window.location.reload();
      })
      .catch((error) => console.log(error));
  };
  const AboutProduct = (id) => {
    setIsLoading(true);
    setTimeout(() => {
      history.push(`/user/${id}`, {
        product: {
          id,
        },
      });
    }, 200);
  };

  const EditProduct = () => {
    setIsLoading(true);
    setTimeout(() => {
      history.push(`/updateUser/`, {
        producto: {
          email: user.email,
          name: user.name,
          facebook: user.facebook,
          phone: user.phone,
          bio: user.bio,
          password: user.password,
          id: user.id,
          image: user.image,
          role: user.role,
        },
      });
    }, 200);
  };

  return (
    <IonItemSliding>
      {console.log('this is image', user.image)}
      <IonItemOptions side="start">
        <IonItemOption
          color="danger"
          expandable
          onClick={() => DeleteUser(user.id)}
        >
          Delete
        </IonItemOption>
      </IonItemOptions>

      <IonItem>
        <IonThumbnail slot="start">
          {user.image === undefined ? (
            <>
              <IonAvatar>
                <img alt="Silhouette of a person's head" src={UserImage} />
              </IonAvatar>
            </>
          ) : (
            <>
              {' '}
              <IonAvatar>
                <img
                  alt="Silhouette of a person's head"
                  src={JSON.parse(user.image).uploadInfo.url}
                />
              </IonAvatar>
            </>
          )}
        </IonThumbnail>

        <IonLabel>{user.name}</IonLabel>
        <IonLabel color="primary">
          {user.role === 'isRestaurant' && <h4>Restaurant Owner</h4>}
          {user.role === 'isProvider' && <h4>Provider</h4>}
          {user.role === 'isTourGuide' && <h4>Tour Guide</h4>}
          {user.role === 'isAdmin' && <h4>Adminstrator</h4>}
        </IonLabel>

        {/* <IonLabel>{user.role}</IonLabel> */}
      </IonItem>

      <IonItemOptions side="end">
        <IonItemOption
          color="primary"
          expandable
          onClick={() => {
            EditProduct();
          }}
        >
          Update
        </IonItemOption>
        <IonItemOption
          color="success"
          expandable
          onClick={() => {
            AboutProduct(user.id);
          }}
        >
          More
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
}

export default UserComponent;
