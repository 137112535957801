// user context
import axios from 'axios';
import React from 'react';
import { BASE_URL } from '../constants/AppConstants';
import Loader from '../components/Loading';
export const UserContext = React.createContext();

function getUsersFromLoacalStorage() {
  return localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : { user: null, token: null };
}

export default function UserProvider({ children }) {
  const [user, setUser] = React.useState(getUsersFromLoacalStorage);
  const [allUsers, setAllUsers] = React.useState();
  const [isLoading, setisLoading] = React.useState(false);

  const showAllUsers = () => {
    setisLoading(true);
    axios
      .get('https://shop.holyland.app/api/users')
      .then((res) => {
        console.log('those are the users from context=>', res.data);
        setAllUsers(res.data.data);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const userLogin = (user, token) => {
    setUser(user);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('token', token);
  };
  const userLogout = () => {
    setUser({ user: null, token: null });
    localStorage.removeItem('user');
  };

  const [alert, setAlert] = React.useState({
    show: false,
    msg: '',
    type: '',
  });
  const showAlert = ({ msg, type, show }) => {
    setAlert({ show, msg, type });
  };
  const hideAlert = () => {
    setAlert({ ...alert, show: false });
  };
  React.useEffect(() => {
    showAllUsers();
    console.log('those are the users from useeffect=>', user);
  }, []);

  return (
    <>
      {isLoading ? (
        <>{/* <Loader /> */}</>
      ) : (
        <>
          <UserContext.Provider
            value={{
              user,
              userLogin,
              userLogout,
              alert,
              showAlert,
              hideAlert,
              allUsers,
              isLoading,
            }}
          >
            {children}
          </UserContext.Provider>
        </>
      )}
    </>
  );
}
