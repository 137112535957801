import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ProductContext } from '../context/product';
import axios from 'axios';
import Moment from 'react-moment';
import styled from 'styled-components';
import { BASE_URL } from '../constants/AppConstants';
import { readerOutline, ellipsisVerticalOutline } from 'ionicons/icons';
import {
  IonContent,
  IonText,
  IonList,
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonNote,
  useIonActionSheet,
  IonModal,
} from '@ionic/react';
function ProviderList({
  setIsLoading,
  name,
  price,
  about,
  quantity,
  sid,
  image,
  userId,
  statusofOrder,
  note,
  id,
  order_id,
}) {
  const { touristOrders, finalOrders } = React.useContext(ProductContext);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();
  const [present1] = useIonActionSheet();
  const FinalorderDetails = finalOrders.filter((item) => item.sid === sid);
  const shopName = FinalorderDetails.map((it) => it.shopTitle);

  const timeOut = (sid) => {
    setIsLoading(true);
    console.log(result);
    setTimeout(() => {
      history.push(`/ProviderFinalOrderPage/${sid}`, {
        product1: {
          sid: sid,
          price: price,
          productName: name,
          quantity: quantity,
          ProductImage: image,
          statusofOrder,
          note,
          orderId: id,
        },
      });
    }, 500);
  };
  // const deleteOrder = (OrderId) => {
  //   setIsLoading(true);
  //   axios
  //     .delete(`https://shop.holyland.app/api/finalorder/${OrderId}`)
  //     .then((res) => {
  //       // console.log('deleted wl3atttttttttttttttttttt');
  //       setTimeout(() => {
  //         window.location.reload(true);
  //       }, 500);
  //     })
  //     .catch((error) => console.log(error));
  // };
  return (
    <>
      <div style={{ borderLeft: '5px solid #a2852e', marginBottom: '10px' }}>
        {console.log('touristOrders-.>', touristOrders)}
        {console.log('FinalorderDetails -.>', FinalorderDetails)}
        {console.log(' statusofOrder -.>', statusofOrder)}
        {console.log(' note-.>', note)}
        {console.log(' sid----------- -.>', sid)}
        <IonList class="ion-no-padding" lines="inset">
          <IonItem>
            <IonGrid>
              <IonRow class="ion-align-items-stretch">
                <IonCol size="10" onClick={() => timeOut(sid)}>
                  <IonLabel
                    class="ion-text-uppercase"
                    color="primary"
                    style={{ fontSize: '20px', fontWeight: 'bold' }}
                  >
                    {shopName}
                  </IonLabel>
                  <IonLabel class="ion-text-uppercase" color="primary">
                    {name}
                  </IonLabel>

                  <IonLabel
                    color="medium"
                    style={{ fontSize: '14px' }}
                  ></IonLabel>
                  <IonLabel color="medium" style={{ fontSize: '16px' }}>
                    {quantity} QUANTITY
                  </IonLabel>
                  <IonLabel color="medium" style={{ fontSize: '16px' }}>
                    {statusofOrder == null && (
                      <>
                        <IonNote
                          color="secondary"
                          style={{ fontSize: '20px', fontWeight: 'bold' }}
                        >
                          New order
                        </IonNote>
                      </>
                    )}

                    {statusofOrder === 'preparing' && (
                      <IonNote
                        color="secondary"
                        style={{ fontSize: '20px', fontWeight: 'bold' }}
                      >
                        Preparing order
                      </IonNote>
                    )}
                    {statusofOrder === 'onTheWay' && (
                      <IonNote
                        color="secondary"
                        style={{ fontSize: '20px', fontWeight: 'bold' }}
                      >
                        On the way
                      </IonNote>
                    )}
                    {statusofOrder === 'received' && (
                      <IonNote
                        color="success"
                        style={{ fontSize: '20px', fontWeight: 'bold' }}
                      >
                        Received
                      </IonNote>
                    )}
                    {statusofOrder === 'closed' && (
                      <IonNote
                        color="danger"
                        style={{ fontSize: '20px', fontWeight: 'bold' }}
                      >
                        Closed
                      </IonNote>
                    )}
                  </IonLabel>
                </IonCol>

                <IonCol size="2" style={{ paddingTop: '8px' }}>
                  <IonIcon
                    onClick={() => timeOut(sid)}
                    icon={readerOutline}
                    style={{ fontSize: '30px' }}
                    color="primary"
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonItem>
        </IonList>
      </div>
    </>
  );
}
const Design = styled.div`
  .my-custom-class {
    display: none !important;
  }
`;
export default ProviderList;
