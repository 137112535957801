import React, { useState, useEffect } from 'react';
import { ProductContext } from '../../context/product';
import { useParams, useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import Menu from '../../components/MenTourist';
import styled from 'styled-components';
import Steps1 from '../../image/STEPS.png';
import {
  IonContent,
  IonPage,
  IonText,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from '@ionic/react';

function MyShopFinalPage() {
  const [items, setItems] = useState([]);

  const { id } = useParams(); // this is shop id
  let history = useHistory();

  const { touristOrders, finalOrders } = React.useContext(ProductContext);
  // const product = orders.filter((item) => item.id === id);
  const [value, setValue] = useState('null');

  const myDetails = touristOrders.filter((item) => item.phoneNumber === value);
  const IdofShopWeNeed = finalOrders.filter((item) => item.id == id);
  const countryPhone = IdofShopWeNeed.map((item) => item.country)[0]?.value;
  const lastCoutryLabel = countryPhone?.toLowerCase();
  const [stauts1, setStauts1] = useState('NOT AVAILABLE DATA');
  const [ifMyDetailsAvailable, setifMyDetailsAvailable] = useState(null);

  console.log(IdofShopWeNeed);
  console.log('========================================');

  return (
    <div>
      <Design>
        <IonPage style={{ paddingTop: '70px' }}>
          <Menu what="Check" display={false} />
          {/* {console.log('localstorage==>', items)} */}
          {console.log('id==>', id)}
          {console.log('finalorders=>', finalOrders)}
          {console.log('IdofShopWeNeed=>', IdofShopWeNeed)}
          {console.log('countryPhone=>', countryPhone)}
          {console.log(
            'IdofShopWeNeed[0]?.statusofOrder=>',
            IdofShopWeNeed[0]?.statusofOrder
          )}
          {console.log('value=>', value)}
          <IonContent>
            {IdofShopWeNeed.length !== 0 ? (
              <IonGrid class="ion-padding">
                {IdofShopWeNeed[0]?.statusofOrder !== 'onTheWay' &&
                IdofShopWeNeed[0]?.statusofOrder !== 'received' &&
                IdofShopWeNeed[0]?.statusofOrder !== 'closed' ? (
                  <>
                    <IonRow>
                      <IonCol size="12">
                        <IonImg
                          src={Steps1}
                          style={{
                            paddingTop: '0px',
                            paddingLeft: '60px',
                            paddingRight: '60px',
                          }}
                        ></IonImg>
                      </IonCol>
                    </IonRow>
                    <IonRow className="r1">
                      <IonCol
                        class="ion-align-self-center"
                        className="col"
                        size-md="6"
                        size-lg="5"
                        size-xs="12"
                      >
                        <IonRow class="ion-padding">
                          <IonCol>
                            <IonText color="dark" class="ion-text-center">
                              <h3 class="ion-text-uppercase">
                                PLEASE INSERT YOUR PHONE NUMBER
                              </h3>
                            </IonText>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol size="12">
                            <PhoneInput
                              placeholder={'Insert your phone number'}
                              country={lastCoutryLabel}
                              value={value}
                              onChange={setValue}
                              alwaysDefaultMask={true}
                              enableAreaCodes={true}
                              enableSearch={true}
                              disableDropdown
                              autofocus={true}
                            />
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol class="ion-text-center">
                            <IonButton
                              mode="md"
                              size="default"
                              expand="block"
                              fill="solid"
                              color="primary"
                              disabled={value.length > 11 ? false : true}
                              onClick={() => {
                                // handleSubmit()
                                history.push(`/touristForm/${value}`, {
                                  product1: {
                                    value,
                                    IdofShopWeNeed,
                                    id, // shop id. it is unique number for each shop
                                  },
                                });
                              }}
                            >
                              CHECK
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </>
                ) : (
                  <IonRow className="r1">
                    <IonCol
                      class="ion-align-self-center"
                      className="col"
                      size-md="6"
                      size-lg="5"
                      size-xs="12"
                    >
                      <IonRow class="ion-padding">
                        <IonCol>
                          <IonText color="dark" class="ion-text-center">
                            <h1 class="ion-text-uppercase">
                              Order is {IdofShopWeNeed[0]?.statusofOrder}
                            </h1>
                            <n />
                            <h1>CAN NOT ORDER SORRY </h1>
                          </IonText>
                        </IonCol>
                      </IonRow>
                    </IonCol>
                  </IonRow>
                )}
              </IonGrid>
            ) : (
              <IonRow className="r1">
                <IonCol
                  class="ion-align-self-center"
                  className="col"
                  size-md="6"
                  size-lg="5"
                  size-xs="12"
                >
                  <IonRow class="ion-padding">
                    <IonCol>
                      <IonText color="dark" class="ion-text-center">
                        <h3 class="ion-text-uppercase">Shop Doesn't Exist</h3>
                      </IonText>
                    </IonCol>
                  </IonRow>
                </IonCol>
              </IonRow>
            )}
          </IonContent>
        </IonPage>
      </Design>
    </div>
  );
}
const Design = styled.div`
  .react-tel-input .form-control {
    height: 40px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-self: center;
    justify-content: center;
  }
  .r1 {
    display: flex;
    height: 80vh;
    align-items: center;
  }
  .col {
  }
  .react-tel-input {
    color: black;
  }
`;
export default MyShopFinalPage;
