import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import Moment from 'react-moment';
import { BASE_URL } from '../constants/AppConstants';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectCoverflow, Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-coverflow';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonItemOptions,
  IonItemOption,
  IonItemSliding,
  IonModal,
  IonContent,
  IonNote,
  IonBadge,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonText,
  IonActionSheet,
} from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
function TouristPageItemSlide1({
  isLoading,
  setIsLoading,
  id,
  name,
  date,
  total,
  sid,
  phoneNumber,
  email,
  created_at,
  customerOrders,
  statusofOrder,
}) {
  // const rep = replicateOrders(customerOrders);

  // function replicateOrders(orders) {
  //   const replicatedArray = [];

  //   orders.forEach((order) => {
  //     const { quantity } = order;

  //     for (let i = 0; i < quantity; i++) {
  //       replicatedArray.push({ ...order });
  //     }
  //   });

  //   return replicatedArray;
  // }
  // const uniqueItems = rep.filter(
  //   (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
  // );

  const uniqueItems = customerOrders.filter(
    (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
  );

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const deleteOrder = (OrderId) => {
    setIsLoading(true);
    axios

      .delete(`https://shop.holyland.app/api/useroders/${OrderId}`)
      .then((res) => {
        setTimeout(() => {
          window.location.reload(true);
        }, 500);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <Design>
        {console.log('customerOrders==>', customerOrders)}
        {console.log('statoforder==>', statusofOrder)}

        <IonList key={id}>
          <IonItem onClick={() => setIsOpen1(true)}>
            <IonBadge slot="end" color="success" style={{ fontSize: '16px' }}>
              {total} $
            </IonBadge>
            <IonLabel>
              {' '}
              <IonLabel class="ion-text-capitalize">{name}</IonLabel>
              <IonLabel color="medium">
                {console.log('created at=>', created_at)}
                <IonLabel color="medium" style={{ fontSize: '15px' }}>
                  <Moment format="YYYY/MM/DD , h:mm:ss a">{created_at}</Moment>
                </IonLabel>
              </IonLabel>
            </IonLabel>
          </IonItem>
          <>
            <IonActionSheet
              isOpen={isOpen1}
              //   header="Actions"
              buttons={[
                {
                  text: 'More details',
                  data: {
                    action: 'share',
                  },
                  handler: () => {
                    setIsOpen(true);
                  },
                },
                {
                  text: 'Delete',
                  role: 'destructive',
                  data: {
                    action: 'delete',
                  },
                  handler: () => {
                    deleteOrder(id);
                  },
                },

                {
                  text: 'Cancel',
                  role: 'cancel',
                  data: {
                    action: 'cancel',
                  },
                },
              ]}
              onDidDismiss={() => setIsOpen1(false)}
            ></IonActionSheet>
          </>
          {/* modal client details */}
          <IonModal isOpen={isOpen}>
            <IonHeader>
              <IonToolbar color="primary">
                <IonTitle>{name.toUpperCase()} ORDER</IonTitle>
                <IonIcon
                  onClick={() => setIsOpen(false)}
                  slot="start"
                  icon={chevronBackOutline}
                  size="large"
                ></IonIcon>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonList inset={true}>
                <IonItem class="ion-padding">
                  <h2>{name.toUpperCase()} ORDER DETAILS</h2>
                </IonItem>
                <IonItem>
                  <IonBadge slot="end" style={{ fontSize: '15px' }}>
                    {total} $
                  </IonBadge>
                  <IonText>Total</IonText>
                </IonItem>
                <IonItem>
                  <IonLabel>Phone number</IonLabel>
                  <IonNote
                    slot="end"
                    color="primary"
                    style={{ fontSize: '14px' }}
                  >
                    +{phoneNumber}
                  </IonNote>
                </IonItem>
                <IonItem>
                  <IonLabel>Email</IonLabel>
                  <IonNote
                    slot="end"
                    color="primary"
                    style={{ fontSize: '14px' }}
                  >
                    {email}
                  </IonNote>
                </IonItem>
                <IonItem>
                  <IonLabel>Date</IonLabel>
                  <IonNote slot="end" color="primary">
                    <Moment format="YYYY/MM/DD" style={{ fontSize: '14px' }}>
                      {created_at}
                    </Moment>
                  </IonNote>
                </IonItem>
              </IonList>
              <IonRow style={{ paddingBottom: '20px' }}>
                <IonCol>
                  <Swiper
                    navigation={true}
                    pagination={{
                      el: '.swiper-custom-pagination',
                      type: 'fraction',
                      clickable: true,
                    }}
                    slidesPerView={1.2}
                    spaceBetween={-5}
                    modules={[Pagination, Navigation]}
                    // className="mySwiper"
                  >
                    {customerOrders.map((item, i) => {
                      return (
                        <>
                          <SwiperSlide>
                            <IonCard>
                              <img
                                alt="Silhouette of mountains"
                                src={JSON.parse(item.image)}
                              />
                              <IonCardHeader>
                                <IonCardTitle>
                                  {' '}
                                  <h5> {item.title}</h5>
                                </IonCardTitle>
                                <IonCardSubtitle>
                                  <h3> {item.price} usd </h3>
                                </IonCardSubtitle>
                              </IonCardHeader>

                              {/* <IonCardContent>{item.about}</IonCardContent> */}
                              <IonCardContent>
                                <IonList lines="none">
                                  <IonItem class="ion-no-padding">
                                    {item.statusofOrder === 'senToShop' && (
                                      <IonBadge
                                        slot="end"
                                        color="secondary"
                                        style={{ padding: '12px' }}
                                      >
                                        {item.statusofOrder}
                                      </IonBadge>
                                    )}

                                    {item.statusofOrder === 'open' && (
                                      <IonBadge
                                        slot="end"
                                        color="secondary"
                                        style={{ padding: '12px' }}
                                      >
                                        {item.statusofOrder}
                                      </IonBadge>
                                    )}

                                    {item.statusofOrder === 'preparing' && (
                                      <IonBadge
                                        slot="end"
                                        color="secondary"
                                        style={{ padding: '12px' }}
                                      >
                                        {item.statusofOrder}
                                      </IonBadge>
                                    )}

                                    {item.statusofOrder === 'onTheWay' && (
                                      <IonBadge
                                        slot="end"
                                        color="secondary"
                                        style={{ padding: '12px' }}
                                      >
                                        {item.statusofOrder}
                                      </IonBadge>
                                    )}

                                    {item.statusofOrder === 'received' && (
                                      <IonBadge
                                        slot="end"
                                        color="success"
                                        style={{ padding: '12px' }}
                                      >
                                        {item.statusofOrder}
                                      </IonBadge>
                                    )}
                                    {item.statusofOrder === 'closed' && (
                                      <IonBadge
                                        slot="end"
                                        color="danger"
                                        style={{ padding: '12px' }}
                                      >
                                        {item.statusofOrder}
                                      </IonBadge>
                                    )}
                                    <IonLabel>STATUS</IonLabel>
                                  </IonItem>
                                  <IonItem class="ion-no-padding">
                                    <IonBadge
                                      slot="end"
                                      style={{ padding: '12px' }}
                                    >
                                      {item.quantity}
                                    </IonBadge>
                                    <IonLabel>QUANTITY </IonLabel>
                                  </IonItem>
                                </IonList>
                              </IonCardContent>
                            </IonCard>
                          </SwiperSlide>
                        </>
                      );
                    })}
                  </Swiper>
                  <div
                    class="swiper-custom-pagination"
                    style={{
                      display: 'flex',
                      margin: '0',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  ></div>
                </IonCol>
              </IonRow>
            </IonContent>
          </IonModal>

          {/* end modal client details */}
        </IonList>
      </Design>
    </div>
  );
}
const Design = styled.div`
  /* .swiper-button-next {
    color: #a2852e;
    scale: 0.5;
  }
  .swiper-3d .swiper-slide-shadow {
    background: rgb(0 0 0 / 0%);
  }
  .swiper-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
  }
  .swiper-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
  }

  .swiper-custom-pagination {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
  }
  .swiper-button-next {
    color: #a2852e;
    scale: 0.5;
  } */
  .swiper-button-prev {
    color: red;
  }

  .swiper-button-next {
    color: #000;
  }
`;
export default TouristPageItemSlide1;
