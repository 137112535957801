// export const BASE_URL = 'https://backend.shopinbus.com'; // local url
// export const BASE_URL_SHARE = 'https://shopinbus.com'; // don not delete ever
export const BASE_URL_SHARE = 'http://localhost:3000'; // don not delete ever

export const BASE_URL = 'http://localhost:5000';
export const BACKEND = 'https://shop.holyland.app';

// export const BASE_URL_SHARE = 'http://shopinbus.com'; // don not delete ever

// HOME PAGE
export const GuideHomePage = 'Weclome To Shop in Bus';
export const AdminHomePage = 'Welcome Dear admin';
export const RegularHomePage = 'Weclome to Shop in Bus';
