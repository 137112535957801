import React, { useState, useEffect } from 'react';
import { ProductContext } from '../../context/product';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import 'react-phone-input-2/lib/style.css';
import Steps1 from '../../image/STEPS2.png';
import 'react-phone-input-2/lib/material.css';
import Menu from '../../components/MenTourist';
import styled from 'styled-components';
import {
  IonContent,
  IonPage,
  IonText,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonImg,
} from '@ionic/react';

function ToristForm() {
  const { value } = useParams();
  const location = useLocation();
  const { product1 } = location.state;
  const id1 = product1.id;
  let history = useHistory();
  const { touristOrders } = React.useContext(ProductContext);
  const myDetails = touristOrders.filter((item) => item.shopId == product1.id); // here we will filter all tourist orders, with the shop id we got from the previous page
  const myDetails1 = myDetails.filter((item) => item.phoneNumber == value); // then we will filter the result of the previous with the phone number, that we got from the previous
  const stauts1 = 'NOT AVAILABLE DATA';
  const [ifMyDetailsAvailable, setifMyDetailsAvailable] = useState(null);
  const customerOrders = myDetails1.map((item) => item.customerOrders);
  const name = myDetails1.map((item) => item.name);
  const email = myDetails1.map((item) => item.email);
  const considerTourLeader = myDetails1.map((item) => item.considerTourLeader);
  const date = myDetails1.map((item) => item.date);
  const phoneNumber = myDetails1.map((item) => item.phoneNumber);
  const productsList = myDetails1.map((item) => item.productsList);
  const shopId = myDetails1.map((item) => item.id);
  const sid = myDetails1.map((item) => item.sid);
  const total = myDetails1.map((item) => item.total);
  const tourGuideName = myDetails1.map((item) => item.tourGuideName);
  const tourLeaderC = myDetails1.map((item) => item.tourLeaderC);
  const tourLeaderName = myDetails1.map((item) => item.tourGuideName);
  const userId = myDetails1.map((item) => item.userId);

  useEffect(() => {
    setifMyDetailsAvailable(
      myDetails1.find((item) => item.phoneNumber === value)
    );
    console.log(myDetails);
    console.log('===================================');
  }, [value, myDetails1]);
  console.log('this is the vlaue=>', value);
  console.log('this is phone number=>', phoneNumber);
  console.log('this is shop id=>', shopId);
  console.log('this is userId>', userId);
  console.log('myDetails==>', myDetails);
  console.log('this is myDetails1>', myDetails1);
  console.log('touristOrders', touristOrders);
  return (
    <div>
      <Design>
        <IonPage style={{ paddingTop: '70px' }}>
          <Menu
            what="Check"
            display={true}
            // backBtnLink="/shop/641cb3de1abe191721ed7bf5"
            backBtnLink={`/shop/${id1}`}
          />
          <IonContent>
            <IonGrid>
              {console.log('mydetails1', myDetails1)}

              {ifMyDetailsAvailable ? (
                <>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12">
                        <IonImg
                          src={Steps1}
                          style={{
                            paddingTop: '0px',
                            paddingLeft: '60px',
                            paddingRight: '60px',
                          }}
                        ></IonImg>
                      </IonCol>
                    </IonRow>
                    <IonRow className="r1">
                      <IonCol
                        class="ion-align-self-center"
                        className="col"
                        size-md="6"
                        size-lg="5"
                        size-xs="12"
                      >
                        <IonText color="dark" class="ion-text-center">
                          <h3>I HAVE ORDER, UPDATE IT</h3>
                        </IonText>
                        <IonRow>
                          <IonCol class="ion-text-center">
                            <IonButton
                              mode="md"
                              size="default"
                              expand="block"
                              fill="solid"
                              color="primary"
                              onClick={() =>
                                history.push(`/updateShop/${product1.id}`, {
                                  product1: {
                                    value,
                                    customerOrders: customerOrders, //done
                                    name, // done
                                    email, // done
                                    considerTourLeader, // done
                                    date, // done
                                    phoneNumber, // done
                                    productsList, // done
                                    shopId,
                                    sid, // done
                                    total, // done
                                    tourGuideName, // done
                                    tourLeaderC, // done
                                    tourLeaderName, // done
                                    userId, // done
                                  },
                                })
                              }
                            >
                              UPDATE IT OR DELETE IT
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </>
              ) : (
                <>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12">
                        <IonImg
                          src={Steps1}
                          style={{
                            paddingTop: '0px',
                            paddingLeft: '60px',
                            paddingRight: '60px',
                          }}
                        ></IonImg>
                      </IonCol>
                    </IonRow>
                    <IonRow className="r1">
                      <IonCol
                        class="ion-align-self-center"
                        className="col"
                        size-md="6"
                        size-lg="5"
                        size-xs="12"
                      >
                        <IonText color="dark" class="ion-text-center">
                          <h3>I HAVE NO ORDER</h3>
                        </IonText>
                        <IonRow>
                          <IonCol class="ion-text-center">
                            <IonButton
                              mode="md"
                              size="default"
                              expand="block"
                              fill="solid"
                              color="primary"
                              onClick={() =>
                                history.push(
                                  `/myFinalShopPage1/${product1.id}`,
                                  {
                                    product1: {
                                      value,
                                    },
                                  }
                                )
                              }
                            >
                              WANT TO ORDER
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </>
              )}
            </IonGrid>
          </IonContent>
        </IonPage>
      </Design>
    </div>
  );
}
const Design = styled.div`
  .react-tel-input .form-control {
    height: 50px;
    width: 90vw !important;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-self: center;
    justify-content: center;
  }
  .r1 {
    display: flex;
    height: 80vh;
    align-items: center;
  }
  .col {
  }
  .react-tel-input {
    color: black;
  }
`;
export default ToristForm;
