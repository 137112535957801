import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ProductContext } from '../../context/productold';

import Moment from 'react-moment';
import axios from 'axios';
import {
  IonContent,
  IonPage,
  IonText,
  IonList,
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  useIonAlert,
  IonItemOptions,
  IonItemOption,
  IonItemSliding,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import Menu from '../../components/Menu';
import { BASE_URL } from '../../constants/AppConstants';

function AllTouristsOrders() {
  const { ids } = useParams();
  let history = useHistory();
  function handleRefresh(event) {
    setTimeout(() => {
      ShowTouristOrders();
      event.detail.complete();
    }, 2000);
  }

  const { touristOrders, ShowTouristOrders } = React.useContext(ProductContext);
  const FinalTouristOrders = touristOrders.filter(
    (item) => item.shopId === ids
  );
  const [presentAlert] = useIonAlert();
  const [handlerMessage, setHandlerMessage] = useState('');
  const [roleMessage, setRoleMessage] = useState('');
  // const [id, setId] = useState('');

  const deleteOrder = (OrderId) => {
    console.log(handlerMessage, roleMessage);
    axios
      .delete(`${BASE_URL}/api/useroders/${OrderId}`)
      .then((res) => {
        console.log('deleted');

        // window.location.reload(true);
      })
      .catch((error) => console.log(error));
    // swal('Poof! Your imaginary file has been deleted!', {
    //   icon: 'success',
    // });
  };

  return (
    <div>
      <IonPage>
        <Menu what="List of orders" />
        <IonContent fullscreen className="all-page">
          <IonRefresher slot="fixed" onIonRefresh={handleRefresh}>
            <IonRefresherContent></IonRefresherContent>
          </IonRefresher>
          <IonRow>
            <IonCol class="ion-padding">
              <IonText color="dark" class="ion-text-center">
                <h3>List of orders created by tour Your tourists</h3>
                {/* <h1>{ids}</h1> */}
                {console.log(`this is all tourost order`, touristOrders)}
                {console.log(`this is final tourost order`, FinalTouristOrders)}
              </IonText>
            </IonCol>
          </IonRow>

          {FinalTouristOrders.map((order) => {
            return (
              <IonList key={order.id}>
                <IonItemSliding>
                  {console.log(ids)}
                  <IonItemOptions side="start">
                    <IonItemOption
                      color="danger"
                      expandable
                      // onClick={() => deleteOrder(order.id)}
                      onClick={() =>
                        presentAlert({
                          header:
                            'The shop will be deleted, and this can not be restored',
                          buttons: [
                            {
                              text: 'Cancel, i need the shop',
                              role: 'cancel',
                              handler: () => {
                                setHandlerMessage('Alert canceled');
                              },
                            },
                            {
                              text: 'OK, No issue',
                              role: 'confirm',
                              handler: () => {
                                setHandlerMessage('Alert confirmed');
                                deleteOrder(order.id);
                                window.location.reload(true);
                              },
                            },
                          ],
                          onDidDismiss: (e) =>
                            setRoleMessage(
                              `Dismissed with role: ${e.detail.role}`
                            ),
                        })
                      }
                    >
                      Delete
                    </IonItemOption>
                  </IonItemOptions>

                  <IonItem>
                    <IonGrid>
                      <IonRow>
                        <IonCol size="9">
                          <IonLabel class="ion-text-capitalize">
                            {order.name} Order
                          </IonLabel>
                          <IonLabel color="medium">
                            <Moment format="YYYY/MM/DD">{order.date}</Moment>
                            {/* <br /> */}
                            <Moment format="HH:MM" class="ion-padding-start">
                              {order.date}
                            </Moment>
                            {/* <Moment toNow class="ion-padding-start">
                              {order.date}
                            </Moment> */}
                          </IonLabel>
                        </IonCol>

                        <IonCol size="3">
                          <IonLabel>{order.total} $</IonLabel>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </IonItem>

                  <IonItemOptions side="end">
                    <IonItemOption
                      onClick={() => {
                        history.push(`/TouristOrderPage/${order.id}`, {
                          product1: {
                            id: order.id,
                          },
                        });
                        // window.location.reload(true);
                      }}
                    >
                      More details
                    </IonItemOption>
                  </IonItemOptions>
                </IonItemSliding>
              </IonList>
            );
          })}
        </IonContent>
      </IonPage>
    </div>
  );
}

export default AllTouristsOrders;
