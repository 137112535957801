import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ProductContext } from '../../context/product';
import { UserContext } from '../../context/user';
import Item from '../../components/Item';
import Progress from '../../components/Porgress';

import {
  IonContent,
  IonPage,
  IonList,
  IonLabel,
  IonItem,
  IonApp,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import Menu from '../../components/Menu';

function AllOrders() {
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { sorted } = React.useContext(ProductContext);
  const { user } = React.useContext(UserContext);
  const [all, setAll] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  React.useEffect(() => {
    user.user && !user.user.role === 'isAdmin' && <>{history.push('/Wrong')}</>;
  }, [user, history]);
  React.useEffect(() => {
    setAll(sorted);
  }, [sorted]);

  function handleCategoryChange(event) {
    setSelectedCategory(event.target.value);
  }
  function getFilteredList() {
    if (!selectedCategory) {
      return all;
    }
    return all.filter((item) => item.statusofOrder === selectedCategory);
  }
  var filteredList = useMemo(getFilteredList, [selectedCategory, all]);
  console.log(filteredList);

  const customActionSheetOptions = {
    header: 'CHOOSE ORDER STATUS',
  };

  return (
    <div>
      <IonApp>
        <IonPage style={{ paddingTop: '65px' }}>
          <Menu
            what={
              (selectedCategory === 'open' && 'NEW SHOPS') ||
              (selectedCategory === 'senToShop' && 'START TO PREPAIR') ||
              (selectedCategory === 'preparing' && 'PREPAIRING ORDER') ||
              (selectedCategory === 'onTheWay' && 'ON THE WAY') ||
              (selectedCategory === 'received' && 'RECEIVED') ||
              (selectedCategory === 'closed' && 'CLOSED') ||
              (!selectedCategory && 'ALL ORDERS')
            }
            backBtnLink="/home"
            display={true}
          />

          <IonContent fullscreen className="all-page">
            {isLoading && <Progress />}

            <IonList
              style={{ backgroundColor: 'rgb(139 139 139 / 15%)' }}
              class="ion-padding"
            >
              <IonItem>
                <IonLabel color="primary">CHOOSE STATUS</IonLabel>
                <IonSelect
                  interfaceOptions={customActionSheetOptions}
                  interface="action-sheet"
                  placeholder="Select status"
                  onIonChange={handleCategoryChange}
                >
                  <IonSelectOption value="">ALL</IonSelectOption>
                  <IonSelectOption value="open">Open</IonSelectOption>
                  <IonSelectOption value="senToShop">
                    Sent to shop
                  </IonSelectOption>
                  <IonSelectOption value="preparing">
                    Prepairing
                  </IonSelectOption>
                  <IonSelectOption value="onTheWay">On The Way</IonSelectOption>
                  <IonSelectOption value="received">Received</IonSelectOption>
                  <IonSelectOption value="closed">closed</IonSelectOption>
                </IonSelect>
              </IonItem>
            </IonList>

            {/* {console.log('sent to shop', sentToShop)} */}
            <div style={{ paddingTop: '50px' }}>
              {filteredList &&
                filteredList?.map((element, index) => (
                  <>
                    {' '}
                    <Item
                      {...element}
                      key={index}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                    />
                  </>
                ))}
            </div>
          </IonContent>
        </IonPage>
      </IonApp>
    </div>
  );
}

export default AllOrders;
