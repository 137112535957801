import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ProductContext } from '../context/product';
import axios from 'axios';
import Moment from 'react-moment';
import styled from 'styled-components';
import { BASE_URL } from '../constants/AppConstants';
import { readerOutline, ellipsisVerticalOutline } from 'ionicons/icons';
import {
  IonContent,
  IonText,
  IonList,
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
  IonNote,
  useIonActionSheet,
  IonModal,
} from '@ionic/react';
function MyShopsList({
  considerTourLeader,
  tourGuideName,
  tourLeaderC,
  tourLeaderName,
  total,
  date,
  created_at,
  productsList,
  customerOrders,
  userId,
  statusofOrder, //ok
  sid,
  deliveryTime,
  deliveryDate,
  deliveryLocation,
  country,
  guidePhone,
  shopTitle,
  note,
  _id,
  id,
  setIsLoading,
}) {
  const { touristOrders } = React.useContext(ProductContext);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState();
  const [present1] = useIonActionSheet();
  const FinalTouristOrders = touristOrders.filter((item) => item.sid === sid);

  const [dataObject, setdataObject] = useState(FinalTouristOrders);
  const [statuses, setStatuses] = useState(
    dataObject.map((it) =>
      it.customerOrders.map((item) => {
        return item.statusofOrder;
      })
    )
  );
  const allStatuses = statuses.flat(1);
  const timeOut = (id) => {
    setIsLoading(true);
    console.log(result);
    setTimeout(() => {
      history.push(`/FinaShopPage/${id}`, {
        product1: {
          sid: id,
        },
      });
    }, 500);
  };
  const deleteOrder = (OrderId) => {
    setIsLoading(true);
    axios
      .delete(`https://shop.holyland.app/api/finalorder/${OrderId}`)
      .then((res) => {
        // console.log('deleted wl3atttttttttttttttttttt');
        setTimeout(() => {
          window.location.reload(true);
        }, 500);
      })
      .catch((error) => console.log(error));
  };
  return (
    <>
      <div style={{ borderLeft: '5px solid #a2852e', marginBottom: '10px' }}>
        {/* <div className={statusofOrder? "":""}> */}
        {console.log('touristOrders==>', touristOrders)}
        {console.log('all statuses', allStatuses)}
        <IonList class="ion-no-padding" lines="inset">
          <IonItem>
            <IonGrid>
              <IonRow class="ion-align-items-stretch">
                <IonCol size="8" onClick={() => timeOut(sid)}>
                  <IonLabel
                    class="ion-text-uppercase"
                    color="primary"
                    style={{ fontSize: '18px', fontWeight: 'bold' }}
                  >
                    {shopTitle}
                  </IonLabel>
                  <IonLabel color="medium" style={{ fontSize: '15px' }}>
                    <Moment format="YYYY/MM/DD , h:mm:ss a">
                      {created_at}
                    </Moment>
                  </IonLabel>

                  <IonLabel
                    color="medium"
                    style={{ fontSize: '14px' }}
                  ></IonLabel>
                  <IonLabel color="medium" style={{ fontSize: '16px' }}>
                    {touristOrders.filter((item) => item.shopId === id).length}{' '}
                    order
                  </IonLabel>
                </IonCol>

                <IonCol size="2" style={{ paddingTop: '8px' }}>
                  <IonIcon
                    onClick={() => timeOut(sid)}
                    icon={readerOutline}
                    style={{ fontSize: '30px' }}
                    color="primary"
                  />
                </IonCol>

                {(() => {
                  if (allStatuses.includes('preparing')) {
                    return (
                      <IonCol size="2">
                        <>
                          <IonButton
                            onClick={() =>
                              present1({
                                header: 'Edit shop',
                                animated: true,

                                buttons: [
                                  {
                                    text: 'Delete shop, and Related orders',
                                    role: 'destructive',
                                    translucent: true,
                                    handler: () => {
                                      deleteOrder(id);
                                    },
                                  },

                                  {
                                    text: 'View shop',
                                    handler: () => {
                                      setIsLoading(true);
                                      setTimeout(() => {
                                        history.push(`/Shopview/${id}`, {
                                          product1: {
                                            id: id,
                                          },
                                        });
                                      }, 600);
                                    },
                                  },
                                  // {
                                  //   text: 'Update shop',

                                  //   handler: () => {
                                  //     setIsLoading(true);
                                  //     setTimeout(() => {
                                  //       history.push('/UpdateOrderP1', {
                                  //         producto: {
                                  //           considerTourLeader,
                                  //           tourGuideName,
                                  //           tourLeaderC,
                                  //           tourLeaderName,
                                  //           total,
                                  //           date,
                                  //           created_at,
                                  //           productsList,
                                  //           customerOrders,
                                  //           userId,
                                  //           statusofOrder,
                                  //           sid,
                                  //           deliveryTime,
                                  //           deliveryDate,
                                  //           deliveryLocation,
                                  //           country,
                                  //           guidePhone,
                                  //           shopTitle,
                                  //           id,
                                  //           note,
                                  //         },
                                  //       });
                                  //     }, 600);
                                  //   },
                                  // },

                                  {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    data: {
                                      action: 'cancel',
                                    },
                                  },
                                ],
                                onDidDismiss: ({ detail }) => setResult(detail),
                              })
                            }
                          >
                            <IonIcon
                              icon={ellipsisVerticalOutline}
                              color="light"
                            />
                          </IonButton>
                        </>
                      </IonCol>
                    );
                  }
                  if (allStatuses.includes('onTheWay')) {
                    return (
                      <IonCol size="2">
                        <>
                          <IonButton
                            onClick={() =>
                              present1({
                                header: 'Edit shop',
                                animated: true,

                                buttons: [
                                  {
                                    text: 'View shop',

                                    handler: () => {
                                      setIsLoading(true);
                                      setTimeout(() => {
                                        history.push(`/Shopview/${id}`, {
                                          product1: {
                                            id: id,
                                          },
                                        });
                                      }, 600);
                                    },
                                  },

                                  {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    data: {
                                      action: 'cancel',
                                    },
                                  },
                                ],
                                onDidDismiss: ({ detail }) => setResult(detail),
                              })
                            }
                          >
                            <IonIcon
                              icon={ellipsisVerticalOutline}
                              color="light"
                            />
                          </IonButton>
                        </>
                      </IonCol>
                    );
                  }
                  if (allStatuses.includes('received')) {
                    return (
                      <IonCol size="2">
                        <>
                          <IonButton
                            onClick={() =>
                              present1({
                                header: 'Edit shop',
                                animated: true,

                                buttons: [
                                  {
                                    text: 'View shop',

                                    handler: () => {
                                      setIsLoading(true);
                                      setTimeout(() => {
                                        history.push(`/Shopview/${id}`, {
                                          product1: {
                                            id: id,
                                          },
                                        });
                                      }, 600);
                                    },
                                  },

                                  {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    data: {
                                      action: 'cancel',
                                    },
                                  },
                                ],
                                onDidDismiss: ({ detail }) => setResult(detail),
                              })
                            }
                          >
                            <IonIcon
                              icon={ellipsisVerticalOutline}
                              color="light"
                            />
                          </IonButton>
                        </>
                      </IonCol>
                    );
                  }
                  if (allStatuses.includes('closed')) {
                    return (
                      <IonCol size="2">
                        <>
                          <IonButton
                            onClick={() =>
                              present1({
                                header: 'Edit shop',
                                animated: true,

                                buttons: [
                                  {
                                    text: 'View shop',

                                    handler: () => {
                                      setIsLoading(true);
                                      setTimeout(() => {
                                        history.push(`/Shopview/${id}`, {
                                          product1: {
                                            id: id,
                                          },
                                        });
                                      }, 600);
                                    },
                                  },

                                  {
                                    text: 'Cancel',
                                    role: 'cancel',
                                    data: {
                                      action: 'cancel',
                                    },
                                  },
                                ],
                                onDidDismiss: ({ detail }) => setResult(detail),
                              })
                            }
                          >
                            <IonIcon
                              icon={ellipsisVerticalOutline}
                              color="light"
                            />
                          </IonButton>
                        </>
                      </IonCol>
                    );
                  }
                  // } else {
                  //   return (
                  //     <IonBadge
                  //       slot="end"
                  //       style={{ fontSize: '15px' }}
                  //       color="secondary"
                  //     >
                  //       prepairing
                  //     </IonBadge>
                  //   );
                  // }
                })()}
              </IonRow>
              <IonModal
                isOpen={isOpen}
                onDidDismiss={() => setIsOpen(false)}
                trigger="open-modal"
                initialBreakpoint={0.25}
                breakpoints={[0, 0.25, 0.5, 0.75]}
              >
                <IonContent className="ion-padding">
                  <IonList inset={true}>
                    <IonItem class="ion-padding">
                      <h2>Notes for your order</h2>
                    </IonItem>
                    <IonItem class="ion-padding">
                      <IonText>{note}</IonText>
                    </IonItem>
                  </IonList>
                </IonContent>
              </IonModal>
            </IonGrid>
          </IonItem>
        </IonList>
      </div>
    </>
  );
}
const Design = styled.div`
  .my-custom-class {
    display: none !important;
  }
`;
export default MyShopsList;
