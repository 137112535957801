import React, { useState, useEffect } from 'react';
import { ProductContext } from '../../context/product';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import Producto from '../../components/TouristCard';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import Menu from '../../components/MenTourist';
import styled from 'styled-components';
import { Formik } from 'formik';
import useLocalStorage from '../../hooks/useLocalStorage';
import Steps1 from '../../image/STEPS3.png';
import * as yup from 'yup';
import {
  IonContent,
  IonPage,
  IonToolbar,
  IonText,
  IonLabel,
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonFooter,
  IonBadge,
  useIonToast,
  IonList,
  IonImg,
} from '@ionic/react';
import { thumbsUpOutline, thumbsDownOutline } from 'ionicons/icons';
import { BASE_URL } from '../../constants/AppConstants';

function MyShopFinalPage1() {
  const location = useLocation();

  const { product1 } = location.state;
  const [present] = useIonToast();
  const { id } = useParams();
  let history = useHistory();
  const { products, finalOrders } = React.useContext(ProductContext);
  const product = finalOrders.filter((item) => item.id == id);
  const productsList = product.map((item) => item.productsList);
  const userId = product.map((item) => item.userId)[0];
  const newProductList1 = productsList.toString();
  const finalo = products.data.filter((item) =>
    newProductList1.includes(item.id)
  );
  const considerTourLeader = product.map((item) => item.considerTourLeader)[0];
  const tourLeaderC = product.map((item) => item.tourLeaderC)[0];
  const tourLeaderName = product.map((item) => item.tourLeaderName)[0];
  const tourGuideName = product.map((item) => item.tourGuideName)[0];
  const shopId = product.map((item) => item.id)[0];
  const sid = product.map((item) => item.sid)[0];
  const [first, setfirst] = useLocalStorage('link', '');
  const [btnActive, setBtnActive] = useState(true);
  const [customerOrders, setcustomerOrders] = useState([]);
  const [value, setValue] = useState();
  const [totalPrices, setTotalPrices] = useState([]);
  const [productStatusOfOrder, setProductStatusOfOrder] = useState();
  const [productOrderNote, setproductOrderNote] = useState();
  const TouristSchema = yup.object({
    name: yup.string().required('Your name is required').max(25),
    email: yup.string().email().required('Your Email is required'),
  });

  useEffect(() => {
    setfirst(`https://backend.shopinbus.com/shop/${id}`);
  }, [id, setfirst]);
  console.log(first);
  const addToFavorite = (id) => {
    if (!customerOrders.includes(id)) {
      const foundValues = finalo.filter((item) => item.id === id);
      setcustomerOrders(customerOrders.concat(foundValues));
    }
  };

  const removeFavorite = (id) => {
    const objWithIdIndex = customerOrders.findIndex((order) => order.id === id);
    if (objWithIdIndex > -1) {
      customerOrders.splice(objWithIdIndex, 1);
    }
    setcustomerOrders(customerOrders);
  };

  const addTocart = (price) => {
    if (!customerOrders.includes(id)) {
      setTotalPrices(totalPrices.concat(price));
    }
  };

  const removeFromCard = (price) => {
    let index = totalPrices.indexOf(price);
    console.log(index);
    let temp = [
      ...totalPrices.slice(0, index),
      ...totalPrices.slice(index + 1),
    ];
    setTotalPrices(temp);
  };

  const calculatePrice = customerOrders.reduce((prevVal, currentVal) => {
    return prevVal + currentVal.price;
  }, 0);

  const totalPrice = calculatePrice;

  const presentToast2 = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message: ' Your order have subbmited ',
      duration: 1500,
      position: 'bottom',
      color: 'success',
      icon: thumbsUpOutline,
    });
  };
  const ErrorUpdate = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message:
        'Order can not be update, please try again, or check your internet connection',
      duration: 1500,
      position: 'bottom',
      color: 'danger',
      icon: thumbsDownOutline,
    });
  };

  const filerOrder = (customerOrder) => {
    const filteredArray = [];

    customerOrders.map((item) => {
      if (filteredArray.find((x) => x.id === item.id)) {
        filteredArray.find((x) => x.id === item.id).quantity += 1;
        return item;
      } else {
        // filteredArray.push({_id:item._id , quantity: 1})
        filteredArray.push({ ...item, quantity: 1 });
        return item;
      }
    });

    return filteredArray;
  };

  const handleSubmit = (name, email) => {
    setBtnActive(false);
    axios
      .post('https://shop.holyland.app/api/useroders/add', {
        tourLeaderC,
        tourLeaderName,
        considerTourLeader,
        tourGuideName,
        productsList,
        customerOrders: filerOrder(customerOrders),
        // customerOrders,
        userId,
        name,
        email,
        shopId,
        total: totalPrice,
        sid,
        phoneNumber: product1.value,
        // productStatusOfOrder,
        // productOrderNote,
      })

      .then((res) => {
        console.log(res);
        presentToast2();

        history.push(`/Thankyou`, {
          producto: {
            name,
            email,
            phoneNumber: product1.value,
            totalPrice: totalPrice,
            customerOrders,
          },
        });
        window.location.reload();
      })
      .catch((err) => {
        ErrorUpdate();
        console.Consolelog(err);
      });
  };

  return (
    <div>
      <Design>
        <IonPage style={{ paddingTop: '70px' }}>
          <Menu what="SHOP PAGE" />
          {console.log('local===>', first)}
          {console.log('finalo===>', finalo)}
          {console.log('finalOrders==>', finalOrders)}
          {console.log('finalOrders==>', finalOrders)}
          {console.log('customerOrders ==>', customerOrders)}
          {console.log('final customerOrders ==>', filerOrder(customerOrders))}
          {console.log('phoneNumber==>', product1.value)}
          {console.log('productsList ==>', productsList)}
          {console.log('id ==>', id)}
          <Formik
            initialValues={{
              name: '',
              email: '',
            }}
            validationSchema={TouristSchema}
            onSubmit={(values, actions) => {
              handleSubmit(values.name, values.email);
            }}
          >
            {(formikProps) => (
              <IonContent>
                {console.log('customer orders=>', customerOrders)}
                {console.log('fproduct=>', product)}
                {console.log('finalo=>', finalo)}
                <IonGrid>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12">
                        <IonImg
                          src={Steps1}
                          style={{
                            paddingTop: '0px',
                            paddingLeft: '60px',
                            paddingRight: '60px',
                          }}
                        ></IonImg>
                      </IonCol>
                    </IonRow>
                    <IonRow class="ion-padding">
                      <IonCol>
                        <IonText color="dark" class="ion-text-center">
                          <h3>
                            Provide us your name, Email and choose your gifts
                          </h3>
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <IonGrid>
                    <IonRow class="ion-padding">
                      <IonCol size="12">
                        <PhoneInput
                          disabled
                          placeholder={'Insert your phone number'}
                          country={'us'}
                          value={product1.value}
                          onChange={setValue}
                        />
                      </IonCol>
                      {console.log('idddd=>', id)}
                      {console.log('inserted phone number', value)}
                    </IonRow>
                  </IonGrid>

                  <div>
                    <IonRow>
                      <IonCol>
                        <IonItem counter={true} required={true}>
                          <IonLabel position="floating">Your name</IonLabel>
                          <IonInput
                            autofocus={true}
                            autocomplete={true}
                            maxlength={25}
                            inputmode="text"
                            type="text"
                            clearOnEdit={true}
                            clearInput={true}
                            value={formikProps.values.name}
                            placeholder="Your name"
                            onIonChange={formikProps.handleChange('name')}
                            onBlur={formikProps.handleBlur('name')}
                          ></IonInput>{' '}
                          <div
                            style={{
                              color: '#a2852e',
                              fontSize: '12px',
                              marginTop: '5px',
                            }}
                          >
                            {formikProps.touched.name &&
                              formikProps.errors.name}
                          </div>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <IonItem>
                          <IonLabel position="floating">Your Email</IonLabel>
                          <IonInput
                            inputmode="text"
                            type="email"
                            clearOnEdit={true}
                            clearInput={true}
                            value={formikProps.values.email}
                            placeholder="Your email"
                            onIonChange={formikProps.handleChange('email')}
                            onBlur={formikProps.handleBlur('email')}
                            // required={true}
                          ></IonInput>{' '}
                          <div
                            style={{
                              color: '#a2852e',
                              fontSize: '12px',
                              marginTop: '5px',
                            }}
                          >
                            {formikProps.touched.email &&
                              formikProps.errors.email}
                          </div>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </div>

                  <IonRow>
                    <IonCol>
                      {finalo.map((item, i) => {
                        return (
                          <>
                            <div key={i} style={{ paddingBottom: '2px' }}>
                              <Producto
                                title={item.title}
                                about={item.about}
                                image={item.image}
                                price={item.price}
                                idProduct={item.id}
                                addToFavorite={addToFavorite}
                                removeFavorite={removeFavorite}
                                productsList={productsList}
                                customerOrders={customerOrders}
                                addTocart={addTocart}
                                removeFromCard={removeFromCard}
                              />
                            </div>
                          </>
                        );
                      })}
                    </IonCol>
                  </IonRow>
                  {btnActive && customerOrders.length > 0 && (
                    <>
                      <IonRow class="ion-padding">
                        <IonCol class="ion-text-center">
                          <IonButton
                            onClick={formikProps.handleSubmit}
                            expand="block"
                          >
                            Make order
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </>
                  )}
                </IonGrid>
                {console.log(
                  'length fo cusotmer orders',
                  customerOrders.length
                )}
              </IonContent>
            )}
          </Formik>
          <IonFooter mode="md">
            <IonToolbar>
              <IonRow>
                <IonCol>
                  <IonList lines="none">
                    <IonItem>
                      <IonBadge slot="end">{totalPrice}$</IonBadge>
                      <IonLabel>Total of your orders</IonLabel>
                    </IonItem>
                  </IonList>
                </IonCol>
              </IonRow>
            </IonToolbar>
          </IonFooter>
        </IonPage>
      </Design>
    </div>
  );
}
const Design = styled.div`
  .react-tel-input .form-control {
    height: 40px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-self: center;
    justify-content: center;
  }
  .react-tel-input {
    color: black;
  }
`;
export default MyShopFinalPage1;
