import { useParams } from 'react-router-dom';
import React, { useState } from 'react';
import { UserContext } from '../../context/user';
import styled from 'styled-components';
import UserImage from '../../image/user1.png';
import Progress from '../../components/Porgress';
import {
  IonContent,
  IonPage,
  IonGrid,
  IonImg,
  setupIonicReact,
  IonItem,
  IonLabel,
  IonRow,
  IonCol,
  IonIcon,
} from '@ionic/react';

import Menu from '../../components/Menu';
import { checkmarkCircle } from 'ionicons/icons';

function UserProfile() {
  React.useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 400);
  }, []);

  const { allUsers } = React.useContext(UserContext);

  setupIonicReact();

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const FinalUser = allUsers.filter((item) => item.id == id);
  const name = FinalUser.map((item) => item.name);
  const email = FinalUser.map((item) => item.email);
  const bio = FinalUser.map((item) => item.bio);
  const facebook = FinalUser.map((item) => item.facebook);
  const image = FinalUser.map((item) => item.image);

  const phone = FinalUser.map((item) => item.phone);

  const role = FinalUser.map((item) => item.role);
  return (
    <Design>
      <IonPage style={{ paddingTop: '65px' }}>
        {console.log('all users=>', FinalUser)}
        <Menu what={name} backBtnLink="/tourGuideList" display={true} />

        <IonContent fullscreen className="all-page">
          {isLoading ? (
            <>
              <Progress />
            </>
          ) : (
            <></>
          )}
          {image[0] === undefined ? (
            <>
              {' '}
              <div className="container">
                <div>
                  <IonImg src={UserImage} alt="Product featued image"></IonImg>{' '}
                </div>
                <div className="centered">
                  <IonLabel>
                    <span
                      style={{ fontSize: '18px', fontWeight: 'bold' }}
                      className="name"
                    >
                      {name}
                    </span>
                  </IonLabel>

                  <IonIcon
                    icon={checkmarkCircle}
                    color="secondary"
                    size="20"
                  ></IonIcon>
                </div>
              </div>
            </>
          ) : (
            <>
              {' '}
              <div className="container">
                <div>
                  <IonImg
                    src={JSON.parse(image).uploadInfo.url}
                    alt="Product featued image"
                  ></IonImg>
                </div>
                <div className="centered">
                  <IonLabel>
                    <span
                      style={{ fontSize: '18px', fontWeight: 'bold' }}
                      className="name"
                    >
                      {name}
                    </span>
                  </IonLabel>
                  <IonIcon
                    icon={checkmarkCircle}
                    color="secondary"
                    size="20"
                  ></IonIcon>
                </div>
              </div>
            </>
          )}

          {/* <IonButton onClick={() => history.goBack()}>back</IonButton> */}
          <IonGrid>
            <IonItem>
              <IonLabel>
                <h1>Name</h1>
                <p>{name}</p>
              </IonLabel>
            </IonItem>

            <IonItem>
              <IonLabel>
                <h1>Email</h1>
                <p> {email}</p>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h1>ROLE</h1>
                {role == 'isRestaurant' && <h4>Restaurant Owner</h4>}
                {role == 'isProvider' && <h4>Provider</h4>}
                {role == 'isTourGuide' && <h4>Tour Guide</h4>}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h1>Facebook</h1>
                <p> {facebook}</p>
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonLabel>
                <h1>Phone number</h1>
                <p> {phone}</p>
              </IonLabel>
            </IonItem>
            <IonRow class="ion-padding">
              <IonCol>
                <IonLabel>
                  <h1>About this tour guide</h1>
                  <p> {bio}</p>
                </IonLabel>
              </IonCol>
            </IonRow>
            {/* <IonRow class="ion-padding">
            <IonCol>
              <IonLabel>
                <h1>About this tour guide</h1>
                <p> {bio}</p>
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow class="ion-padding">
            <IonLabel>
              <h1>Tour guide orders</h1>
            </IonLabel>
          </IonRow>
          <IonRow>
            <IonCol>
              {userOrders.map((element, index) => (
                <Item
                  {...element}
                  key={index}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              ))}
            </IonCol>
          </IonRow> */}
          </IonGrid>
        </IonContent>
      </IonPage>
    </Design>
  );
}

const Design = styled.div`
  .container {
    position: relative;
    text-align: center;
    /* background-color: rgba(1, 1, 1, 0.4); */
  }
  .container {
    /* position: relative; */
    text-align: center;
    background-color: rgba(1, 1, 1, 0.4);
  }
  .centered {
    color: white;
    position: absolute;

    bottom: 3%;
    left: 3%;
    /* transform: translate(-50%, -50%); */
  }
  .name {
    text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.45);
  }
  ion-img::part(image) {
    height: 250px; /* or whatever value */
    /* you can set the height value too */
    object-fit: cover;
  }
`;
export default UserProfile;
