import React, { useState } from 'react';
import { ProductContext } from '../../context/product';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import Producto from '../../components/TouristCard';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import Menu from '../../components/MenTourist';
import styled from 'styled-components';
import Steps1 from '../../image/STEPS3.png';
import {
  IonContent,
  IonPage,
  IonToolbar,
  IonText,
  IonLabel,
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonFooter,
  IonBadge,
  useIonToast,
  IonList,
  IonImg,
} from '@ionic/react';
import { thumbsUpOutline } from 'ionicons/icons';
import { BASE_URL } from '../../constants/AppConstants';
import { Formik } from 'formik';
import * as yup from 'yup';
function UpdateTouristOrder() {
  //email
  const { id } = useParams();
  let history = useHistory();
  const location = useLocation();
  const { product1 } = location.state;
  const [present] = useIonToast();
  const { products, finalOrders } = React.useContext(ProductContext);

  const product = finalOrders.filter((item) => item.id == id);
  const productsList = product.map((item) => item.productsList);
  const userId = product.map((item) => item.userId)[0];
  const newProductList1 = productsList.toString();
  const finalo = products.data.filter((item) =>
    newProductList1.includes(item.id)
  );
  const considerTourLeader = product.map((item) => item.considerTourLeader)[0];
  const tourLeaderC = product.map((item) => item.tourLeaderC)[0];
  const tourLeaderName = product.map((item) => item.tourLeaderName)[0];
  const tourGuideName = product.map((item) => item.tourGuideName)[0];
  const shopId = product.map((item) => item.id)[0];
  const sid = product.map((item) => item.sid)[0];
  const date = new Date().toDateString();
  const order = product1.customerOrders;
  const rep = replicateOrders(...order);
  console.log('========================');

  console.log(rep);
  console.log('=========================');

  const [customerOrders, setcustomerOrders] = useState(rep); // here need to load it from customers orders ,
  const name = product1.name.toString();
  const email = product1.email.toString();
  const shopId1 = product1.shopId;

  // console.log('=================new============================');
  // console.log(order);
  // console.log('=============================================');

  const [value, setValue] = useState();
  const [totalPrices, setTotalPrices] = useState([]);
  // console.log(customerOrders, 'customerOrders array');
  const TouristSchema = yup.object({
    name: yup.string().required('Your name is required').max(25),
    email: yup.string().email().required('Your Email is required'),
  });
  function replicateOrders(orders) {
    const replicatedArray = [];

    orders.forEach((order) => {
      const { quantity } = order;

      for (let i = 0; i < quantity; i++) {
        replicatedArray.push({ ...order });
      }
    });

    return replicatedArray;
  }
  const addToFavorite = (id) => {
    if (!customerOrders.includes(id)) {
      const foundValues = finalo.filter((item) => item.id === id);
      setcustomerOrders(customerOrders.concat(foundValues));
    }
  };

  const removeFavorite = (id) => {
    const objWithIdIndex = customerOrders.findIndex((order) => order.id === id);
    if (objWithIdIndex > -1) {
      customerOrders.splice(objWithIdIndex, 1);
    }
    setcustomerOrders(customerOrders);
  };

  const addTocart = (price) => {
    if (!customerOrders.includes(id)) {
      setTotalPrices(totalPrices.concat(price));
    }
  };

  const removeFromCard = (price) => {
    let index = totalPrices.indexOf(price);
    console.log(index);
    let temp = [
      ...totalPrices.slice(0, index),
      ...totalPrices.slice(index + 1),
    ];
    setTotalPrices(temp);
  };
  // const addToFavorite = (id) => {
  //   if (!customerOrders.includes(id)) {
  //     const foundValues = products.filter((item) => item.id === id);
  //     setcustomerOrders( customerOrders.map(order => {
  //       if (order.id === foundValues[0].id) {
  //         // console.log('========================');
  //         // console.log(order);
  //         // console.log('========================');

  //         return {
  //           ...order,
  //           quantity: order.quantity + 1

  //         };
  //       }
  //       return order;
  //     }))
  //     // console.log("HERE");
  //     // console.log(customerOrders);
  //     // setcustomerOrders(customerOrders.concat(foundValues));
  //   }
  // }

  // const removeFavorite = (id) => {
  //   // if (!customerOrders.includes(id)) {
  //   //   const foundValues = products.filter((item) => item.id === id);
  //   //   setcustomerOrders( customerOrders.map(order => {
  //   //     if (order.id === foundValues[0].id && !order.quantity>0 ) {
  //   //       console.log('========================');
  //   //       console.log(order);
  //   //       console.log('========================');

  //   //       return {
  //   //         ...order,
  //   //         quantity: order.quantity -1

  //   //       };
  //   //     }
  //   //     return order;
  //   //   }))
  //   //   console.log("HERE");
  //   //   console.log(customerOrders);
  //   //   // setcustomerOrders(customerOrders.concat(foundValues));
  //   // }
  //   const objWithIdIndex = customerOrders.findIndex((order) => order.id === id);
  //   console.log(objWithIdIndex);
  //   if (customerOrders[objWithIdIndex]?.quantity === 1) {

  //     setcustomerOrders(customerOrders.splice(objWithIdIndex, 1))
  //   }
  //   setcustomerOrders( customerOrders.map(order => {
  //         if (order.id === customerOrders[objWithIdIndex].id ) {
  //           // console.log('========================');
  //           // console.log(order);
  //           // console.log('========================');

  //           return {
  //             ...order,
  //             quantity: order.quantity -1

  //           };
  //         }
  //         return order;
  //       }))

  // };

  // const addTocart = (price) => {
  //   if (!customerOrders.includes(id)) {
  //     setTotalPrices(totalPrices.concat(price));
  //   }
  // };

  // const removeFromCard = (price) => {
  //   let index = totalPrices.indexOf(price);
  //   // console.log(index);
  //   let temp = [
  //     ...totalPrices.slice(0, index),
  //     ...totalPrices.slice(index + 1),
  //   ];
  //   setTotalPrices(temp);
  // };

  const calculatePrice = customerOrders.reduce((prevVal, currentVal) => {
    return prevVal + currentVal.price;
  }, 0);

  const totalPrice = calculatePrice;

  const presentToast2 = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message: ' Your order have been updated',
      duration: 1500,
      position: 'bottom',
      color: 'success',
      icon: thumbsUpOutline,
    });
  };
  const filerOrder = (customerOrder) => {
    const filteredArray = [];

    customerOrders.map((item) => {
      if (filteredArray.find((x) => x.id === item.id)) {
        filteredArray.find((x) => x.id === item.id).quantity += 1;
        return item;
      } else {
        // filteredArray.push({_id:item._id , quantity: 1})
        filteredArray.push({ ...item, quantity: 1 });
        return item;
      }
    });

    return filteredArray;
  };
  const handleSubmit = (name, email) => {
    console.log('saif');
    axios

      .put(`https://shop.holyland.app/api/useroders/${shopId1}/update`, {
        tourLeaderC,
        tourLeaderName,
        considerTourLeader,
        tourGuideName,
        productsList,
        customerOrders: filerOrder(customerOrders),
        userId,
        name,
        email,
        shopId,
        total: totalPrice,
        sid,
        phoneNumber: product1.value,
        date,
      })

      .then((res) => {
        console.log('===================API CALLL =================');
        console.log(res);
        console.log('====================================');

        presentToast2();
        // setLoading(false);
        history.push(`/Thankyou`, {
          producto: {
            name,
            email,
            phoneNumber: product1.value,
            totalPrice: totalPrice,
            customerOrders,
          },
        });
        window.location.reload();
      })
      .catch((error) => console.log(error));
  };
  const deleteOrder = (OrderId) => {
    axios
      .delete(`https://shop.holyland.app/api/useroders/${OrderId}`)
      .then((res) => {
        window.location.reload(true);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      <Design>
        <IonPage style={{ paddingTop: '70px' }}>
          {console.log('producto1', product1.value)}
          {console.log('finalOrders', finalOrders)}
          {console.log('  shopId', shopId)}
          {console.log('filerOrder(customerOrders)==>', order)}
          {console.log('customerOrders==>', customerOrders)}
          {console.log(
            'khahara filerOrder(customerOrders)==>, filerOrder(customerOrders)',
            filerOrder(customerOrders)
          )}

          <Menu what="order the best" />

          <Formik
            initialValues={{
              name: name,
              email: email,
            }}
            validationSchema={TouristSchema}
            onSubmit={(values, actions) => {
              handleSubmit(values.name, values.email);
            }}
          >
            {(formikProps) => (
              <IonContent>
                <IonGrid>
                  <IonGrid>
                    <IonRow>
                      <IonCol size="12">
                        <IonImg
                          src={Steps1}
                          style={{
                            paddingTop: '0px',
                            paddingLeft: '60px',
                            paddingRight: '60px',
                          }}
                        ></IonImg>
                      </IonCol>
                    </IonRow>
                    <IonRow class="ion-padding">
                      <IonCol>
                        <IonText color="dark" class="ion-text-center">
                          <h3>Dear {name}, update your order nnnnnn</h3>
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                  <IonGrid>
                    <IonRow class="ion-padding">
                      <IonCol size="12">
                        <PhoneInput
                          disabled
                          placeholder={'Insert your phone number'}
                          country={'us'}
                          value={product1.value}
                          onChange={setValue}
                        />
                      </IonCol>
                    </IonRow>
                  </IonGrid>

                  <IonRow>
                    <IonCol>
                      <IonItem counter={true} required={true}>
                        <IonLabel position="floating">Client name</IonLabel>
                        <IonInput
                          autofocus={true}
                          autocomplete={true}
                          maxlength={25}
                          inputmode="text"
                          type="text"
                          clearOnEdit={false}
                          clearInput={true}
                          value={formikProps.values.name}
                          placeholder="Your name"
                          onIonChange={formikProps.handleChange('name')}
                          onBlur={formikProps.handleBlur('name')}
                        ></IonInput>
                        {/* {console.log('name', name)} */}
                        <div
                          style={{
                            color: '#a2852e',
                            fontSize: '12px',
                            marginTop: '5px',
                          }}
                        >
                          {formikProps.touched.name && formikProps.errors.name}
                        </div>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem>
                        <IonLabel position="floating">Client Email</IonLabel>
                        <IonInput
                          inputmode="text"
                          type="email"
                          clearOnEdit={false}
                          clearInput={true}
                          value={formikProps.values.email}
                          placeholder="Your email"
                          onIonChange={formikProps.handleChange('email')}
                          onBlur={formikProps.handleBlur('email')}
                        ></IonInput>
                        <div
                          style={{
                            color: '#a2852e',
                            fontSize: '12px',
                            marginTop: '5px',
                          }}
                        >
                          {formikProps.touched.email &&
                            formikProps.errors.email}
                        </div>
                      </IonItem>
                    </IonCol>
                  </IonRow>

                  <IonRow>
                    <IonCol>
                      {finalo.map((item, i) => {
                        return (
                          <>
                            <div key={i}>
                              <Producto
                                title={item.title}
                                about={item.about}
                                image={item.image}
                                price={item.price}
                                idProduct={item.id}
                                addToFavorite={addToFavorite}
                                removeFavorite={removeFavorite}
                                productsList={productsList}
                                customerOrders={customerOrders}
                                addTocart={addTocart}
                                removeFromCard={removeFromCard}
                              />
                            </div>
                          </>
                        );
                      })}
                    </IonCol>
                  </IonRow>
                  <IonRow class="ion-padding">
                    <IonCol class="ion-text-center" size="6">
                      <IonButton
                        onClick={formikProps.handleSubmit}
                        expand="block"
                      >
                        UPDATE
                      </IonButton>
                    </IonCol>

                    <IonCol class="ion-text-center" size="6">
                      <IonButton
                        onClick={() => deleteOrder(product1.shopId)}
                        expand="block"
                        color="danger"
                      >
                        Delete order
                      </IonButton>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </IonContent>
            )}
          </Formik>
          <IonFooter mode="md">
            <IonToolbar>
              <IonRow>
                <IonCol>
                  <IonList lines="none">
                    <IonItem>
                      <IonBadge slot="end">{totalPrice}$</IonBadge>
                      {/* <IonBadge slot="end">{total}$</IonBadge> */}
                      <IonLabel>Total of your orders</IonLabel>
                    </IonItem>
                  </IonList>
                </IonCol>
              </IonRow>
            </IonToolbar>
          </IonFooter>
        </IonPage>
      </Design>
    </div>
  );
}
const Design = styled.div`
  .react-tel-input .form-control {
    height: 40px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-self: center;
    justify-content: center;
  }
`;
export default UpdateTouristOrder;
