import {
  IonApp,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonRouterOutlet,
  IonToggle,
  setupIonicReact,
  IonToolbar,
  IonTitle,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {
  addCircleOutline,
  bagHandleOutline,
  duplicateOutline,
  homeOutline,
  logInOutline,
  logOutOutline,
  moon,
  peopleOutline,
  personAddOutline,
  readerOutline,
  settingsOutline,
  storefrontOutline,
  sunny,
} from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UserContext } from './context/user';
import useLocalStorage from './hooks/useLocalStorage';

import AddTourGuide from './pages/Admin/AddTourGuide';
import Home from './pages/Home';
import Login from './pages/Login';
import AddProducts from './pages/Providers/AddProducts';
import Allordersegments from './pages/Admin/AllOrderSegment';
import ProductList from './pages/Providers/ProductList';
import ProductListPorvider from './pages/Providers/ProductListPorvider';
import TourGuideList from './pages/Admin/TourGuideList';
import AllTouristsOrders from './pages/Delete/AllTouristsOrdersD';
import HomeProductsList from './pages/Delete/HomeProductsListD';
import ProductPage from './pages/Different/ProductPageD';
import WrongPage from './pages/Different/WrongPage';
import FinalShopPage from './pages/TourGuide/FinalShopPage';
import HomeDashboard from './pages/TourGuide/HomeDashboard';
import ShopView from './pages/TourGuide/MyShopFinalPageView';
import MyShops from './pages/TourGuide/MyShops';
import ProvidersOrders from './pages/Providers/ProvidersOrders';
import Explore from './pages/Tourist/Explore';
import MyShopFinalPage from './pages/Tourist/MyShopFinalPage';
import MyShopFinalPage1 from './pages/Tourist/MyShopFinalPage1';
import Thankyou from './pages/Tourist/Thankyou';
import TouristForm from './pages/Tourist/ToristForm';
import TouristOrderPage from './pages/Tourist/TouristOrderPage';
import UpdateTouristOrder from './pages/Tourist/UpdateTouristOrder';
import UpdateOrderPart1 from './pages/UpdateOrderPart1';
import UpdateProductNew from './pages/UpdateProductNew';
import Logo from './image/appLogo3.png';
import AdminFinalOrderPage from './pages/Admin/AdminFinalOrderPage';
import PdfTest from './pages/Different/pdfbasic';
import ProviderFinalOrderPage from './pages/Providers/ProviderFinalOrderPage';
import UpdateUserProfile from './pages/Admin/UpdateUserProfile';
import UserProfile from './pages/Admin/UserProfile';
import SettingPage from './pages/TourGuide/SettingPage';
import ProviderOrdersMainPage from './pages/Providers/ProviderOrdersMainPage';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/display.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

/* Theme variables */
import './theme/variables.css';

// const AddTourGuide = lazy(() => import('./pages/Admin/AddTourGuide'));
// const Home = lazy(() => import('./pages/Home'));
// const Login = lazy(() => import('./pages/Login'));
// const AddProducts = lazy(() => import('./pages/Admin/AddProducts'));
// const Allordersegments = lazy(() => import('./pages/Admin/AllOrderSegment'));
// const ProductList = lazy(() => import('./pages/Admin/ProductList'));
// const TourGuideList = lazy(() => import('./pages/Admin/TourGuideList'));
// const AllTouristsOrders = lazy(() => import('./pages/Delete/AllTouristsOrdersD'));
// const HomeProductsList = lazy(() => import('./pages/Delete/HomeProductsListD'));
// const ProductPage = lazy(() => import('./pages/Different/ProductPageD'));
// const WrongPage = lazy(() => import('./pages/Different/WrongPage'));
// const FinalShopPage = lazy(() => import('./pages/TourGuide/FinalShopPage'));
// const HomeDashboard = lazy(() => import('./pages/TourGuide/HomeDashboard'));
// const ShopView = lazy(() => import('./pages/TourGuide/MyShopFinalPageView'));
// const MyShops = lazy(() => import('./pages/TourGuide/MyShops'));
// const Explore = lazy(() => import('./pages/Tourist/Explore'));
// const MyShopFinalPage = lazy(() => import('./pages/Tourist/MyShopFinalPage'));
// const MyShopFinalPage1 = lazy(() => import('./pages/Tourist/MyShopFinalPage1'));
// const Thankyou = lazy(() => import('./pages/Tourist/Thankyou'));
// const TouristForm = lazy(() => import('./pages/Tourist/ToristForm'));
// const TouristOrderPage = lazy(() => import('./pages/Tourist/TouristOrderPage'));
// const UpdateTouristOrder = lazy(() => import('./pages/Tourist/UpdateTouristOrder'));
// const UpdateOrderPart1 = lazy(() => import('./pages/UpdateOrderPart1'));
// const UpdateProductNew = lazy(() => import('./pages/UpdateProductNew'));
// const AdminFinalOrderPage = lazy(() => import('./pages/Admin/AdminFinalOrderPage'));
// const PdfTest = lazy(() => import('./pages/Different/pdfbasic'));
// const UpdateUserProfile = lazy(() => import('./pages/Admin/UpdateUserProfile'));
// const UserProfile = lazy(() => import('./pages/Admin/UserProfile'));
// const SettingPage = lazy(() => import('./pages/TourGuide/SettingPage'));

setupIonicReact();

function App() {
  const { user, userLogout } = React.useContext(UserContext);
  const [items, setItems] = useState([]);
  const [theme, setTheme] = useLocalStorage('theme', 'light');
  const [checked1, setChecked1] = useState(false);
  {
    console.log('user1->', user);
  }
  const toggleTheme = () => {
    console.log(items, checked1);
    window.location.reload();

    if (theme === 'light') {
      setTheme('dark');
      setChecked1(false);
    } else {
      setTheme('light');
      setChecked1(true);
    }
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('theme') || '{}');

    if (items) {
      setItems(items);
    }
  }, []);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <div>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Switch>
              <Route exact path="/home">
                <Home />
              </Route>
              {/* <Route path="/loginN">
                <Login />
              </Route> */}
              <Route path="/login">
                <Login />
              </Route>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
              <Route path="/AddTourGuide">
                <AddTourGuide />
              </Route>
              {/* <Route path="/allOrderAdmin">
                <AllOrders />
              </Route> */}
              <Route path="/addProduct">
                <AddProducts />
              </Route>
              <Route path="/productList">
                <ProductList />
              </Route>
              <Route path="/productListProvider">
                <ProductListPorvider />
              </Route>
              <Route path="/editProduct/">
                <UpdateProductNew />
              </Route>
              <Route path="/tourGuideList">
                <TourGuideList />
              </Route>
              <Route path="/shop/:id">
                <MyShopFinalPage />
              </Route>
              <Route path="/myFinalShopPage1/:id">
                <MyShopFinalPage1 />
              </Route>
              <Route path="/updateShop/:id">
                <UpdateTouristOrder />
              </Route>
              <Route path="/product/:id">
                <ProductPage />
              </Route>

              <Route path="/user/:id">
                <UserProfile />
              </Route>

              <Route path="/TouristOrderPage/:id">
                <TouristOrderPage />
              </Route>
              <Route path="/Shopview/:id">
                <ShopView />
              </Route>
              <Route path="/shopProducts">
                <HomeProductsList />
              </Route>
              <Route path="/FinaShopPage/:sid">
                <FinalShopPage />
              </Route>
              <Route path="/AdminFinalOrderPage/:sid">
                <AdminFinalOrderPage />
              </Route>
              <Route path="/ProviderFinalOrderPage/:sid">
                <ProviderFinalOrderPage />
              </Route>
              <Route path="/ProviderOrdersMainPage">
                <ProviderOrdersMainPage />
              </Route>
              <Route path="/touristOrders/:ids">
                <AllTouristsOrders />
              </Route>
              <Route path="/UpdateOrderP1">
                <UpdateOrderPart1 />
              </Route>

              <Route path="/GuideShops">
                <MyShops />
              </Route>
              <Route path="/ProvidersOrders/:sid">
                <ProvidersOrders />
              </Route>
              <Route path="/Addshop">
                <HomeDashboard />
              </Route>
              <Route path="/Thankyou">
                <Thankyou />
              </Route>
              <Route path="/Wrong">
                <WrongPage />
              </Route>
              <Route path="/allordersegments">
                <Allordersegments />
              </Route>
              {/* <Route path="/touristForm/:id">
                <TouristForm />
              </Route> */}
              <Route path="/touristForm/:value">
                <TouristForm />
              </Route>
              <Route path="/explore">
                <Explore />
              </Route>
              <Route path="/pdf/:sid">
                <PdfTest />
              </Route>

              <Route path="/updateUser/">
                <UpdateUserProfile />
              </Route>
              <Route path="/settingPage/">
                <SettingPage />
              </Route>
              {/* <Route path="*">
                <WrongPage />
              </Route> */}
            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
        <IonMenu
          contentId="main-content"
          type="overlay"
          style={{ zIndex: '999999999999999999999999999999999' }}
        >
          {' '}
          <IonHeader style={{ display: 'flex', justifyContent: 'center' }}>
            <img
              src={Logo}
              alt="SHOP IN BUS"
              style={{
                height: '200px',
                justifySelf: 'center',
                justifyItems: 'center',
              }}
            />
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              <IonMenuToggle>
                {/*ADMIN MENU */}
                {user.user && user.user.role === 'isAdmin' && (
                  <>
                    <IonItem button routerLink="/home" routerDirection="none">
                      <IonIcon slot="start" icon={homeOutline} />
                      <IonLabel>Admin Home</IonLabel>
                    </IonItem>

                    <IonItem
                      button
                      routerLink="/AddTourGuide"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={personAddOutline} />
                      <IonLabel>Add user</IonLabel>
                    </IonItem>

                    <IonItem
                      button
                      routerLink="/allordersegments"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={bagHandleOutline} />
                      <IonLabel>All orders</IonLabel>
                    </IonItem>

                    <IonItem
                      button
                      routerLink="/productList"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={readerOutline} />
                      <IonLabel>Products List </IonLabel>
                    </IonItem>
                    <IonItem
                      button
                      routerLink="/tourGuideList"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={peopleOutline} />
                      <IonLabel>Users List </IonLabel>
                    </IonItem>
                  </>
                )}
                {/* TOUR GUIDE MENU */}
                {user.user && user.user.role === 'isProvider' && (
                  <>
                    <IonItem button routerLink="/home" routerDirection="none">
                      <IonIcon slot="start" icon={homeOutline} />
                      <IonLabel>Provider Home </IonLabel>
                    </IonItem>
                    {/* <IonItem
                      button
                      routerLink="/GuideShops"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={storefrontOutline} />
                      <IonLabel>My Orders</IonLabel>
                    </IonItem> */}
                    <IonItem
                      button
                      routerLink="/addProduct"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={duplicateOutline} />
                      <IonLabel> Add Products </IonLabel>
                    </IonItem>

                    {/* <IonItem
                      button
                      routerLink="/Addshop"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={addCircleOutline} />
                      <IonLabel>Add Order</IonLabel>
                    </IonItem> */}
                    <IonItem
                      button
                      routerLink="/ProviderOrdersMainPage"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={bagHandleOutline} />
                      <IonLabel>My orders</IonLabel>
                    </IonItem>
                    <IonItem
                      button
                      routerLink="/productListProvider"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={readerOutline} />
                      <IonLabel>Products List </IonLabel>
                    </IonItem>
                    <IonItem
                      button
                      routerLink="/settingPage"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={settingsOutline} />
                      <IonLabel>My account</IonLabel>
                    </IonItem>
                  </>
                )}
                {/* END PROVIDER MENU */}

                {/* TOUGUIDE MENU MENU */}
                {user.user && user.user.role === 'isTourGuide' && (
                  <>
                    <IonItem button routerLink="/home" routerDirection="none">
                      <IonIcon slot="start" icon={homeOutline} />
                      <IonLabel>Tour guide Home </IonLabel>
                    </IonItem>
                    {/* <IonItem
                      button
                      routerLink="/GuideShops"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={storefrontOutline} />
                      <IonLabel>My Orders</IonLabel>
                    </IonItem> */}

                    <IonItem
                      button
                      routerLink="/Addshop"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={addCircleOutline} />
                      <IonLabel>Add Order</IonLabel>
                    </IonItem>
                    <IonItem
                      button
                      routerLink="/GuideShops"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={storefrontOutline} />
                      <IonLabel>My Orders</IonLabel>
                    </IonItem>

                    <IonItem
                      button
                      routerLink="/settingPage"
                      routerDirection="none"
                    >
                      <IonIcon slot="start" icon={settingsOutline} />
                      <IonLabel>My account</IonLabel>
                    </IonItem>
                  </>
                )}
                {user.user ? (
                  <>
                    <IonItem
                      button
                      routerLink="/login"
                      routerDirection="none"
                      onClick={userLogout}
                    >
                      <IonIcon slot="start" icon={logOutOutline} />
                      <IonLabel>Logout</IonLabel>
                    </IonItem>
                  </>
                ) : (
                  <>
                    <IonItem button routerLink="/login" routerDirection="none">
                      <IonIcon slot="start" icon={logInOutline} />
                      <IonLabel>Login</IonLabel>
                    </IonItem>
                  </>
                )}

                <IonItem>
                  {theme === 'dark' ? (
                    <>
                      <IonIcon
                        slot="start"
                        icon={moon}
                        color="primary"
                        className="component-icon component-icon-dark"
                      />
                      <IonLabel>Dark Mode</IonLabel>
                      <IonToggle
                        slot="end"
                        name="darkMode"
                        checked={true}
                        onIonChange={toggleTheme}
                      />
                    </>
                  ) : (
                    <>
                      {' '}
                      <IonIcon
                        slot="start"
                        icon={sunny}
                        className="component-icon component-icon-dark"
                      />
                      <IonLabel>Light Mode</IonLabel>
                      <IonToggle
                        slot="end"
                        name="darkMode"
                        checked={false}
                        onIonChange={toggleTheme}
                      />
                    </>
                  )}
                </IonItem>
              </IonMenuToggle>
            </IonList>
          </IonContent>
        </IonMenu>
      </IonApp>
    </div>
  );
}

export default App;
