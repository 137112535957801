import React from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { UserContext } from '../context/user';
import axios from 'axios';
import * as yup from 'yup';

import Menu from '../components/Menu';
import {
  IonContent,
  IonPage,
  IonText,
  IonList,
  IonLabel,
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  useIonToast,
  IonCheckbox,
} from '@ionic/react';

import {
  lockClosedOutline,
  mailOutline,
  thumbsUpOutline,
  thumbsDownOutline,
  eyeOutline,
  eyeOffOutline,
} from 'ionicons/icons';
import { BASE_URL } from '../constants/AppConstants';
const loginSchema = yup.object({
  email: yup
    .string()
    .required('Email is required')
    .email('Email must have proper format'),
  password: yup.string().required('Password is required'),
});

function Login() {
  const [present] = useIonToast();
  const presentToast2 = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message: 'Weclome to shop in bus, Enjoy your time',
      duration: 1500,
      position: 'bottom',
      color: 'success',
      icon: thumbsUpOutline,
    });
  };
  const ErrorUpdate = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message:
        'There is error, please try again, or check your internet connection',
      duration: 1500,
      position: 'bottom',
      color: 'danger',
      icon: thumbsDownOutline,
    });
  };
  const history = useHistory();
  const { userLogin } = React.useContext(UserContext);
  const [passwordType, setPasswordType] = React.useState('password');
  const handleSubmit = async (email, password) => {
    console.log(email, password);
    axios
      .post('https://shop.holyland.app/api/users/login', {
        email,
        password,
      })
      .then((res) => {
        if (res.data.user) {
          console.log(res.data);
          console.log(res.data.user);
          const { token, user } = res.data;
          const newUser1 = { token, user };
          userLogin(newUser1);
          presentToast2();
          history.push('/home');
        } else {
          ErrorUpdate();
        }
      })
      .catch((error) => console.log(error));
  };
  return (
    <Design>
      <IonPage>
        <Menu what="SIGN IN" backBtnLink="/home" display={true} />

        <IonContent fullscreen className="all-page">
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={loginSchema}
            onSubmit={(values, actions) => {
              handleSubmit(values.email, values.password);
            }}
          >
            {(formikProps) => (
              <div>
                <IonGrid>
                  <IonRow className="r1">
                    <IonCol>
                      <IonText color="dark" class="ion-text-center">
                        <h1>SIGN IN</h1>
                      </IonText>
                      <IonList
                        class="margin-top-25 ion-margin margin-0-28"
                        className="list"
                      >
                        <IonRow>
                          <IonCol>
                            <IonItem class="roundedInput8px margin-bottom-15 ">
                              <IonLabel>
                                <IonIcon icon={mailOutline}></IonIcon>
                              </IonLabel>
                              <IonInput
                                autofocus={false}
                                inputmode="text"
                                clearInput={false}
                                type="email"
                                value={formikProps.values.email}
                                placeholder="Email"
                                onIonChange={formikProps.handleChange('email')}
                                onBlur={formikProps.handleBlur('email')}
                              ></IonInput>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol class="ion-padding">
                            <div
                              style={{
                                color: '#a2852e',
                                fontSize: '12px',
                                marginTop: '5px',
                              }}
                            >
                              {formikProps.touched.email &&
                                formikProps.errors.email}
                            </div>
                          </IonCol>
                        </IonRow>
                        <IonRow class="ion-padding-top">
                          <IonCol>
                            <IonItem>
                              <IonLabel>
                                <IonIcon
                                  color="medium"
                                  icon={lockClosedOutline}
                                ></IonIcon>
                              </IonLabel>

                              <IonInput
                                maxlength={15}
                                inputmode="text"
                                placeholder="Password"
                                type={passwordType}
                                value={formikProps.values.password}
                                onIonChange={formikProps.handleChange(
                                  'password'
                                )}
                                onBlur={formikProps.handleBlur('password')}
                              ></IonInput>

                              {passwordType === 'text' ? (
                                <>
                                  <IonIcon
                                    onClick={() => setPasswordType('password')}
                                    color="medium"
                                    icon={eyeOutline}
                                  ></IonIcon>
                                </>
                              ) : (
                                <>
                                  <IonIcon
                                    onClick={() => setPasswordType('text')}
                                    color="medium"
                                    icon={eyeOffOutline}
                                  ></IonIcon>
                                </>
                              )}
                            </IonItem>
                          </IonCol>
                        </IonRow>
                        <IonRow>
                          <IonCol class="ion-padding">
                            <div
                              style={{
                                color: '#a2852e',
                                fontSize: '12px',
                                marginTop: '5px',
                              }}
                            >
                              {formikProps.touched.password &&
                                formikProps.errors.password}
                            </div>
                          </IonCol>
                        </IonRow>

                        <IonRow
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                            alignItems: 'center',
                            gap: 10,
                          }}
                        >
                          <IonCheckbox labelPlacement="end"></IonCheckbox>
                          <IonText>Remember Me</IonText>
                        </IonRow>

                        <IonRow>
                          <IonCol>
                            <IonButton
                              className="button"
                              // mode="ios"
                              class="ion-margin-top"
                              expand="full"
                              size="large"
                              onClick={formikProps.handleSubmit}
                            >
                              <IonText color="white">SIGN IN</IonText>
                            </IonButton>
                          </IonCol>
                        </IonRow>
                      </IonList>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              </div>
            )}
          </Formik>
        </IonContent>
      </IonPage>
    </Design>
  );
}
const Design = styled.div`
  .r1 {
    display: flex;
    height: 80vh;
    align-items: center;
  }
  .col {
    /* background-color: black;
    background-color: #f5f5f540; */
  }
`;
export default Login;
