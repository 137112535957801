import React, { useState } from 'react';
import { UserContext } from '../../context/user';
import Loader from '../../components/Loading';
import { IonContent, IonPage, IonList, IonSearchbar } from '@ionic/react';
import Menu from '../../components/Menu';
import UserComponent from '../../components/UserComponent';

function TourGuideList() {
  const [search, setSearch] = useState('');
  const { allUsers, isLoading } = React.useContext(UserContext);

  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div>
            <IonPage style={{ paddingTop: '70px' }}>
              <Menu what="TOUR GUIDE LIST" backBtnLink="/home" display={true} />
              <IonContent fullscreen className="all-page">
                <IonSearchbar
                  animated={false}
                  placeholder="Tour guide name"
                  onIonChange={(e) => setSearch(e.target.value)}
                ></IonSearchbar>
                {allUsers
                  .filter((item) => {
                    return search.toLowerCase() === ''
                      ? item
                      : item.name.toLowerCase().includes(search);
                  })
                  .map((item) => {
                    return (
                      <>
                        <IonList key={item.id} class="ion-no-padding">
                          <UserComponent user={item} />
                        </IonList>
                      </>
                    );
                  })}
              </IonContent>
            </IonPage>
          </div>
        </>
      )}
    </>
  );
}

export default TourGuideList;
