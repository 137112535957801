import React from 'react';
import { ProductContext } from '../../context/product';
import { useParams } from 'react-router-dom';
import Menu from '../../components/Menu';
import styled from 'styled-components';
import {
  IonContent,
  IonPage,
  IonText,
  IonLabel,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonNote,
  IonIcon,
} from '@ionic/react';
import { checkmarkCircle, closeCircle } from 'ionicons/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectCoverflow } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-coverflow';
function MyShopFinalPageView() {
  //email
  const { id } = useParams();
  const { products, finalOrders } = React.useContext(ProductContext);
  const product = finalOrders.filter((item) => item.id == id);
  const productsList = product.map((item) => item.productsList);
  const newProductList1 = productsList.toString();
  const finalo = products.data.filter((item) =>
    newProductList1.includes(item.id)
  );
  const considerTourLeader = product.map((item) => item.considerTourLeader)[0];
  const tourLeaderC = product.map((item) => item.tourLeaderC)[0];
  const tourLeaderName = product.map((item) => item.tourLeaderName)[0];
  const tourGuideName = product.map((item) => item.tourGuideName)[0];
  const statusofOrder = product.map((item) => item.statusofOrder)[0];

  return (
    <div>
      <Design>
        <IonPage>
          <Menu what="SHOP " backBtnLink="/GuideShops" display={true} />
          <IonContent>
            {/* <h1>{id}</h1> */}
            {console.log('finalOrders==>', finalOrders)}
            {/* {console.log('product==>', prod)} */}
            <IonGrid>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    <IonText color="dark" class="ion-text-center">
                      <h3>YOUR SHOP DETAILS</h3>
                    </IonText>
                  </IonCol>
                </IonRow>
              </IonGrid>

              <IonList inset={true}>
                <IonItem>
                  <IonLabel>Tour Guide name </IonLabel>
                  <IonNote slot="end" color="primary">
                    {tourGuideName}
                  </IonNote>
                </IonItem>

                <IonItem>
                  <IonLabel>Consider tour leader </IonLabel>
                  <IonNote slot="end" color="primary">
                    {considerTourLeader ? (
                      <IonIcon
                        icon={checkmarkCircle}
                        size="large"
                        color="primary"
                      />
                    ) : (
                      <IonIcon
                        icon={closeCircle}
                        size="large"
                        color="primary"
                      />
                    )}
                  </IonNote>
                </IonItem>

                {considerTourLeader ? (
                  <>
                    <IonItem>
                      <IonLabel>Tour Leader name </IonLabel>
                      <IonNote slot="end" color="primary">
                        {tourLeaderName}
                      </IonNote>
                    </IonItem>
                  </>
                ) : (
                  <></>
                )}
                {considerTourLeader ? (
                  <>
                    <IonItem>
                      <IonLabel>Comission of tour leader </IonLabel>
                      <IonNote slot="end" color="primary">
                        {tourLeaderC}
                      </IonNote>
                    </IonItem>
                  </>
                ) : (
                  <></>
                )}
                <IonItem>
                  <IonLabel>Status of shop </IonLabel>
                  <IonNote slot="end" color="primary">
                    {statusofOrder}
                  </IonNote>
                </IonItem>
              </IonList>
              <IonRow>
                <IonCol>
                  <IonText color="dark" class="ion-text-center">
                    <h3>CHOOSEN PRODUCTS</h3>
                  </IonText>
                </IonCol>
              </IonRow>
              <IonRow style={{ paddingBottom: '50px' }}>
                <IonCol>
                  <Swiper
                    pagination={{
                      el: '.swiper-custom-pagination',
                      type: 'fraction',
                    }}
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={'auto'}
                    coverflowEffect={{
                      rotate: 90,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                    }}
                    modules={[EffectCoverflow, Pagination]}
                    // className="mySwiper"
                  >
                    {finalo.map((item) => {
                      return (
                        <>
                          {console.log('this is image=>', item.image)}
                          <IonRow>
                            <IonCol>
                              <SwiperSlide>
                                <IonCard>
                                  <img
                                    alt="Silhouette of mountains"
                                    src={JSON.parse(item.image)}
                                  />
                                  <IonCardHeader>
                                    <IonCardTitle>{item.title}</IonCardTitle>
                                    <IonCardSubtitle class="ion-padding-top">
                                      <IonText color="primary">
                                        {item.price} usd
                                      </IonText>
                                    </IonCardSubtitle>
                                  </IonCardHeader>

                                  <IonCardContent>{item.about}</IonCardContent>
                                </IonCard>
                              </SwiperSlide>
                            </IonCol>
                          </IonRow>
                        </>
                      );
                    })}
                  </Swiper>
                  <div class="swiper-custom-pagination"></div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonContent>
        </IonPage>
      </Design>
    </div>
  );
}
const Design = styled.div`
  .swiper-3d .swiper-slide-shadow {
    background: rgb(0 0 0 / 0%);
  }
  .swiper-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
  }
  .swiper-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
  }
  .swiper-custom-pagination {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
  }
  .swiper-pagination {
    /* padding-top: 100px;
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center; */
  }
  .swiper-pagination-fraction {
    /* display: flex;
    margin: 0;
    align-items: center;
    justify-content: center; */
  }
  .swiper-pagination-horizontal {
    /* display: flex;
    margin: 0;
    align-items: center;
    justify-content: center; */
  }
`;
export default MyShopFinalPageView;
