import React, { useState, useEffect } from 'react';
import { ProductContext } from '../../context/product';
import { UserContext } from '../../context/user';
import styled from 'styled-components';
import Progress from '../../components/Porgress';
import ProductProvider from '../../context/product';
import ProviderShopList from '../../components/ProviderShopList';
import {
  IonContent,
  IonPage,
  IonText,
  IonRow,
  IonCol,
  IonButton,
  IonIcon,
} from '@ionic/react';
import Menu from '../../components/Menu';
import MyShopsList from '../../components/MyShopsList';
import { addCircleOutline } from 'ionicons/icons';
import ProviderList from '../../components/ProviderList';

function ProviderOrdersMainPage() {
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { finalOrders, touristOrders, products } =
    React.useContext(ProductContext);

  const { user } = React.useContext(UserContext);
  const user_id = user.user.id;
  const Myshops = finalOrders.filter((item) => item.userId === user_id);
  const userId = user.user.id;
  const FinalcustomerOrders = touristOrders.map((it) => it.customerOrders);
  const ProviderOrders = touristOrders.map((item) =>
    item.customerOrders.filter((it) => it.userId === userId)
  );

  const allOrderSameShop = ProviderOrders.map((item) =>
    item.filter((it) => it.userId === userId)
  );
  const [sid1, setsid1] = useState();

  const [FinalShopOfProvider, setFinalShopOfProvider] = useState();

  // const sid1 = ProviderOrders.map((it) => it[0].sid)[0];
  // const FinalShopOfProvider = finalOrders.filter((it) => it.sid === sid1);
  const FinalShopId = ProviderOrders.map((it) => it.map((it) => it.shopId));
  const FinalID = FinalShopId.filter((it) => it[0]);
  const Idofshop = ProviderOrders.map((i) => i.map((it) => it.sid));
  const object = Idofshop.flat(1);
  const unique_names = [...new Set(object)];
  const finalIdToUse = finalOrders.filter((it) => !unique_names.includes(it));
  useEffect(() => {
    if (ProviderOrders[0] > 1) {
      setsid1(ProviderOrders.map((it) => it[0].sid)[0]);
      setFinalShopOfProvider(finalOrders.filter((it) => it.sid === sid1));
    } else return;
  }, []);

  return (
    <div>
      <Design>
        {console.log('id of shops', Idofshop)}
        {console.log('finalIdToUse====>', finalIdToUse)}
        {console.log('unique_names==============', unique_names)}
        {console.log('finalid of shop==================', object)}
        {console.log('touristOrders', touristOrders)}
        {console.log('finalOrders ----->', finalOrders)}
        {console.log('ProviderOrders----->', ProviderOrders.length)}
        {console.log('finalOrders----->', finalOrders)}
        {/* {console.log('sid1----->', sid1)} */}
        {/* {console.log('FinalShopOfProvider----->', FinalShopOfProvider)} */}
        {console.log('allOrderSameShop ----->', allOrderSameShop)}
        {console.log('FinalcustomerOrders ----->', FinalcustomerOrders)}
        {console.log('ProviderOrders ----->', ProviderOrders)}
        {console.log('ProviderOrders.length', ProviderOrders[0].length)}

        <IonPage>
          <Menu what=" MY ORDERS" backBtnLink="/home" display={true} />

          <IonContent fullscreen className="all-page">
            {isLoading && <Progress />}

            <IonRow class="ion-padding-vertical">
              <IonCol>
                <IonText color="dark" class="ion-text-center">
                  <h3 class="ion-text-uppercase">List of ORDERS</h3>
                </IonText>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol class="ion-text-center">
                {ProviderOrders[0].length === 0 ? (
                  <>
                    <IonRow class="ion-padding-vertical">
                      <IonCol>
                        <IonText color="medium" class="ion-text-center">
                          {/* <h3>List of shops created by {user.user.name}</h3> */}
                          <h5>You do not have any order</h5>
                        </IonText>
                      </IonCol>
                    </IonRow>
                  </>
                ) : (
                  <>
                    {finalIdToUse.map((order, index) => {
                      // return <div className="container">{order.shopTitle}</div>;
                      return (
                        <ProviderShopList
                          order={order}
                          key={index}
                          isLoading={isLoading}
                          setIsLoading={setIsLoading}
                        />
                      );
                    })}
                  </>
                )}
              </IonCol>
            </IonRow>
          </IonContent>
        </IonPage>
      </Design>
    </div>
  );
}
const Design = styled.div`
  .my-custom-class {
    display: none !important;
  }
`;
export default ProviderOrdersMainPage;
