import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { UserContext } from '../../context/user';
import {
  IonContent,
  IonPage,
  IonList,
  IonLabel,
  IonTextarea,
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonImg,
  IonProgressBar,
  IonFooter,
  IonToolbar,
  useIonToast,
} from '@ionic/react';
import { Formik } from 'formik';
import axios from 'axios';
import * as yup from 'yup';
import {
  textOutline,
  imageOutline,
  documentTextOutline,
  cashOutline,
  thumbsUpOutline,
} from 'ionicons/icons';
import Menu from '../../components/Menu';
import { BASE_URL, BACKEND } from '../../constants/AppConstants';

const PrdouctSchema = yup.object({
  title: yup.string().required('Product title is required').max(20),
  about: yup.string().required('About is required').max(200),
  price: yup.number().required('Price is required').max(10000),
});

function AddProducts() {
  const { user } = React.useContext(UserContext);
  console.log('user details->', user.user.id);
  const userId = user.user.id;
  const [present] = useIonToast();
  const presentToast2 = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message: ' Product have been added sucessfuly ',
      duration: 1500,
      position: 'bottom',
      color: 'success',
      icon: thumbsUpOutline,
    });
  };
  const [image, setImage] = useState('');
  const [imgErr, setImgErr] = useState('');

  const [loading, setLoading] = useState(true);
  function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => {
      setValue((value) => ++value);
      console.log(value);
    }; // update the state to force render
  }
  const forceUpdate = useForceUpdate();
  const [featuredImage, setFeaturedImage] = useState(null);
  //here you can change cloudinary settings to be yours
  // --------------------------------------------------
  // const cloudName = "dfjgtalry";
  // const uploadPreset = "ahmedImageUpload";

  const cloudName = 'dehpovrxe';
  const uploadPreset = 'lqs73mbv';
  //---------------------------------------------------
  let featuredOptions = {
    cloudName,
    uploadPreset,
    thumbnailTransformation: [{ width: 400, crop: 'scale' }],
    sources: ['local'],

    styles: {
      palette: {
        window: '#F5F5F5',
        sourceBg: '#FFFFFF',
        windowBorder: '#c0c0c0',
        tabIcon: '#0094c7',
        inactiveTabIcon: '#69778A',
        menuIcons: '#0094C7',
        link: '#0ca8fd',
        action: '#F5F5F5',
        inProgress: '#0194c7',
        complete: '#53ad9d',
        error: '#c43737',
        textDark: '#000000',
        textLight: '#FFFFFF',
      },
    },
    maxFiles: 1,
    maxImageFileSize: 7000000,
    singleUploadAutoClose: true,
    resourceType: 'image',
  };
  let addVideofeaturedImage = () => {
    let featuredWidget = window.cloudinary.createUploadWidget(
      featuredOptions,
      (error, result) => {
        error ? console.log(result) : console.log('he');
        error ? console.log('error.status') : console.log('non');
        console.log(result.event);
        if (!error && result && result.event === 'queues-end') {
          console.log(result.event);
          console.log(
            'this is the imageeee',
            result.info.files[0].uploadInfo.url
          );
          result.info.files[0].uploadInfo != null &&
            setImage(JSON.stringify(result.info.files[0].uploadInfo.url));
          setFeaturedImage(result.info.files[0].uploadInfo.url);

          forceUpdate();
        }
      }
    );
    featuredWidget.open();
  };
  const history = useHistory();
  const handleSubmit = async (title, about, price) => {
    if (image === '') {
      setImgErr('Please Upload Image');
      return;
    }

    console.log(image);

    setLoading(true);
    axios
      .post(`${BACKEND}/api/product`, {
        title,
        about,
        image,
        price,
        userId: user.user.id,
      })
      .then((res) => {
        console.log(res.data);
        presentToast2();
        setLoading(false);
        history.push('/productList');
        window.location.reload(true);
      });
  };

  return (
    <Formik
      initialValues={{
        title: '',
        about: '',
        price: '',
      }}
      validationSchema={PrdouctSchema}
      onSubmit={(values, actions) => {
        handleSubmit(values.title, values.about, values.price);
      }}
    >
      {(formikProps) => (
        <div>
          <IonPage style={{ paddingTop: '50%' }}>
            <Menu what="ADD PRODUCT" backBtnLink="/home" display={true} />
            {loading && <IonProgressBar type="indeterminate"></IonProgressBar>}

            <IonContent
              fullscreen
              className="all-page"
              style={{ paddingTop: '150px' }}
            >
              <IonGrid>
                {/* <IonRow class="ion-padding">
                  <IonCol>
                    <IonText color="dark" class="ion-text-center">
                      <h3 class="ion-text-uppercase">ADD NEW PRODUCT </h3>
                    </IonText>
                  </IonCol>
                </IonRow> */}
                <IonRow class="ion-justify-content-center">
                  <IonCol class="ion-align-self-center">
                    <IonList
                      class="margin-top-25 ion-margin margin-0-28"
                      className="list"
                    >
                      {/* TITLE filed */}
                      <IonRow>
                        <IonCol>
                          <IonItem
                            class="roundedInput8px margin-bottom-15 "
                            counter={true}
                          >
                            <IonLabel>
                              <IonIcon icon={textOutline} colo></IonIcon>
                            </IonLabel>

                            <IonInput
                              required={true}
                              maxlength={20}
                              inputmode="text"
                              type="text"
                              clearOnEdit={true}
                              clearInput={true}
                              value={formikProps.values.title}
                              placeholder="Title"
                              onIonChange={formikProps.handleChange('title')}
                              onBlur={formikProps.handleBlur('title')}
                            ></IonInput>

                            <div
                              style={{
                                color: '#a2852e',
                                fontSize: '12px',
                                marginTop: '5px',
                              }}
                            >
                              {formikProps.touched.title &&
                                formikProps.errors.title}
                            </div>
                          </IonItem>
                        </IonCol>
                      </IonRow>

                      <IonRow>
                        <IonCol>
                          {' '}
                          <IonItem>
                            <IonLabel>
                              <IonIcon icon={cashOutline}></IonIcon>
                            </IonLabel>
                            <IonInput
                              maxlength={10000}
                              inputmode="numeric"
                              placeholder="Price in dollars"
                              clearOnEdit={true}
                              clearInput={true}
                              type="number"
                              value={formikProps.values.price}
                              onIonChange={formikProps.handleChange('price')}
                              onBlur={formikProps.handleBlur('price')}
                            ></IonInput>
                            <div
                              style={{
                                color: '#a2852e',
                                fontSize: '12px',
                                marginTop: '5px',
                              }}
                            >
                              {formikProps.touched.price &&
                                formikProps.errors.price}
                            </div>
                          </IonItem>
                        </IonCol>
                      </IonRow>

                      {/* About */}
                      <IonRow>
                        <IonCol>
                          {' '}
                          <IonItem counter={true}>
                            <IonLabel>
                              <IonIcon icon={documentTextOutline}></IonIcon>
                            </IonLabel>
                            <IonTextarea
                              required={true}
                              maxlength={200}
                              inputmode="text"
                              placeholder="About product"
                              clearOnEdit={true}
                              clearInput={true}
                              type="text"
                              autoGrow={true}
                              value={formikProps.values.about}
                              onIonChange={formikProps.handleChange('about')}
                              onBlur={formikProps.handleBlur('about')}
                            ></IonTextarea>
                            <div
                              style={{
                                color: '#a2852e',
                                fontSize: '12px',
                                marginTop: '5px',
                              }}
                            >
                              {formikProps.touched.about &&
                                formikProps.errors.about}
                            </div>
                          </IonItem>
                        </IonCol>
                      </IonRow>

                      {/* name */}
                      {/* product featured image */}
                      {/* show the image */}

                      {featuredImage ? (
                        <>
                          <IonImg
                            src={featuredImage}
                            class="ion-padding"
                          ></IonImg>
                        </>
                      ) : (
                        <></>
                      )}
                      {/* end show the image */}
                      {featuredImage ? (
                        <>
                          <IonButton
                            fill="outline"
                            expand="block"
                            onClick={addVideofeaturedImage}
                          >
                            <IonIcon slot="end" icon={imageOutline}></IonIcon>
                            CHANGE IMAGE
                          </IonButton>
                        </>
                      ) : (
                        <>
                          <IonButton
                            fill="outline"
                            expand="block"
                            onClick={addVideofeaturedImage}
                          >
                            <IonIcon slot="end" icon={imageOutline}></IonIcon>
                            UPLOAD PRODUT IMAGE
                          </IonButton>
                          <p>{imgErr}</p>
                        </>
                      )}
                    </IonList>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonContent>
            <IonFooter color="primary">
              <IonToolbar>
                <IonRow>
                  <IonCol>
                    <IonButton
                      expand="block"
                      onClick={formikProps.handleSubmit}
                      mode="ios"
                    >
                      ADD PRODUCT
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonToolbar>
            </IonFooter>
          </IonPage>
        </div>
      )}
    </Formik>
  );
}

export default AddProducts;
