import React, { useState, useMemo } from 'react';
import { ProductContext } from '../../context/product';
import { UserContext } from '../../context/user';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';
import Producto from '../../components/ProductCardHome';
import Menu from '../../components/Menu';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import 'react-phone-input-2/lib/material.css';
import { Formik } from 'formik';
import * as yup from 'yup';
import {
  IonContent,
  IonPage,
  IonText,
  IonLabel,
  IonItem,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
  IonRadioGroup,
  IonModal,
  IonCheckbox,
  IonHeader,
  IonTitle,
  IonButtons,
  IonToolbar,
  IonIcon,
  useIonToast,
  IonNote,
  IonFooter,
  IonBadge,
} from '@ionic/react';
import { v4 as uuid } from 'uuid';
import {
  thumbsUpOutline,
  chevronBackOutline,
  sendOutline,
  addCircleOutline,
} from 'ionicons/icons';
import { BASE_URL } from '../../constants/AppConstants';
// import ProductList from '../Admin/ProductList';

function HomeDashboard() {
  const unique_id = uuid();
  const { products } = React.useContext(ProductContext);
  const { user } = React.useContext(UserContext);
  let history = useHistory();
  const [present] = useIonToast();
  const [considerTourLeader, setconsiderTourLeader] = useState(true);
  const total = '';
  const [productsList, setproductsList] = useState([]);
  const note = '';
  const [customerOrders] = useState([]);
  const [deliveryTime] = useState(null);
  const [deliveryDate] = useState(null);
  const [deliveryLocation] = useState(null);
  const [country, setCountry] = useState({
    label: 'Select country of the your group',
  });
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('null');
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const options = useMemo(() => countryList().getData(), []);

  const ConsiderSchema = yup.object({
    shopTitle: yup.string().required('Order title is required').max(20),
    tourLeaderName: yup
      .string()

      .required('Tour leader name is required')
      .max(20),
    tourLeaderC: yup.number().max(100).required('commission is required'),
  });
  const NotConsiderSchema = yup.object({
    shopTitle: yup.string().required('Order title is required').max(20),
    tourLeaderName: yup
      .string()

      .required('Tour leader name is required')
      .max(20),
  });

  const presentToast2 = (position = 'top' | 'middle' | 'bottom') => {
    present({
      message: ' Your order have been added',
      duration: 1500,
      position: 'bottom',
      color: 'success',
      icon: thumbsUpOutline,
    });
  };
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    ///.....
    placeholder: (base) => ({
      ...base,
      fontSize: '2em',
      color: 'black',
      fontWeight: 400,
    }),
  };
  const changeHandler = (value) => {
    setCountry(value);
    setIsCountrySelected(true);
  };

  const addToFavorite = (id) => {
    if (!productsList.includes(id)) {
      setproductsList(productsList.concat(id));
    }
  };

  const removeFavorite = (id) => {
    let index = productsList.indexOf(id);
    console.log(index);
    let temp = [
      ...productsList.slice(0, index),
      ...productsList.slice(index + 1),
    ];
    setproductsList(temp);
  };

  const handleSubmit = (shopTitle, tourLeaderName, tourLeaderC) => {
    axios
      .post('https://shop.holyland.app/api/finalorder/add', {
        shopTitle,
        productsList,
        considerTourLeader,
        tourGuideName: user.user.name,
        tourLeaderName,
        tourLeaderC,
        total,
        userId: user.user.id,
        customerOrders,
        sid: unique_id,
        statusofOrder: 'open',
        deliveryTime,
        deliveryDate,
        deliveryLocation,
        country,
        guidePhone: value,
        note,
      })

      .then((res) => {
        console.log(res.data);
        presentToast2();
        // history.push(`/FinaShopPage/${unique_id}`, {
        //   product1: {
        //     sid: unique_id,
        //   },
        // });
        // window.location.reload(true);

        // window.location.reload();
      })
      .catch((error) => console.log(error));
  };

  return (
    <div>
      {console.log('user id', user.user.id)}
      {console.log('THIS IS country', country)}
      {console.log('this is productList', productsList)}
      <StyleHome>
        <Formik
          initialValues={{
            shopTitle: '',
            tourLeaderName: '',
            tourLeaderC: '',
          }}
          validationSchema={
            considerTourLeader ? ConsiderSchema : NotConsiderSchema
          }
          onSubmit={(values) => {
            console.log(values);
            handleSubmit(
              values.shopTitle,
              values.tourLeaderName,
              values.tourLeaderC
            );
          }}
        >
          {(formikProps) => (
            <IonPage style={{ paddingTop: '70px' }}>
              {console.log('this is coutry', country)}
              {console.log('youer consider', considerTourLeader)}
              <Menu what="Build My order" backBtnLink="/home" display={true} />

              <IonContent fullscreen className="all-page">
                <IonGrid>
                  <IonRow>
                    <IonCol class="ion-padding" size="">
                      <IonText color="dark">
                        <h3 className="title">
                          Hey welcome <br></br>
                          {user.user && (
                            <span className="name">Dear {user.user.name}</span>
                          )}
                        </h3>
                      </IonText>
                    </IonCol>
                  </IonRow>

                  <IonGrid>
                    <IonRow>
                      <IonCol>
                        <IonItem counter={true}>
                          <IonInput
                            maxlength={20}
                            inputmode="text"
                            type="text"
                            clearOnEdit={true}
                            clearInput={true}
                            placeholder="Order Name"
                            value={formikProps.values.shopTitle}
                            onIonChange={formikProps.handleChange('shopTitle')}
                            onBlur={formikProps.handleBlur('shopTitle')}
                          ></IonInput>{' '}
                          <div
                            style={{
                              color: '#a2852e',
                              fontSize: '12px',
                              marginTop: '5px',
                            }}
                          >
                            {formikProps.touched.shopTitle &&
                              formikProps.errors.shopTitle}
                          </div>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol class="ion-padding">
                        <Select
                          placeholder="Select country of the your group"
                          options={options}
                          value={country}
                          onChange={changeHandler}
                          styles={customStyles}
                        />
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol class="ion-flot-center">
                        <IonItem counter={true}>
                          <IonInput
                            counter={true}
                            maxlength={20}
                            inputmode="text"
                            type="text"
                            clearOnEdit={true}
                            clearInput={true}
                            placeholder="Tour leader name"
                            value={formikProps.values.tourLeaderName}
                            onIonChange={formikProps.handleChange(
                              'tourLeaderName'
                            )}
                            onBlur={formikProps.handleBlur('tourLeaderName')}
                          ></IonInput>
                          <div
                            style={{
                              color: '#a2852e',
                              fontSize: '12px',
                              marginTop: '5px',
                            }}
                          >
                            {formikProps.touched.tourLeaderName &&
                              formikProps.errors.tourLeaderName}
                          </div>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol class="ion-flot-center">
                        <IonRadioGroup allowEmptySelection={true}>
                          <IonItem>
                            <IonCheckbox
                              slot="end"
                              checked={considerTourLeader}
                              onIonChange={(event) =>
                                setconsiderTourLeader(event.target.checked)
                              }
                            ></IonCheckbox>
                            <IonLabel>Consider Tour leader</IonLabel>
                          </IonItem>
                        </IonRadioGroup>
                      </IonCol>
                    </IonRow>
                    {considerTourLeader ? (
                      <>
                        <IonRow>
                          <IonCol class="ion-flot-center">
                            <IonItem>
                              <IonLabel slot="end">%</IonLabel>
                              <IonInput
                                inputmode="numeric"
                                clearOnEdit={true}
                                clearInput={true}
                                placeholder="Commision"
                                type="number"
                                value={formikProps.values.tourLeaderC}
                                onIonChange={formikProps.handleChange(
                                  'tourLeaderC'
                                )}
                                onBlur={formikProps.handleBlur('tourLeaderC')}
                              ></IonInput>{' '}
                              <div
                                style={{
                                  color: '#a2852e',
                                  fontSize: '12px',
                                  marginTop: '5px',
                                }}
                              >
                                {formikProps.touched.tourLeaderC &&
                                  formikProps.errors.tourLeaderC}
                              </div>
                            </IonItem>
                          </IonCol>
                        </IonRow>
                      </>
                    ) : (
                      <></>
                    )}
                    <IonModal hei isOpen={isOpen}>
                      <IonHeader>
                        <IonToolbar color="primary">
                          <IonTitle>CHOOSE PRODUCT</IonTitle>
                          <IonButtons slot="start">
                            <IonIcon
                              onClick={() => setIsOpen(false)}
                              slot="start"
                              icon={chevronBackOutline}

                              // color="primary"
                            ></IonIcon>
                          </IonButtons>
                        </IonToolbar>
                      </IonHeader>
                      <IonContent>
                        {products.data.map((item, i) => {
                          return (
                            <>
                              <div key={i}>
                                <Producto
                                  // key={item.id}
                                  title={item.title}
                                  about={item.about}
                                  image={item.image}
                                  price={item.price}
                                  idProduct={item.id}
                                  addToFavorite={addToFavorite}
                                  removeFavorite={removeFavorite}
                                  productsList={productsList}
                                  isOpen={isOpen}
                                  setIsOpen={setIsOpen}
                                />
                              </div>
                            </>
                          );
                        })}
                      </IonContent>
                      {isCountrySelected &&
                        value.length >= 7 &&
                        productsList.length > 0 && (
                          <IonFooter>
                            <IonToolbar>
                              <IonRow>
                                <IonCol>
                                  <IonButton
                                    expand="block"
                                    color="primary"
                                    onClick={formikProps.handleSubmit}
                                  >
                                    SEND THE ORDER
                                    <IonIcon
                                      slot="end"
                                      icon={sendOutline}
                                    ></IonIcon>
                                  </IonButton>
                                </IonCol>
                              </IonRow>
                            </IonToolbar>
                          </IonFooter>
                        )}
                    </IonModal>
                    <IonRow class="ion-padding">
                      <IonCol>
                        <PhoneInput
                          placeholder={
                            'Tour guide, Insert your phone number for help'
                          }
                          country={'il'}
                          value={value}
                          onChange={setValue}
                          alwaysDefaultMask={true}
                          enableAreaCodes={true}
                          countryCodeEditable={false}
                          disableCountryCode={false}
                        />
                      </IonCol>
                      {console.log('phone guide=> ', productsList.length)}
                    </IonRow>
                    <IonRow class="ion-padding">
                      <IonCol>
                        <IonNote>
                          Dear tour guide, Please provide us your phone number
                          for more information.
                        </IonNote>
                      </IonCol>
                    </IonRow>
                  </IonGrid>
                </IonGrid>
              </IonContent>
              {console.log('products length', productsList.length)}
              {productsList.length > 0 ? (
                <>
                  {' '}
                  <IonItem>
                    <IonBadge slot="end" color="success">
                      {productsList.length}{' '}
                      {productsList.length > 1 ? <> PRODUCTS</> : <> PRODUCT</>}
                    </IonBadge>
                    <IonLabel>YOU WILL SELL </IonLabel>
                  </IonItem>
                </>
              ) : (
                <></>
              )}

              <IonFooter>
                <IonToolbar>
                  <IonRow>
                    <IonCol>
                      {productsList.length > 0 ? (
                        <>
                          <IonButton
                            expand="block"
                            color="primary"
                            onClick={() => setIsOpen(true)}
                          >
                            ADD MORE PRODUCTS
                            <IonIcon
                              slot="end"
                              icon={addCircleOutline}
                            ></IonIcon>
                          </IonButton>
                        </>
                      ) : (
                        <>
                          <IonButton
                            expand="block"
                            color="primary"
                            onClick={() => setIsOpen(true)}
                          >
                            CHOOSE PRODUCTS
                          </IonButton>
                        </>
                      )}
                      {console.log('this is productList footer', productsList)}
                    </IonCol>
                  </IonRow>
                </IonToolbar>
              </IonFooter>
            </IonPage>
          )}
        </Formik>
        {/* close formim */}
      </StyleHome>
    </div>
  );
}
const StyleHome = styled.div`
  .react-tel-input .form-control {
    height: 40px;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-items: center;
    justify-self: center;
    justify-content: center;
  }
  .react-tel-input {
    color: black;
  }
  /* .title {
    color: #898989;
    font-weight: bold;
  }
  .name {
    font-weight: bold;
    color: black;
  }
  .btn {
    position: absolute;
    bottom: 5px;
    text-align: center;
  }
  .country1 {
    display: none;
  } */
`;
export default HomeDashboard;
