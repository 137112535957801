import React from 'react';
import {
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonList,
  IonItemOptions,
  IonItemOption,
  IonItemSliding,
  IonModal,
  IonContent,
  IonNote,
  IonBadge,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonText,
} from '@ionic/react';
import Moment from 'react-moment';
function NewCard({ customerOrders, customerOrder1 }) {
  console.log('=====================================');
  console.log(customerOrders);
  console.log(customerOrder1);
  console.log('=====================================');
  return (
    <div>
      {console.log('this is cusotmoerorders==>', customerOrders)}
      {customerOrders.map((it) => {
        return (
          <>
            <IonList lines="none">
              <IonItem>
                <IonBadge
                  slot="end"
                  color="success"
                  style={{ fontSize: '16px' }}
                >
                  {it?.quantity}Q{'   /  '}
                  {it.price}$
                </IonBadge>
                <IonLabel>
                  <IonLabel class="ion-text-capitalize">{it.name}</IonLabel>
                </IonLabel>
              </IonItem>
            </IonList>
          </>
        );
      })}
    </div>
  );
}

export default NewCard;
