import React, { useState, useEffect } from 'react';
import { UserContext } from '../context/user';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import * as myConstClass from '../constants/AppConstants';
import UserImage from '../image/new2.jpg';
import Menu from '../components/Menu';
import Logo from '../image/logoapp1.png';
import Progress from '../components/Porgress';
import {
  IonContent,
  IonPage,
  IonText,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonImg,
} from '@ionic/react';

import {
  duplicateOutline,
  storefrontOutline,
  addCircleOutline,
  bagHandleOutline,
} from 'ionicons/icons';

function Home() {
  const [isLoding, setIsLoding] = useState(false);
  const history = useHistory();
  const { user } = React.useContext(UserContext);
  const [items, setItems] = useState([]);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('link'));
    if (items) {
      setItems(items);
    }
  }, []);

  return (
    <Design>
      <IonPage>
        {console.log('this is user from home =>', user)}
        <Menu what="HOME" display={false} />
        <IonContent class="bg-home">
          <div className="container">
            <div>
              <IonImg
                src={UserImage}
                alt="Product featued image"
                class="imageBack"
              ></IonImg>
            </div>
            {isLoding && <Progress isRever={false} />}
            <div className="centered">
              <IonGrid>
                <IonRow>
                  <IonCol class="ion-no-padding">
                    <img
                      src={Logo}
                      alt="SHOP IN BUS"
                      style={{
                        padding: '150px 100px 50px 100px',
                      }}
                    />
                  </IonCol>
                </IonRow>

                {/* tour gudie details */}

                {user.user && user.user.role === 'isAdmin' && (
                  <>
                    <IonGrid>
                      <IonRow>
                        <IonCol>
                          <IonText color="dark" class="ion-text-center">
                            <h3 class="ion-padding" style={{ color: 'white' }}>
                              {myConstClass.AdminHomePage}
                            </h3>
                          </IonText>
                        </IonCol>
                      </IonRow>

                      <IonRow class="ion-text-center">
                        <IonCol class="ion-text-center">
                          <IonButton
                            mode="md"
                            size="large"
                            expand="block"
                            fill="solid"
                            color="primary"
                            onClick={() => {
                              setIsLoding(true);
                              setTimeout(() => {
                                history.push('/AddTourGuide');
                              }, 100);
                            }}
                          >
                            <IonIcon
                              slot="start"
                              icon={duplicateOutline}
                            ></IonIcon>
                            ADD USER
                          </IonButton>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol class="ion-text-center">
                          <IonButton
                            style={{ color: 'white' }}
                            size="large"
                            mode="md"
                            expand="block"
                            fill="outline"
                            color="white"
                            onClick={() => {
                              setIsLoding(true);
                              setTimeout(() => {
                                history.push('/tourGuideList');
                              }, 100);
                            }}
                          >
                            <IonIcon
                              slot="start"
                              icon={bagHandleOutline}
                            ></IonIcon>
                            CHECK ALL USERS
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </>
                )}
                {user.user && user.user.role === 'isTourGuide' && (
                  <>
                    <IonText color="dark" class="ion-text-center">
                      <h3 class="ion-padding" style={{ color: 'white' }}>
                        {myConstClass.GuideHomePage}
                      </h3>
                    </IonText>
                    <IonGrid>
                      <IonRow class="ion-text-center">
                        <IonCol class="ion-text-center">
                          <IonButton
                            mode="md"
                            size="large"
                            expand="block"
                            fill="solid"
                            color="primary"
                            // routerLink="/Addshop"
                            onClick={() => {
                              setIsLoding(true);
                              setTimeout(() => {
                                history.push('/Addshop');
                              }, 100);
                            }}
                          >
                            <IonIcon
                              slot="start"
                              icon={addCircleOutline}
                            ></IonIcon>
                            ADD NEW ORDER
                          </IonButton>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol class="ion-text-center">
                          <IonButton
                            style={{ color: 'white' }}
                            mode="md"
                            size="large"
                            expand="block"
                            fill="outline"
                            color="white"
                            onClick={() => {
                              setIsLoding(true);
                              setTimeout(() => {
                                history.push('/GuideShops');
                              }, 100);
                            }}
                          >
                            {' '}
                            <IonIcon
                              slot="start"
                              icon={storefrontOutline}
                            ></IonIcon>
                            CHECK YOUR ORDERS
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </>
                )}
                {user.user && user.user.role === 'isProvider' && (
                  <>
                    <IonText color="dark" class="ion-text-center">
                      <h3 class="ion-padding" style={{ color: 'white' }}>
                        {myConstClass.GuideHomePage}
                      </h3>
                    </IonText>
                    <IonGrid>
                      <IonRow class="ion-text-center">
                        <IonCol class="ion-text-center">
                          <IonButton
                            mode="md"
                            size="large"
                            expand="block"
                            fill="solid"
                            color="primary"
                            // routerLink="/Addshop"
                            onClick={() => {
                              setIsLoding(true);
                              setTimeout(() => {
                                history.push('/addProduct');
                              }, 100);
                            }}
                          >
                            <IonIcon
                              slot="start"
                              icon={addCircleOutline}
                            ></IonIcon>
                            ADD PRODUCT
                          </IonButton>
                        </IonCol>
                      </IonRow>
                      <IonRow>
                        <IonCol class="ion-text-center">
                          <IonButton
                            style={{ color: 'white' }}
                            mode="md"
                            size="large"
                            expand="block"
                            fill="outline"
                            color="white"
                            onClick={() => {
                              setIsLoding(true);
                              setTimeout(() => {
                                history.push('/ProviderOrdersMainPage');
                              }, 100);
                            }}
                          >
                            {' '}
                            <IonIcon
                              slot="start"
                              icon={storefrontOutline}
                            ></IonIcon>
                            CHECK YOUR ORDERS
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </>
                )}
                {!user.user && (
                  <>
                    {console.log('this is local==>', items)}

                    <IonText color="dark" class="ion-text-center">
                      <h3 class="ion-padding" style={{ color: 'white' }}>
                        {myConstClass.RegularHomePage}
                      </h3>
                    </IonText>
                    <IonGrid>
                      <IonRow class="ion-text-center">
                        <IonCol class="ion-text-center">
                          <IonButton
                            mode="md"
                            size="large"
                            expand="block"
                            fill="solid"
                            color="primary"
                            onClick={() => {
                              setIsLoding(true);
                              setTimeout(() => {
                                history.push('/login');
                              }, 100);
                            }}
                          >
                            SIGN IN
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </IonGrid>
                  </>
                )}

                {/* end tour guide details */}
              </IonGrid>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </Design>
  );
}
const Design = styled.div`
  .imageBack {
    height: 100vh;
    width: 100vw;
    object-fit: cover;
  }
  .container {
    min-height: -webkit-fill-available;
    /* position: relative; */
    text-align: center;
    /* background-color: rgba(1, 1, 1, 0.4); */
  }
  .container {
    /* position: relative; */

    text-align: center;
    background-color: rgba(1, 1, 1, 0.4);
  }
  .centered {
    color: white;
    position: absolute;

    top: 0%;

    /* transform: translate(-50%, -50%); */
  }
`;
export default Home;
