import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Menu from '../../components/MenTourist';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectCoverflow } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cards';
import 'swiper/css/effect-coverflow';
import {
  IonContent,
  IonPage,
  IonText,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonList,
  IonBadge,
  IonCardContent,
} from '@ionic/react';

function Thankyou() {
  const location = useLocation();
  const { producto } = location.state;
  const uniqueItems = producto.customerOrders.filter(
    (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
  );

  const numbers = [];
  return (
    <Design>
      <IonPage style={{ paddingTop: '70px' }}>
        <Menu what="Thank you" display={false} />
        {console.log('customer orders producto====>', producto.customerOrders)}
        <IonContent>
          <IonGrid>
            <IonRow className="r1">
              <IonList inset={true}>
                <IonItem class="ion-padding">
                  <h2>
                    Dear {producto.name.toUpperCase()} ,Thank you for ordering,
                    will keep in touch
                  </h2>{' '}
                </IonItem>
                <IonItem>
                  <IonBadge slot="end" style={{ fontSize: '15px' }}>
                    {producto.email}
                  </IonBadge>
                  <IonLabel>Your email:</IonLabel>
                </IonItem>
                <IonItem>
                  <IonBadge slot="end" style={{ fontSize: '15px' }}>
                    {producto.name}
                  </IonBadge>
                  <IonLabel>Your name:</IonLabel>
                </IonItem>
                <IonItem>
                  <IonBadge slot="end" style={{ fontSize: '15px' }}>
                    {producto.phoneNumber}
                  </IonBadge>
                  <IonLabel>Your Phone number:</IonLabel>
                </IonItem>
                <IonItem>
                  <IonBadge slot="end" style={{ fontSize: '15px' }}>
                    {producto.totalPrice} $
                  </IonBadge>
                  <IonLabel>Total:</IonLabel>
                </IonItem>
              </IonList>
            </IonRow>
            <IonRow>
              <IonCol>
                <IonText color="dark">
                  <h5 class="ion-padding">You have purshased :</h5>
                </IonText>
              </IonCol>
            </IonRow>
            <IonRow style={{ paddingBottom: '20px' }}>
              <IonCol>
                <Swiper
                  pagination={{
                    el: '.swiper-custom-pagination',
                    type: 'fraction',
                  }}
                  effect={'coverflow'}
                  grabCursor={true}
                  centeredSlides={true}
                  slidesPerView={'auto'}
                  coverflowEffect={{
                    rotate: 90,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                  }}
                  modules={[EffectCoverflow, Pagination]}
                  className="mySwiper"
                >
                  {console.log('customerorder=>', producto.customerOrders)}
                  {uniqueItems.map((item, i) => {
                    return (
                      <>
                        <SwiperSlide>
                          <IonCard>
                            <img
                              alt="Silhouette of mountains"
                              src={JSON.parse(item.image)}
                            />
                            <IonCardHeader>
                              <IonCardTitle>
                                {' '}
                                <h5> {item.title}</h5>
                              </IonCardTitle>
                              <IonCardSubtitle>
                                <h3> {item.price} usd </h3>
                              </IonCardSubtitle>
                            </IonCardHeader>
                            <IonCardContent>{item.about}</IonCardContent>

                            <IonCardContent>
                              <IonList lines="none">
                                <IonItem class="ion-no-padding">
                                  <IonBadge
                                    slot="end"
                                    style={{ padding: '12px' }}
                                  >
                                    {' '}
                                    {
                                      producto.customerOrders.filter(
                                        (order) => order.id === item.id
                                      ).length
                                    }
                                  </IonBadge>
                                  <IonLabel>QUANTITY </IonLabel>
                                </IonItem>
                              </IonList>
                            </IonCardContent>
                          </IonCard>
                        </SwiperSlide>
                      </>
                    );
                  })}
                </Swiper>
                {console.log(numbers)}
                <div class="swiper-custom-pagination"></div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    </Design>
  );
}
const Design = styled.div`
  .swiper-3d .swiper-slide-shadow {
    background: rgb(0 0 0 / 0%);
  }
  .swiper-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
  }
  .swiper-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    );
  }
  /* .swiper-pagination {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
  }
  .swiper-pagination-fraction {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
  }
  .swiper-pagination-horizontal {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
  } */
  .swiper-custom-pagination {
    display: flex;
    margin: 0;
    align-items: center;
    justify-content: center;
  }
`;
export default Thankyou;
