import React from 'react';
import axios from 'axios';
import { UserContext } from '../context/user';
import { useHistory } from 'react-router-dom';
import {
  IonText,
  IonButton,
  IonGrid,
  IonRow,
  IonCol,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonImg,
} from '@ionic/react';
import { BASE_URL } from '../constants/AppConstants';

function Productcardnew({
  title,
  about,
  image,
  price,
  id,
  setIsLoading,
  userId,
}) {
  console.log('this is the image=>', JSON.parse(image));

  const history = useHistory();
  const { user } = React.useContext(UserContext);
  // const providerName = user.map((item) => item.id === userId);
  const deletePlace = (placeId) => {
    setIsLoading(true);
    axios
      .delete(`https://shop.holyland.app/api/product/${id}`)
      .then((res) => {
        console.log('deleted');
        setTimeout(() => {
          window.location.reload(true);
        }, 100);

        // ShowPlaces();
      })
      .catch((error) => console.log(error));
  };
  const EditProduct = () => {
    setIsLoading(true);
    setTimeout(() => {
      history.push(`/editProduct/`, {
        producto: {
          title,
          about,
          image,
          price,
          id,
          userId,
        },
      });
    }, 200);
  };

  const AboutProduct = () => {
    setIsLoading(true);
    setTimeout(() => {
      history.push(`/product/${id}`, {
        product: {
          id,
        },
      });
    }, 200);
  };
  return (
    <div>
      <IonCard>
        {/* {console.log('providerName->', providerName)} */}
        <IonImg
          src={JSON.parse(image)}
          alt="Silhouette of mountains"
          style={{ borderBottom: '1px solid rgb(162 133 46 / 24%)' }}
        ></IonImg>

        <IonCardHeader>
          <IonCardTitle>{title}</IonCardTitle>
          <IonCardSubtitle>
            <IonText color="primary">
              <h5>Price: {price}$</h5>
            </IonText>
          </IonCardSubtitle>
          {/* <IonCardSubtitle>
            <IonText color="primary">
              <h5>Provider name: {userId}</h5>
            </IonText>
          </IonCardSubtitle> */}
        </IonCardHeader>

        <IonCardContent>{about}</IonCardContent>
        {user.user && user.user.role === 'isProvider' && (
          <>
            <IonGrid>
              <IonRow class="ion-justify-content-between">
                <IonCol size="auto">
                  <IonButton
                    fill="clear"
                    onClick={() => {
                      AboutProduct();
                    }}
                  >
                    ABOUT
                  </IonButton>
                </IonCol>
                <IonCol size="auto">
                  <IonButton fill="clear" onClick={() => EditProduct()}>
                    UPDATE
                  </IonButton>
                </IonCol>
                <IonCol size="auto">
                  <IonButton
                    fill="clear"
                    color="danger"
                    onClick={() => deletePlace(id)}
                  >
                    DELETE
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        )}
      </IonCard>
    </div>
  );
}

export default Productcardnew;
